import { FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { FormikCalendar, FormikInputSelect } from 'src/components';
import config from 'src/config';
import { useTranslation } from 'react-i18next';
import { FormValues } from '..';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
};

function ReportTo({ formik, hidden = false }: Props) {
  const { t } = useTranslation();

  return (
    <Grid columnSpacing={2} container sx={{ display: hidden ? 'none' : 'flex' }}>
      <Grid xs={12} md={6} item>
        <FormikCalendar formik={formik} field="expectedStartDate" label={t('eventPage.expectedStartDate')} />
      </Grid>

      <Grid xs={12} md={6} item>
        <FormikInputSelect
          formik={formik}
          field="time"
          label={t('eventsPage.time')}
          options={config.times.map((value: string) => ({
            key: value,
            value,
          }))}
          disableClearable
        />
      </Grid>

      <Grid xs={12} md={6} item>
        <FormikInputSelect formik={formik} field="yardFK" label={t('eventsPage.yardFK')} options={config.options.yards} disableClearable />
      </Grid>
    </Grid>
  );
}

export default ReportTo;
