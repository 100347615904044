import React, { useEffect, useRef, useState } from 'react';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRowParams,
  GridCellParams,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridRowId,
  GridValidRowModel,
  useGridApiRef,
  GridRowClassNameParams,
  GridRenderCellParams,
  GridMoreVertIcon,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

import { Button, EmptyState, Icon, TablePagination, ToolbarAdapter, ToolbarProps } from 'src/components';
import { themeColors } from 'src/theme';
import { usePagination } from 'src/hooks';
import { Tooltip, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePersistColumnSettings } from './hooks';

const StyledDataGrid = styled(DataGrid)({
  border: 'none',
  fontSize: '14px',
  '& .MuiDataGrid-columnHeaders': {
    border: 'none',
    backgroundColor: themeColors.grayLight,
    textTransform: 'uppercase',
    fontSize: '12px',
    color: themeColors.black,
    // overriding inline styles
    maxHeight: '168px !important',
  },
  '& .MuiDataGrid-columnHeader': {
    px: '16px',
    // overriding inline styles
    height: 'unset !important',
  },
  '& .MuiDataGrid-columnHeader[data-field="userRoles"] .MuiDataGrid-columnHeaderTitle': {
    paddingLeft: '10px',
  },
  '& .MuiDataGrid-cell': {
    px: '16px',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: themeColors.grayLight,
    '&:hover': {
      backgroundColor: themeColors.grayLight,
    },
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'normal',
    lineHeight: 'normal',
    fontWeight: 600,
    fontSize: '12px',
  },
  '& .MuiDataGrid-row.row--edited': {
    backgroundColor: themeColors.grayLight,
  },
  '& .MuiDataGrid-cell.cell--edited': {
    backgroundColor: themeColors.blueLight,
  },
  '& .MuiDataGrid-row.MuiDataGrid-row--editing': {
    backgroundColor: themeColors.lightYellow,
    boxShadow: 'none',
    '& .MuiDataGrid-cell': {
      backgroundColor: 'transparent',
      '&.cell--editing': {
        backgroundColor: themeColors.white,
        border: '1px solid',
        borderColor: themeColors.yellowMain,
      },
      '&:focus-within': {
        outline: 'none',
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
    outline: 'none',
    '& fieldset': { border: 'none' },
  },
  '& .MuiDataGrid-sortIcon': {
    opacity: `1 !important`,
    color: 'black',
  },
  '& .MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
  },
});

export type TableAdvancedMetaColumn = {
  index: number;
  column: GridColDef;
};

export type TableAdvancedProps = {
  metaColumns?: TableAdvancedMetaColumn[];
  metaRows?: any[];
  getActions?: (params: GridRowParams<any>) => React.ReactElement<GridActionsCellItemProps>[];
  onBulkEditStart?: () => void;
  onBulkEditStop?: () => void;
  hasUnsavedData?: (hasUnsavedChanges: boolean) => void;
  processRowsUpdate?: (newRows: any, oldRows: any) => void;
  toolbarProps?: ToolbarProps;
  hideToolbar?: boolean;
  columnEditableOverride?: { [key: string]: boolean };
  goTo?: (params: GridRenderCellParams) => void;
  noDiscard?: boolean;
  usePaginaionParams?: boolean;
  idToScroll?: string;
  onScroll?: () => void;
  isFilters?: boolean;
  resetFilters?: () => void;
  statePersistId?: string;
} & DataGridProps;

const TableAdvanced: React.FC<TableAdvancedProps> = ({
  metaColumns,
  metaRows,
  getActions,
  columns,
  rows,
  columnVisibilityModel,
  processRowUpdate,
  processRowsUpdate,
  hasUnsavedData,
  getRowId = (row) => row.id,
  hideToolbar = false,
  columnEditableOverride,
  goTo,
  noDiscard = false,
  onStateChange,
  usePaginaionParams,
  idToScroll,
  onScroll,
  isFilters,
  resetFilters,
  statePersistId,
  ...props
}): JSX.Element => {
  const defPageSizeOptions = [10, 25, 50, 100];

  const { pagination, onChangePagination } = usePagination({ useQueryParams: usePaginaionParams });

  // Only need to override pageSizeOptions here, otherwise ... actions will
  // properly propagate down if user overrides default behavior
  const pageProps = {
    ...props, // Should this be here or should we pass default props to PaginationItem?
    pageSizeOptions: defPageSizeOptions,
  };

  // Add any additional columns and rows into the table at the specified index
  // TODO: add bounds checking
  // The following line causes multiple instances of metaColumn and action entries (why??)
  // const newColumns: GridColDef[] = columns as GridColDef[];
  let newColumns: GridColDef[] = [...columns];
  if (metaColumns) {
    for (const col of metaColumns) {
      newColumns.splice(col.index, 0, col.column);
    }
  }

  if (columnEditableOverride) {
    newColumns = newColumns.map((col) => {
      return { ...col, editable: columnEditableOverride[col.field] || col.editable };
    });
  }

  const hasBulkEditMode = props.onBulkEditStart && props.onBulkEditStop;

  (getActions || hasBulkEditMode) &&
    newColumns.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      minWidth: 80,
      maxWidth: 160,
      sortable: false,
      getActions: (params: GridRowParams<any>): React.ReactElement<GridActionsCellItemProps>[] => {
        const ret = getActions ? getActions(params) : [];

        !noDiscard &&
          hasBulkEditMode &&
          ret.unshift(
            <GridActionsCellItem
              key="blockDiscard"
              icon={
                <Tooltip title="Revert changes" placement="left">
                  <Icon name="restoreIcon" />
                </Tooltip>
              }
              label="Discard changes"
              disabled={unsavedChangesRef.current.unsavedRows[params.id] === undefined}
              onClick={() => {
                apiRef.current.updateRows([unsavedChangesRef.current.rowsBeforeChange[params.id]]);
                delete unsavedChangesRef.current.rowsBeforeChange[params.id];
                delete unsavedChangesRef.current.unsavedRows[params.id];
                const dirty = Object.keys(unsavedChangesRef.current.unsavedRows).length > 0;
                setHasUnsavedRows(dirty); // won't be updated until next render
                if (!dirty) {
                  setInBulkEditMode(false);
                  props.onBulkEditStop && props.onBulkEditStop();
                }
              }}
            />,
          );

        if (ret.length === 0) {
          ret.unshift(
            <GridActionsCellItem
              key="moreVertIcon"
              icon={
                <Tooltip title="More" placement="left">
                  <GridMoreVertIcon />
                </Tooltip>
              }
              label="More icon"
              disabled
            />,
          );
        }

        return ret;
      },
    });

  if (goTo) {
    newColumns.push({
      field: 'goTo',
      headerName: '',
      width: 60,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          goTo(params);
        };
        return (
          <Box
            onClick={onClick}
            sx={{ height: '100%', width: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Icon name="arrowRight" />
          </Box>
        );
      },
    });
  }

  const newRows = metaRows
    ? rows.map((item, index) => ({
        ...item,
        ...metaRows[index],
      }))
    : rows;

  const apiRef = useGridApiRef();
  const [inBulkEditMode, setInBulkEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const [isRowsLoaded, setIsRowsLoaded] = useState(false);
  if (statePersistId) {
    usePersistColumnSettings(apiRef, statePersistId);
  }

  const unsavedChangesRef = useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>;
    rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
  }>({
    unsavedRows: {},
    rowsBeforeChange: {},
  });

  const blockProcessRowUpdate: NonNullable<DataGridProps['processRowUpdate']> = (newRow, oldRow) => {
    // onRowEditStop is called prior to this callback. If user only modifies a single row,
    // hasUnsavedRows hasn't been called yet, leaving a window where there are changes but
    // we are not tracking them. This is a stopgap until we can figure out how to see if
    // the values are dirty in onRowEditStop.
    if (!inBulkEditMode) {
      setInBulkEditMode(true);
      props.onBulkEditStart && props.onBulkEditStart();
    }

    const rowId = getRowId(newRow);
    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }
    setHasUnsavedRows(true);

    if (hasUnsavedData) {
      hasUnsavedData(true);
    }
    return newRow;
  };

  const saveChanges = async () => {
    try {
      // Persist updates in the database
      setIsSaving(true);

      // Bulk saving
      if (processRowsUpdate) {
        processRowsUpdate(unsavedChangesRef.current.unsavedRows, unsavedChangesRef.current.rowsBeforeChange);
      } else {
        let i: keyof typeof unsavedChangesRef.current.unsavedRows;
        for (i in unsavedChangesRef.current.unsavedRows) {
          if (Object.hasOwn(unsavedChangesRef.current.unsavedRows, i) && processRowUpdate) {
            processRowUpdate(unsavedChangesRef.current.unsavedRows[i], unsavedChangesRef.current.rowsBeforeChange[i]);
          }
        }
      }

      setIsSaving(false);
      /*
      // Need DataGridPro for updateRows, however, it's not necessary since we
      // reload dataset anytime there is a change and don't worry about keeping
      // table data in synch. Should that behavior change, we'll need to revisit
      const rowsToDelete = Object.values(unsavedChangesRef.current.unsavedRows).filter((row) => row.test_action === 'delete');
      if (rowsToDelete.length > 0) {
        apiRef.current.updateRows(rowsToDelete);
      }
      */
      setHasUnsavedRows(false);
      if (hasUnsavedData) {
        hasUnsavedData(false);
      }
      unsavedChangesRef.current = {
        unsavedRows: {},
        rowsBeforeChange: {},
      };
    } catch (error) {
      setIsSaving(false);
    }
    setInBulkEditMode(false);
    props.onBulkEditStop && props.onBulkEditStop();
  };

  const discardChanges = () => {
    setHasUnsavedRows(false);
    if (hasUnsavedData) {
      hasUnsavedData(false);
    }
    let i: keyof typeof unsavedChangesRef.current.unsavedRows;
    for (i in unsavedChangesRef.current.unsavedRows) {
      if (Object.hasOwn(unsavedChangesRef.current.unsavedRows, i)) {
        apiRef.current.updateRows([unsavedChangesRef.current.rowsBeforeChange[i]]);
        delete unsavedChangesRef.current.rowsBeforeChange[i];
        delete unsavedChangesRef.current.unsavedRows[i];
      }
    }
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
    setInBulkEditMode(false);
    props.onBulkEditStop && props.onBulkEditStop();
  };

  const toolbarProps = {
    id: '',
    ...props.toolbarProps,
    hasUnsavedRows,
    isSaving,
    saveChanges,
    discardChanges,
  };

  const hiddenFromToggleFields = ['goTo'];

  const getTogglableColumns = (columns: any) => {
    return columns.filter((column: any) => !hiddenFromToggleFields.includes(column.field)).map((column: any) => column.field);
  };

  const scrollToSelectedRow = () => {
    setTimeout(() => {
      const selectedRow = document.querySelector(`[data-id="${idToScroll}"]`);
      selectedRow?.scrollIntoView({
        block: 'center',
      });
      apiRef.current.setCellFocus(idToScroll as GridRowId, columns[0].field);
      if (onScroll) onScroll();
    }, 300);
  };

  useEffect(() => {
    if (idToScroll && newRows.length && isRowsLoaded) {
      scrollToSelectedRow();
    }
  }, [idToScroll, newRows.length, isRowsLoaded]);

  return (
    <StyledDataGrid
      columns={newColumns}
      rows={newRows}
      initialState={{
        columns: {
          columnVisibilityModel,
        },
        filter: {
          filterModel: {
            items: [],
            quickFilterExcludeHiddenColumns: true,
          },
        },
      }}
      columnHeaderHeight={42}
      rowHeight={56}
      apiRef={apiRef}
      disableColumnMenu
      hideFooterSelectedRowCount
      localeText={{
        toolbarColumns: '',
        toolbarFilters: '',
        toolbarDensity: '',
        toolbarExport: '',
      }}
      editMode="row"
      pageSizeOptions={defPageSizeOptions}
      paginationModel={pagination}
      onPaginationModelChange={onChangePagination}
      onRowEditStart={() => {
        if (inBulkEditMode) return;
        setInBulkEditMode(true);
        props.onBulkEditStart && props.onBulkEditStart();
      }}
      onRowEditStop={() => {
        if (hasUnsavedRows) return;
        setInBulkEditMode(false);
        props.onBulkEditStop && props.onBulkEditStop();
      }}
      processRowUpdate={blockProcessRowUpdate}
      loading={isSaving}
      getRowId={getRowId}
      getCellClassName={(params: GridCellParams) => {
        if (params.cellMode === 'edit' && params.hasFocus) {
          return 'cell--editing';
        }
        const unsavedRow = unsavedChangesRef.current.unsavedRows[params.id];
        if (unsavedRow) {
          const rowsBeforeChange = unsavedChangesRef.current.rowsBeforeChange[params.id];
          if (unsavedRow[params.field] !== rowsBeforeChange[params.field]) {
            return 'cell--edited';
          }
        }
        return '';
      }}
      getRowClassName={(params: GridRowClassNameParams) => {
        const unsavedRow = unsavedChangesRef.current.unsavedRows[params.id];
        if (unsavedRow) {
          if (unsavedRow.test_action === 'delete') {
            return 'row--removed';
          }
          return 'row--edited';
        }
        return '';
      }}
      {...props}
      slots={{
        toolbar: () => (hideToolbar ? null : ToolbarAdapter(toolbarProps)),
        pagination: () => TablePagination(pageProps),
        noRowsOverlay: isFilters ? () => CustomNoRowsWithFiltersOverlay({ resetFilters }) : CustomNoRowsOverlay,
        ...props.slots,
      }}
      slotProps={{ columnsPanel: { getTogglableColumns }, ...props.slotProps }}
      onStateChange={(state, event, details) => {
        setIsRowsLoaded(true);
        if (onStateChange) {
          onStateChange(state, event, details);
        }
      }}
    />
  );
};

const CustomNoRowsOverlay = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box>
        <EmptyState type="table" title={t('common.noResultsFound')} />
      </Box>
    </Box>
  );
};
const CustomNoRowsWithFiltersOverlay = ({ resetFilters }: { resetFilters?: () => void }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <EmptyState type="filters" title={t('common.noResultsFound')} />
        <Button buttonType="text" sx={{ color: themeColors.blue, textTransform: 'unset', fontWeight: 500 }} onClick={resetFilters}>
          {t('common.resetFilters')}
        </Button>
      </Box>
    </Box>
  );
};

export default TableAdvanced;
