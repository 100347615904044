import { createSelector } from 'reselect';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector, createErrorPayloadSelector } from '../@errors';
import { IncidentDataState } from './types';
import { ReduxState } from '../types';

// Incident
export const selectIncidentData = (state: ReduxState): IncidentDataState => state.incidentData;
export const selectIncident = createSelector(selectIncidentData, ({ incident }) => incident);
export const selectIncidentId = createSelector(selectIncident, (incident) => incident.id);

export const selectIncidentLoading = createLoadingSelector(TYPES.LOAD_INCIDENT.TYPE);
export const selectIncidentSuccess = createSuccessSelector(TYPES.LOAD_INCIDENT.TYPE);
export const selectIncidentError = createErrorSelector(TYPES.LOAD_INCIDENT.TYPE);

export const selectGetIdIncidentLoading = createLoadingSelector(TYPES.GET_ID_INCIDENT.TYPE);
export const selectGetIdIncidentSuccess = createSuccessSelector(TYPES.GET_ID_INCIDENT.TYPE);
export const selectGetIdIncidentError = createErrorSelector(TYPES.GET_ID_INCIDENT.TYPE);

export const selectSaveIncidentLoading = createLoadingSelector(TYPES.SAVE_INCIDENT.TYPE);
export const selectSaveIncidentSuccess = createSuccessSelector(TYPES.SAVE_INCIDENT.TYPE);
export const selectSaveIncidentError = createErrorSelector(TYPES.SAVE_INCIDENT.TYPE);
export const selectSaveIncidentErrors = createErrorPayloadSelector(TYPES.SAVE_INCIDENT.TYPE);

export const selectDeletingIncident = createLoadingSelector(TYPES.DELETE_INCIDENT.TYPE);
export const selectDeletingIncidentSuccess = createSuccessSelector(TYPES.DELETE_INCIDENT.TYPE);
export const selectDeletingIncidentErrors = createErrorPayloadSelector(TYPES.DELETE_INCIDENT.TYPE);

export const selectSetInviteIncident = createLoadingSelector(TYPES.SET_INVITE_INCIDENT.TYPE);
export const selectSetInviteIncidentSuccess = createSuccessSelector(TYPES.SET_INVITE_INCIDENT.TYPE);
export const selectSetInviteIncidentErrors = createErrorPayloadSelector(TYPES.SET_INVITE_INCIDENT.TYPE);

export const selectIncidentDeclineLinemen = createLoadingSelector(TYPES.INCIDENT_DECLINE_LINEMEN.TYPE);
export const selectIncidentDeclineLinemenSuccess = createSuccessSelector(TYPES.INCIDENT_DECLINE_LINEMEN.TYPE);
export const selectIncidentDeclineLinemenErrors = createErrorPayloadSelector(TYPES.INCIDENT_DECLINE_LINEMEN.TYPE);

export const selectUpdateIncidentStatusLoading = createLoadingSelector(TYPES.UPDATE_INCIDENT_STATUS.TYPE);
export const selectUpdateIncidentStatusSuccess = createSuccessSelector(TYPES.UPDATE_INCIDENT_STATUS.TYPE);
export const selectUpdateIncidentStatusError = createErrorSelector(TYPES.UPDATE_INCIDENT_STATUS.TYPE);
export const selectUpdateIncidentStatusErrors = createErrorPayloadSelector(TYPES.UPDATE_INCIDENT_STATUS.TYPE);
