import { Box, styled } from '@mui/material';
import { Button } from 'src/components';

export const ControlsContainer = styled(Box)(({ theme }) => ({
  gap: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
}));

export const ButtonWrapper = styled(Box)(() => ({
  width: '100%',
}));

export const ActionButton = styled(Button)(() => ({
  width: '100%',
  textTransform: 'capitalize',
}));
