import { IncidentType } from './incident';
import { UserType } from './user';
import { VehicleType } from './vehicle';

// eslint-disable-next-line no-shadow
export enum RosterStatus {
  DRAFT = 0,
  PENDING_APPROVAL,
  MOBILIZED,
  ACTIVATED,
  DEMOBILIZED,
  CLOSED,
}

// eslint-disable-next-line no-shadow
export enum RosterApprovalStatus {
  REJECTED = 0,
  PENDING,
  APPROVED,
}

export type RosterMeta = {
  date: string;
  version: number;
  status: number;
};

export type RosterType = {
  approvalStatus: RosterApprovalStatus;
  id?: number;
  name: string;
  yardFK: number;
  startDate: number;
  startTime: string;
  status: RosterStatus;
  incidentId?: number;
  owner: string;
  ownerUuid: string;
  contactFullName: string;
  contactEmail: string;
  contactPhone: string;
  vehicles?: VehicleType[];
  linemen?: UserType[] | null;
  incident?: Partial<IncidentType> | null;
  createdBy?: Partial<UserType> | null;
  createdUuid?: string;
  ownedBy?: Partial<UserType> | null;
  meta?: RosterMeta[];
  version?: number;
  isLastVersion: boolean;
  myCrew?: UserType[];
  myEquipments?: VehicleType[];
  notes: string;
};
