import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@mui/material';
import { PATHS } from 'src/navigation';
import { resend, loadUser, verifyCode, logout } from 'src/redux/user/actions';
import {
  selectUser,
  selectSuccessResendingUserEmail,
  selectErrorsResendingUserEmail,
  selectResendEmailError,
  selectVerifyCodeSuccess,
  selectVerifyCodeError,
} from 'src/redux/user/selectors';
import { H1, P1R, TextLink, Notification, P2, Button, P1M } from 'src/components';
import { themeColors } from 'src/theme';
import OTPInput from 'src/components/OTPInput';
import { setClearModal } from 'src/redux/clearme/actions';
import { BlankWrapper } from '../wrappers';

const CreateAccountContainer: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const verifyCodeSuccess = useSelector(selectVerifyCodeSuccess);
  const verifyCodeError = useSelector(selectVerifyCodeError);
  const resendSuccess = useSelector(selectSuccessResendingUserEmail);
  const resendFalure = useSelector(selectResendEmailError);
  const resendErrors = useSelector(selectErrorsResendingUserEmail) as Record<string, any>;
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);

  useEffect(() => {
    if (!user.email || verifyCodeSuccess) {
      dispatch(loadUser.init());
    }

    if (user.emailConfirmed) {
      dispatch(setClearModal.init({ isOpen: true }));
      navigate(PATHS.MY_PROFILE_PAGE, { replace: true });
    }
  }, [user, dispatch, verifyCodeSuccess]);

  const handleResend = () => {
    dispatch(resend.init({ uuid: user.uuid }));
  };

  const handleSubmit = () => {
    if (otp.length < 6) {
      setOtpError(true);
      dispatch(verifyCode.reset());
    } else {
      setOtpError(false);
      dispatch(verifyCode.init({ verificationCode: otp }));
    }
  };

  const handleLogout = () => dispatch(logout.init());

  return (
    <BlankWrapper>
      <Box sx={{ pt: 14, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          <Box>
            <H1>{t('verifyEmailPage.title')}</H1>
          </Box>

          <Box sx={{ marginTop: 6 }}>
            <P1R>{t('verifyEmailPage.verificationCode')}</P1R>
            <br />
            <P1M>{user.email}</P1M>
          </Box>

          <Box sx={{ margin: '12px 0 26px 0' }}>
            <P1R>{t('verifyEmailPage.enterCode')}.</P1R>
          </Box>

          <OTPInput separator={<span> </span>} value={otp} onChange={setOtp} length={6} />

          {verifyCodeError && <P2 sx={{ color: themeColors.error, textTransform: 'none' }}>{t('verifyEmailPage.incorrectCode')}.</P2>}
          {otpError && <P2 sx={{ color: themeColors.error, textTransform: 'none' }}>{t('verifyEmailPage.otpError')}</P2>}

          <Box sx={{ marginTop: '32px' }}>
            <TextLink style={{ display: 'flex', alignItems: 'center' }}>
              {t('verifyEmailPage.didntRecieve')}
              <Link
                component="button"
                variant="body2"
                onClick={handleResend}
                style={{
                  color: themeColors.blue,
                  textDecoration: 'none',
                  marginLeft: '4px',
                  fontSize: '14px',
                }}
              >
                {t('verifyEmailPage.resendCode')}.
              </Link>
            </TextLink>
          </Box>

          <Box sx={{ marginTop: '42px' }}>
            <Button onClick={() => handleSubmit()} fullWidth style={{ textTransform: 'none' }}>
              {t('common.submit')}
            </Button>
          </Box>
        </div>
      </Box>
      <Box sx={{ mt: 14 }}>
        <Button primary={false} onClick={handleLogout} style={{ textTransform: 'capitalize' }}>
          {t('common.goToLogout')}
        </Button>
      </Box>

      <Notification open={resendSuccess} onClose={() => dispatch(resend.reset())}>
        <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>{t('verifyEmailPage.emailResent')}</P2>
      </Notification>

      <Notification severity="error" open={resendFalure} onClose={() => dispatch(resend.reset())}>
        <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>{resendErrors?.[0]}</P2>
      </Notification>
    </BlankWrapper>
  );
};

export default CreateAccountContainer;
