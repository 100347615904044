import { clearStatusNameByCode } from 'src/components/ClearStatus';
import { userStatusNameByCode } from 'src/components/UserStatus';
import { verificationStatusNameByCode } from 'src/components/VerificationStatus';
import { ClearStatus } from 'src/shared/enums/clearStatus.enum';
import { UnionInfoStatus } from 'src/shared/enums/unionInfoStatus.enum';
import { UserStatus } from 'src/shared/enums/userStatus.enum';

export const MOBILE_PREFIX = '+1';

export const clearStatusValues = [
  {
    title: clearStatusNameByCode[ClearStatus.APPROVED],
    value: ClearStatus.APPROVED,
  },
  {
    title: clearStatusNameByCode[ClearStatus.DECLINED],
    value: ClearStatus.DECLINED,
  },
  {
    title: clearStatusNameByCode[ClearStatus.INCOMPLETE],
    value: ClearStatus.INCOMPLETE,
  },
  {
    title: clearStatusNameByCode[ClearStatus.NOT_STARTED],
    value: ClearStatus.NOT_STARTED,
  },
];

export const userStatusValues = [
  {
    title: userStatusNameByCode[UserStatus.DEACTIVATED],
    value: UserStatus.DEACTIVATED,
  },
  {
    title: userStatusNameByCode[UserStatus.BLOCKED],
    value: UserStatus.BLOCKED,
  },
  {
    title: userStatusNameByCode[UserStatus.ACTIVE],
    value: UserStatus.ACTIVE,
  },
];

export const unionStatusValues = [
  {
    title: verificationStatusNameByCode[UnionInfoStatus.PENDING],
    value: UnionInfoStatus.PENDING,
  },
  {
    title: 'Blocked',
    value: UnionInfoStatus.REJECTED,
  },
  {
    title: 'Active',
    value: UnionInfoStatus.VERIFIED,
  },
];
