import { createReduxAsyncAction } from '../utils/toolkit';
import { TYPES } from './constants';

export const loadIncident = createReduxAsyncAction(TYPES.LOAD_INCIDENT);
export const getIdIncident = createReduxAsyncAction(TYPES.GET_ID_INCIDENT);
export const saveIncident = createReduxAsyncAction(TYPES.SAVE_INCIDENT);
export const deleteIncident = createReduxAsyncAction(TYPES.DELETE_INCIDENT);
export const setInviteIncident = createReduxAsyncAction(TYPES.SET_INVITE_INCIDENT);
export const incidentDeclineLinemen = createReduxAsyncAction(TYPES.INCIDENT_DECLINE_LINEMEN);
export const updateIncidentStatus = createReduxAsyncAction(TYPES.UPDATE_INCIDENT_STATUS);
