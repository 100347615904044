import { put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { SagaAction } from 'src/redux/types';
import { setClearModal } from '../actions';
import { setClearmeModal } from '../reducers';

function* setClearModalSaga({ payload: { isOpen = false } }: SagaAction<Partial<{ isOpen: boolean }>>): SagaIterator {
  try {
    yield put(setClearModal.pending());

    yield put(setClearmeModal({ isOpen }));
    yield put(setClearModal.success());
  } catch (e) {
    yield put(setClearModal.failure());
  }
}

export default setClearModalSaga;
