import { GridApi } from '@mui/x-data-grid';
import { GridEvents } from '@mui/x-data-grid/models/events';
import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * If argument is a string, try to parse as JSON.
 * Otherwise return null.
 */
export function parseOrNull(raw: unknown) {
  if (!raw) return null;

  if (typeof raw === 'string') {
    try {
      return JSON.parse(raw);
    } catch (e) {
      console.warn(`Failed to parse: ${raw.substring(0, 50)}`);
      return null;
    }
  }

  return null;
}

/**
 * Listens to column-related changes on grid, and saves column settings to local storage.
 * Restores settings upon reload.
 * Docs: https://mui.com/x/react-data-grid/state/#save-and-restore-the-state
 */
export function usePersistColumnSettings(apiRef: MutableRefObject<GridApi>, key: string) {
  const initialized = useRef(false);
  const storageKey = `${key}_grid-state`;

  useEffect(() => {
    const ref = apiRef.current;

    if (!ref?.subscribeEvent) return undefined;

    // Restore state on first ref load
    if (!initialized.current) {
      initialized.current = true;

      const raw = localStorage.getItem(storageKey);
      if (raw) {
        const parsed = parseOrNull(raw);
        if (parsed) {
          try {
            // omit applying pagination to not mess with scrollToSelectedRow
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { pagination, ...state } = parsed;

            ref.restoreState({ ...state });
          } catch (e) {
            console.warn(`Failed to restore grid state`, e);
          }
        }
      }
    }

    const subs: VoidFunction[] = [];

    const save = () => {
      const state = ref.exportState();
      if (state) {
        localStorage.setItem(storageKey, JSON.stringify(state));
      }
    };

    const subscribe = <E extends GridEvents>(event: E) => {
      subs.push(ref.subscribeEvent(event, save));
    };

    subscribe('columnResizeStop');
    subscribe('columnOrderChange');
    subscribe('columnVisibilityModelChange');
    subscribe('sortModelChange');

    return () => {
      subs.forEach((unsub) => unsub());
    };
  }, [apiRef]);
}
