import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setIncidents } from '../reducers';
import { loadIncidents } from '../actions';

function* loadIncidentsSaga(): SagaIterator {
  try {
    yield put(loadIncidents.pending());

    const { payload } = yield call(API.loadIncidents);

    yield put(setIncidents(payload));
    yield put(loadIncidents.success());
  } catch (e) {
    yield put(loadIncidents.failure(e));
  }
}

export default loadIncidentsSaga;
