import { Box, BoxProps, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { themeColors } from 'src/theme';
import { loadVehicles } from 'src/redux/vehicles/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectVehicles, selectVehiclesError, selectVehiclesLoading } from 'src/redux/vehicles/selectors';
import { Loader, SomethingWentWrongState } from 'src/components';
import { useTranslation } from 'react-i18next';
import { DashboardWrapper } from '../wrappers';
import MaintenanceTab from './FleetReportsTabs/MaintenanceTab';
import AdministrativeTab from './FleetReportsTabs/AdministrativeTab';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps & BoxProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'hidden',
        ...(value !== index && { display: 'none' }),
      }}
      {...other}
    >
      {value === index && <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'hidden' }}>{children}</Box>}
    </Box>
  );
}

const FleetReportsContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fleet = useSelector(selectVehicles);
  const isFleetLoading = useSelector(selectVehiclesLoading);
  const isFleetLoadingError = useSelector(selectVehiclesError);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    dispatch(loadVehicles.init());
  }, []);

  const handleTabsChange = (event: React.SyntheticEvent, newTab: number) => {
    setTabValue(newTab);
  };

  if (isFleetLoadingError) {
    return (
      <DashboardWrapper breadcrumbs={[['Dashboard']]}>
        <SomethingWentWrongState />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper
      breadcrumbs={[['Dashboard']]}
      // navigationWidget={<NavigationWidget editingVehicle={editingVehicle} editMode={editMode} />}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabsChange} aria-label="basic tabs example">
          <Tab
            label={t('fleetReportsPage.maintenance')}
            {...a11yProps(0)}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              color: themeColors.grayDark,
              '&.Mui-selected': { color: 'black' },
            }}
          />

          <Tab
            label={t('fleetReportsPage.administrative')}
            {...a11yProps(1)}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              color: themeColors.grayDark,
              '&.Mui-selected': { color: 'black' },
            }}
          />
        </Tabs>
      </Box>

      {isFleetLoading ? (
        <Loader />
      ) : (
        <>
          <TabPanel value={tabValue} index={0}>
            <MaintenanceTab fleet={fleet} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <AdministrativeTab fleet={fleet} />
          </TabPanel>
        </>
      )}
    </DashboardWrapper>
  );
};

export default FleetReportsContainer;
