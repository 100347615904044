import { Box, Grid } from '@mui/material';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Input, P1R } from 'src/components';
import { themeColors } from 'src/theme';
import { FormValues } from '..';

type NotesProps = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
  incidentStatus?: number;
};

const Notes = ({ formik, hidden = false, incidentStatus }: NotesProps) => {
  const { t } = useTranslation();
  const notesFieldVal = formik.values.notes;
  const charsCount = notesFieldVal.length;
  const maxCharsLimit = 500;

  return (
    <Grid container sx={{ margin: '0px 0 16px 0', display: hidden ? 'none' : 'block' }}>
      <Grid item>
        <Input
          multiline
          type="text"
          name="notes"
          label={t('eventsPage.notes')}
          placeholder="Type here"
          maxLength={maxCharsLimit}
          rows={6}
          value={formik.values.notes}
          onChange={formik.handleChange}
          disabled={incidentStatus === 1}
          sx={{ '& .MuiInputBase-root': { height: '100%' } }}
        />

        <Box sx={{ mt: -2, display: 'flex', justifyContent: 'right' }}>
          <P1R sx={{ color: themeColors.grayPlaceholders }}>{charsCount}/500</P1R>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Notes;
