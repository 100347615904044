import { Tabs as MuiTabs, Box, TabsProps as MuiTabsProps } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { LeftGradient, RightGradient } from './styled';

type TabsType = {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  children: ReactNode;
} & MuiTabsProps;

export default function Tabs({ value, onChange, children }: TabsType) {
  const [showLeftGradient, setShowLeftGradient] = useState(false);
  const [showRightGradient, setShowRightGradient] = useState(false);
  const tabsRef = useRef<HTMLDivElement | null>(null);

  const checkScroll = () => {
    if (tabsRef.current) {
      const scrollContainer = tabsRef.current.querySelector('.MuiTabs-scroller');
      if (!scrollContainer) return;

      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

      setShowLeftGradient(scrollLeft > 0);
      setShowRightGradient(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    checkScroll();
    const scrollContainer = tabsRef.current?.querySelector('.MuiTabs-scroller');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', checkScroll);
      return () => scrollContainer.removeEventListener('scroll', checkScroll);
    }

    return undefined;
  }, []);

  return (
    <Box sx={{ position: 'relative' }}>
      {showLeftGradient && <LeftGradient />}
      {showRightGradient && <RightGradient />}

      <MuiTabs ref={tabsRef} value={value} onChange={onChange} variant="scrollable" scrollButtons={false} allowScrollButtonsMobile={false}>
        {children}
      </MuiTabs>
    </Box>
  );
}
