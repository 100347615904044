import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { CssBaseline, Drawer, Box, Toolbar, List, Grid, IconButton, Container, useMediaQuery } from '@mui/material';
import { logout, setUserCurrentRole, loadUser } from 'src/redux/user/actions';
import { selectUser } from 'src/redux/user/selectors';
import { Button, H1, Icon, Logo, P2M, P3 } from 'src/components';
import { PATHS } from 'src/navigation';
import { themeColors } from 'src/theme';
import config from 'src/config';
import Breadcrumb from 'src/components/Breadcrumb';
import { setClearModal } from 'src/redux/clearme/actions';
import { ClearStatus } from 'src/shared/enums/clearStatus.enum';
import ContinueWithClearPopup from 'src/components/UserProfile/ContinueWithClearPopup';
import { MainListItems, SecondaryListItems } from './listItems';

const drawerWidth = 250;

const MuiDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  height: 'inherit',
  '& .MuiDrawer-paper': {
    border: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: 104,
      },
    }),
  },
}));

type DashboardProps = {
  children: React.ReactNode;
  navigationLabel?: React.ReactNode;
  navigationWidget?: React.ReactNode;
  navigationChildren?: React.ReactNode;
  breadcrumbs?: [string, string?][];
};

// eslint-disable-next-line react/prop-types
const DashboardWrapper: React.FC<DashboardProps> = ({
  children,
  navigationLabel,
  navigationWidget,
  navigationChildren,
  breadcrumbs = [],
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(true);
  const [openHamburger, setOpenHamburger] = useState(false);
  const isClearNotCompleted = user.clearStatus === ClearStatus.INCOMPLETE || user.clearStatus === ClearStatus.NOT_STARTED;

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleHamburgerDrawer = (isOpen: boolean) => {
    setOpenHamburger(isOpen);
  };

  const handleLogout = () => {
    dispatch(logout.init());
  };

  const changeRole = (role: string) => {
    dispatch(setUserCurrentRole.init({ role }));
    navigate(PATHS.MY_PROFILE_PAGE);
  };

  useEffect(() => {
    dispatch(loadUser.init());
  }, [dispatch]);

  useEffect(() => {
    // @ts-ignore
    if (!user?.uuid || user?.step > 1) {
      // Skip
    } else if (!user.emailConfirmed) {
      navigate(PATHS.CREATE_ACCOUNT_VERIFY_EMAIL_PAGE);
    }
  }, [dispatch, navigate, user]);

  const clearNotCompletedWarning = (
    <Box sx={{ backgroundColor: themeColors.grayLight }}>
      <Box
        sx={{
          p: 2,
          m: 2,
          gap: '8px',
          display: 'flex',
          flexDirection: 'column',
          background: themeColors.redLight,
          borderRadius: '10px',
          textAlign: 'center',
        }}
      >
        <P2M>You&apos;re almost there! </P2M>
        <P3
          sx={{ whiteSpace: 'pre-wrap', lineHeight: '13px', color: themeColors.grayDark }}
        >{`To access events please \ncomplete your CLEAR verification`}</P3>

        <Button onClick={() => dispatch(setClearModal.init({ isOpen: true }))} sx={{ textTransform: 'none', height: '32px', width: 'auto', mt: 1 }}>
          <P3>Complete Verification</P3>
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <Box
        sx={{
          height: '100vh',
          width: open ? 256 : 114,
          position: 'sticky',
          top: 0,
          left: 0,
          display: { xs: 'none', lg: 'block' },
        }}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={{
            width: '32px',
            height: '32px',
            border: `1px solid ${themeColors.grayMedium}`,
            backgroundColor: themeColors.white,
            position: 'absolute',
            right: open ? '-14px' : '-12px',
            top: '16px',
            zIndex: 1300,
            '&:hover': {
              backgroundColor: themeColors.white,
            },
          }}
        >
          {open ? <Icon name="minimizeArrow" /> : <Icon name="maximizeArrow" />}
        </IconButton>
        <MuiDrawer variant="permanent" open={open} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              backgroundColor: themeColors.grayLight,
              px: [1],
            }}
          >
            <Logo isMinimised={!open} sx={{ margin: '10px auto 0' }} title={config.getVersion()} onClick={() => navigate(PATHS.MY_PROFILE_PAGE)} />
          </Toolbar>
          <List component="nav" sx={{ display: 'contents' }}>
            {MainListItems(open, user)}
            {isClearNotCompleted && clearNotCompletedWarning}
            {SecondaryListItems(user, handleLogout, changeRole, open)}
          </List>
        </MuiDrawer>
      </Box>

      <Drawer anchor="right" open={openHamburger} onClose={() => setOpenHamburger(false)}>
        <Toolbar
          sx={{
            width: 340,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: themeColors.grayLight,
            px: [1],
          }}
        >
          {open && <Logo sx={{ margin: '10px auto 0' }} title={config.getVersion()} onClick={() => navigate(PATHS.MY_PROFILE_PAGE)} />}
          <IconButton onClick={() => setOpenHamburger(false)}>
            <Icon name="close" />
          </IconButton>
        </Toolbar>
        <List component="nav" sx={{ display: 'contents' }}>
          {MainListItems(open, user)}
          {isClearNotCompleted && clearNotCompletedWarning}
          {SecondaryListItems(user, handleLogout, changeRole, open)}
        </List>
      </Drawer>

      <Container
        id="container"
        maxWidth="xl"
        sx={{
          height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container rowGap={1} columnGap={2} sx={{ pt: 2, position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
          <Grid item xs lg="auto" order={{ xs: 1, lg: 1 }}>
            {isMobile ? (
              <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                {breadcrumbs.length > 1 && (
                  <Box onClick={() => navigate(-1)}>
                    <Icon name="arrowBack" />
                  </Box>
                )}

                <H1>{breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1][0] : ''}</H1>
              </Box>
            ) : (
              <Breadcrumb breadcrumbs={breadcrumbs} navigate={navigate} />
            )}

            {navigationLabel}
          </Grid>

          <Grid item xs={12} lg order={{ xs: 3, lg: 2 }}>
            {navigationWidget}
          </Grid>

          <Grid item xs="auto" md="auto" order={{ xs: 2, lg: 3 }} sx={{ display: { xs: 'flex', lg: 'none' }, alignItems: 'center' }}>
            <Box onClick={() => toggleHamburgerDrawer(true)}>
              <Icon name="hamburger" />
            </Box>
          </Grid>

          <Grid item xs={12} order={{ xs: 4 }} sx={{ bgcolor: 'yellow' }}>
            {navigationChildren}
          </Grid>
        </Grid>

        {children}

        {isClearNotCompleted && <ContinueWithClearPopup />}
      </Container>
    </Box>
  );
};

export default DashboardWrapper;
