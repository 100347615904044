import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, styled } from '@mui/material';
import { Button, H1, Icon, P1R } from 'src/components';
import { themeColors } from 'src/theme';
import { useTranslation } from 'react-i18next';
import warningIcon from '../../assets/warning.svg';

type UnsavedChangesPopupProps = {
  onCancel: () => void;
  onProceed: () => void;
  type?: 'unsavedPage' | 'unsavedPopup';
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function UnsavedChangesPopup({ onCancel, onProceed, type = 'unsavedPage' }: UnsavedChangesPopupProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <BootstrapDialog open onClose={onCancel} aria-labelledby="customized-dialog-title">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onCancel}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 5, mb: 2 }}>
            <Box sx={{ pb: 3 }}>
              <img src={warningIcon} alt="warning" />
            </Box>
            <H1 align="center">{t('unsavedChangesPopup.unsavedChanges')}</H1>
          </Box>
        </BootstrapDialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <P1R>
              {type === 'unsavedPage' && t('unsavedChangesPopup.changesLost')}
              {type === 'unsavedPopup' && t('unsavedChangesPopup.changesLostWindow')}
            </P1R>
          </Box>
          <Grid container columnSpacing={2} sx={{ mt: 3, mb: 4 }}>
            <Grid item xs={6}>
              <Button primary={false} onClick={onCancel} sx={{ width: '100%', textTransform: 'capitalize' }}>
                {type === 'unsavedPage' && t('unsavedChangesPopup.stayOnPage')}
                {type === 'unsavedPopup' && t('unsavedChangesPopup.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  onProceed();
                }}
                sx={{ width: '100%', textTransform: 'capitalize', backgroundColor: themeColors.red }}
              >
                {type === 'unsavedPage' && t('unsavedChangesPopup.leavePage')}
                {type === 'unsavedPopup' && t('unsavedChangesPopup.closeWindow')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}

export default UnsavedChangesPopup;
