import { Box } from '@mui/material';
import { P1R, H1 } from 'src/components';
import foremanPic from 'src/assets/foremanPic.svg';
import fleetPic from 'src/assets/fleet.svg';
import emptyList from 'src/assets/emptyList.svg';
import emptyTable from 'src/assets/emptyTable.svg';
import noRows from 'src/assets/noRows.svg';
import { themeColors } from 'src/theme';
import { ReactNode } from 'react';

type Props = {
  type: 'people' | 'fleet' | 'list' | 'table' | 'filters';
  size?: 'default' | 'small';
  title?: string;
  subTitle?: string | ReactNode;
  withBorder?: boolean;
  sx?: { [key: string]: any };
};

const defaultImgSize = '174px';
const smallImgSize = '64px';
const borderStyle = {
  border: `1px solid ${themeColors.grayMedium}`,
  borderRadius: '5px',
};

const EmptyState = ({ type, size = 'default', title = '', subTitle = '', withBorder, sx }: Props) => {
  const imgStyle = {
    height: size === 'small' ? smallImgSize : defaultImgSize,
    width: size === 'small' ? smallImgSize : defaultImgSize,
  };
  const getPicture = () => {
    const states = {
      people: foremanPic,
      fleet: fleetPic,
      list: emptyList,
      table: emptyTable,
      filters: noRows,
    };
    return states[type] || '';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        pt: 4,
        ...(withBorder ? borderStyle : {}),
        ...sx,
      }}
    >
      <Box sx={{ mb: 3 }}>
        <img src={getPicture()} alt="empty state picture" style={imgStyle} />
      </Box>
      {title ? <H1 sx={{ textTransform: 'capitalize', mb: 3 }}>{title}</H1> : null}
      <P1R sx={{ textAlign: 'center', whiteSpace: 'pre-line', mb: 3 }}>{subTitle}</P1R>
    </Box>
  );
};

export default EmptyState;
