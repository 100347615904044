import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Grid, Link, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { loadIncident } from 'src/redux/incident/actions';
import { Icon, P1R, P2 } from 'src/components';
import { themeColors } from 'src/theme';
import { RosterType, IncidentType, VehicleType, UserType, ListType } from 'src/types';
import { formatIncidentId, formatPhone, formatTimestamp, setParams } from 'src/utils';
import truckPic from 'src/assets/truckSmall.svg';
import foremanPic from 'src/assets/foremanSmall.svg';
import config from 'src/config';
import RosterStatus from 'src/components/RostersTable/RosterStatus';
import IncidentHeader from 'src/containers/IncidentContainer/components/IncidentHeader/IncidentHeader';
import { saveRoster } from 'src/redux/roster/actions';
import { PATHS } from 'src/navigation';
import InfoBlock from '../components/InfoBlock';
import { AssignmentsTypeLabel, AssignmentsAmountLabel, BaseItem, ContactBaseItem } from './styles/overviewTab.styles';

type Props = {
  roster: RosterType;
  incident: IncidentType;
  user: UserType;
  readOnly: boolean;
};

const rosterStatusesOptions: { [key: number]: number[] } = {
  0: [5],
  2: [3],
  3: [4],
  4: [3, 5],
};

function OverviewTab({ roster, incident, user, readOnly }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [statusesOptions, setStatusesOptions] = useState<number[]>([]);

  const incidentOwnedBy = {
    fullName: `${roster?.incident?.createdBy?.firstName || ''} ${roster?.incident?.createdBy?.lastName || ''}`,
    email: roster?.incident?.createdBy?.email || '',
    phone: roster?.incident?.createdBy?.phone || '',
  };

  // @ts-ignore
  const currUtility = config.options.utilities.find((obj: ListType) => obj.key === roster.incident?.utilityId)?.value;

  const fleet = roster.vehicles
    ?.filter((vehicle) => vehicle.assignments?.length)
    .reduce((accum: { type: string; amount: number }[], currVehicle: VehicleType) => {
      const existingVehicle = accum.find((vehicle) => vehicle.type === currVehicle.type);
      if (existingVehicle) {
        existingVehicle.amount += 1;
      } else {
        accum.push({ type: currVehicle.type, amount: 1 });
      }
      return accum;
    }, [])
    .sort((a, b) => b.amount - a.amount)
    .sort((a, b) => (a.amount !== b.amount ? 0 : a.type > b.type ? 1 : -1));

  const selectedClassificationsMap = (roster.linemen || []).reduce((acc: { [key: string]: number }, item) => {
    const classification = item.assignments?.[0]?.utilityFteClassification;
    if (classification) {
      acc[classification] ? (acc[classification] += 1) : (acc[classification] = 1);
    }
    return acc;
  }, {});

  const peopleClassifications = Object.entries(selectedClassificationsMap)
    .map(([name, amount]) => ({ name, amount }))
    .filter((item) => item.name !== 'N_A');

  const openStatusMenu = (event: MouseEvent<HTMLElement>, rosterStatus: number) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setStatusesOptions(rosterStatusesOptions[rosterStatus] || []);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleStatusChange = (event: MouseEvent<HTMLElement>, newStatus: number) => {
    event.stopPropagation();
    dispatch(saveRoster.init({ id: roster.id, status: newStatus }));
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!incident.id && roster?.incident?.id) {
      dispatch(loadIncident.init({ id: roster.incident.id }));
    }
  }, [incident, roster, dispatch]);

  return (
    <>
      <IncidentHeader incident={incident || roster.incident || undefined} />
      <Box sx={{ mt: 3 }} />

      <InfoBlock title="General Info">
        <Grid container rowSpacing={4}>
          <BaseItem item xs={6} md>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.rosterId')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>{formatIncidentId(roster.id)}</P1R>
          </BaseItem>

          <BaseItem item xs={6} md>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.displayName')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>{roster.name}</P1R>
          </BaseItem>

          <BaseItem item xs={6} md>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.status')}</P2>
            <Box sx={{ display: 'flex', justifyContent: 'left', cursor: 'pointer' }}>
              {!config.can('edit_roster', { user, roster }) || readOnly ? (
                <RosterStatus status={roster.status} />
              ) : (
                <>
                  <Box onClick={(event) => openStatusMenu(event, roster.status)}>
                    <RosterStatus isEditable status={roster.status} />
                  </Box>

                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    {statusesOptions.map((status) => (
                      <MenuItem key={status} onClick={(event) => handleStatusChange(event, status)} sx={{ width: '184px', gap: 2, display: 'flex' }}>
                        <P1R>
                          <RosterStatus status={status} />
                        </P1R>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </Box>
          </BaseItem>

          <BaseItem item xs={6} md>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.reportToYard')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>
              {
                // @ts-ignore
                config.options.yards[roster.yardFK - 1]?.value
              }
            </P1R>
          </BaseItem>

          <BaseItem item xs={6} md>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.startDay')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>{formatTimestamp(+roster.startDate * 1000)}</P1R>
          </BaseItem>

          <BaseItem item xs={6} md>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.reportTime')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>{roster?.startTime}</P1R>
          </BaseItem>

          <BaseItem item xs={6} md>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.notes')}</P2>
            <P1R sx={{ color: themeColors.grayDark, whiteSpace: 'normal', overflowWrap: 'break-word', wordBreak: 'break-word' }}>{roster?.notes}</P1R>
          </BaseItem>
        </Grid>
      </InfoBlock>

      <InfoBlock title="Equipment">
        {fleet && fleet.length > 0 ? (
          <Grid container columnSpacing={4}>
            {fleet?.map((vehicle, index) => (
              <AssignmentsTypeLabel item key={index} xs={12} sm={6} md={3}>
                <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
                  <Icon name={vehicle.type} />
                  <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t(`vehicles.${vehicle.type}`)}</P2>
                </Box>

                <AssignmentsAmountLabel>{vehicle.amount}</AssignmentsAmountLabel>
              </AssignmentsTypeLabel>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Box>
              <img src={truckPic} alt="Truck picture" />
            </Box>
            <P1R>
              {t('rosterPage.noFleet')} <br />
              {t('rosterPage.goTo')}
              <P1R
                onClick={() => navigate(setParams(PATHS.ROSTER_PAGE, { id: `${roster.id}` }), { state: { tab: 'equipment' } })}
                sx={{ color: themeColors.blue, cursor: 'pointer' }}
              >
                {' Equipment '}
              </P1R>
              {t('rosterPage.or')}
              <Link
                component={RouterLink}
                to={setParams(PATHS.INCIDENT_PAGE, {
                  id: roster.incident?.id?.toString() || '0',
                  eventTab: 'resources',
                  resourcesSubtab: 'vehicles',
                })}
                sx={{ textDecoration: 'none', color: themeColors.blue }}
              >
                {' Resources '}
              </Link>
              {t('rosterPage.tabToAdd')}
            </P1R>
          </Box>
        )}
      </InfoBlock>

      <InfoBlock title="FTEs">
        {peopleClassifications && peopleClassifications.length > 0 ? (
          <Grid container rowSpacing={2} columnSpacing={4}>
            {peopleClassifications?.map((classification, index) => (
              <AssignmentsTypeLabel item key={index} xs={12} sm={6} md={3}>
                <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>
                  {t(`utility_fte_classifications.${currUtility}.${classification.name}`)}
                </P2>

                <AssignmentsAmountLabel>{classification.amount}</AssignmentsAmountLabel>
              </AssignmentsTypeLabel>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Box>
              <img src={foremanPic} alt="Truck picture" />
            </Box>
            <P1R>
              {t('rosterPage.dataAvailable')}
              <br />
              <Link
                component={RouterLink}
                to={setParams(PATHS.INCIDENT_PAGE, {
                  id: roster.incident?.id?.toString() || '0',
                  eventTab: 'resources',
                  resourcesSubtab: 'lineman',
                })}
                sx={{ textDecoration: 'none', color: themeColors.blue }}
              >
                {' Resourses '}
              </Link>
              {t('rosterPage.or')}
              <P1R
                onClick={() => navigate(setParams(PATHS.ROSTER_PAGE, { id: `${roster.id}` }), { state: { tab: 'fte' } })}
                sx={{ color: themeColors.blue, cursor: 'pointer' }}
              >
                {' FTEs '}
              </P1R>
              {t('rosterPage.setClassifications')}
            </P1R>
          </Box>
        )}
      </InfoBlock>

      <InfoBlock title="Contacts">
        <Grid container rowGap={2}>
          <ContactBaseItem item xs={12} md={4}>
            <P2 sx={{ mb: 0.5, fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.stormManager')}</P2>
            <P1R>{incidentOwnedBy.fullName}</P1R>
            <P1R>{formatPhone(incidentOwnedBy.phone)}</P1R>
            <P1R>{incidentOwnedBy.email}</P1R>
          </ContactBaseItem>

          <ContactBaseItem item xs={12} md={4}>
            <P2 sx={{ mb: 0.5, fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.crewContact')}</P2>
            <P1R>{`${roster.ownedBy?.firstName || ''} ${roster.ownedBy?.lastName || ''}`}</P1R>
            <P1R>{roster.ownedBy?.phone ? formatPhone(roster.ownedBy?.phone) : ''}</P1R>
            <P1R>{roster.ownedBy?.email}</P1R>
          </ContactBaseItem>

          <ContactBaseItem item xs={12} md={4}>
            <P2 sx={{ mb: 0.5, fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterPage.alternateContact')}</P2>
            {roster.contactFullName || roster.contactPhone || roster.contactEmail ? (
              <>
                <P1R>{roster.contactFullName || ''}</P1R>
                <P1R>{roster.contactPhone ? formatPhone(roster.contactPhone) : ''}</P1R>
                <P1R>{roster.contactEmail}</P1R>
              </>
            ) : (
              <P1R sx={{ color: themeColors.grayDisabled }}>{t('rosterPage.noData')}</P1R>
            )}
          </ContactBaseItem>
        </Grid>
      </InfoBlock>
    </>
  );
}

export default OverviewTab;
