import React, { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Menu, MenuItem } from '@mui/material';
import { GridValueGetterParams, GridRenderCellParams, GridColDef } from '@mui/x-data-grid';

import { updateIncidentStatus } from 'src/redux/incident/actions';

import { IncidentType, UserType, ListType, IncidentStatus as IncidentStatusEnum } from 'src/types';
import { ClassificationType, FleetType } from 'src/types/incident';
import { formatIncidentId, formatTimestamp } from 'src/utils';
import config from 'src/config';

import { P1R, Table } from 'src/components';
import { TableProps } from '../Table';
import IncidentStatus from './IncidentStatus';

type Props = {
  incidents: IncidentType[];
  authUser: UserType;
  setIncident: (incidentId: number) => void;
  isEditable?: boolean;
} & Partial<TableProps>;

type AnchorEls = { [key: number]: HTMLElement | null };

export const incidentsColumnVisibilityModel = {
  id: false,
  name: false,
  ownedBy: false,
  utility: false,
  utilityContractor: false,
  workLocation: false,
  assignedTo: false,
  ibewPay: false,
  benefitsPay: false,
  travelReimbursement: false,
  lodgingProvided: false,
  meals: false,
  receipts: false,
  fteCount: false,
  fleetCount: false,
  yardFK: false,
  expectedDate: false,
  time: false,
  estimatedDuration: false,
  dailyHours: false,
  status: false,
};

const IncidentsTable: FC<Props> = ({ authUser, incidents, setIncident, isEditable = false, ...props }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [statusAnchorEls, setStatusAnchorEls] = useState<AnchorEls>({});
  const [statusesOptions, setStatusesOptions] = useState<IncidentStatusEnum[]>([]);

  const openStatusMenu = (event: MouseEvent<HTMLElement>, incidentId: number, incidentStatus: number) => {
    event.stopPropagation();
    setStatusAnchorEls((prevState) => ({
      ...prevState,
      [incidentId]: event.currentTarget,
    }));

    if (incidentStatus === IncidentStatusEnum.DRAFT) {
      setStatusesOptions([IncidentStatusEnum.ACTIVATED]);
    } else if (incidentStatus === IncidentStatusEnum.ACTIVATED) {
      setStatusesOptions([IncidentStatusEnum.CLOSED]);
    } else {
      setStatusesOptions([]);
    }
  };

  const closeStatusMenu = (event: MouseEvent<HTMLElement>, incidentId: number) => {
    event.stopPropagation();
    setStatusAnchorEls((prevState) => ({
      ...prevState,
      [incidentId]: null,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStatusChange = (event: MouseEvent<HTMLElement>, { createdBy, rosters, ...incident }: IncidentType, newStatus: number) => {
    event.stopPropagation();
    dispatch(updateIncidentStatus.init({ id: incident.id, status: newStatus }));
    closeStatusMenu(event, incident.id);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('eventsPage.id'),
      minWidth: 75,
      valueGetter: (params: GridValueGetterParams) => formatIncidentId(params.row.id),
    },
    {
      field: 'name',
      headerName: t('eventsPage.name'),
      editable: isEditable,
      minWidth: 200,
    },
    {
      field: 'ownedBy',
      headerName: t('eventsPage.ownedBy'),
      editable: isEditable,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => `${params.row.ownedBy?.firstName} ${params.row.ownedBy?.lastName}`,
    },
    {
      field: 'utility',
      headerName: t('eventsPage.utility'),
      editable: isEditable,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.utility?.name,
    },
    {
      field: 'utilityContractor',
      headerName: t('eventsPage.utilityContractor'),
      editable: isEditable,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.utilityContractor?.name,
    },
    {
      field: 'workLocation',
      headerName: t('eventsPage.workLocation'),
      editable: isEditable,
      minWidth: 150,
    },
    {
      field: 'assignedTo',
      headerName: t('eventsPage.assignedTo'),
      editable: isEditable,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.assignedTo.length ? params.row.assignedTo.map((person: UserType) => `${person.firstName} ${person.lastName}`).join(', ') : '',
    },
    {
      field: 'ibewPay',
      headerName: t('eventsPage.ibewPay'),
      editable: isEditable,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.ibewPay ? `${params.row.ibewPay}` : '';
      },
    },
    {
      field: 'benefitsPay',
      headerName: t('eventsPage.benefitsPay'),
      editable: isEditable,
      valueGetter: (params: GridValueGetterParams) => params.row.benefitsPay,
    },
    {
      field: 'travelReimbursement',
      headerName: t('eventsPage.travelReimbursement'),
      editable: isEditable,
      minWidth: 150,
    },
    {
      field: 'lodgingProvided',
      headerName: t('eventsPage.lodgingProvided'),
      editable: isEditable,
      valueGetter: (params: GridValueGetterParams) => (params.row.lodgingProvided === '1' ? 'No' : params.row.lodgingProvided === '2' ? 'Yes' : ''),
    },
    {
      field: 'meals',
      headerName: t('eventsPage.meals'),
      editable: isEditable,
      minWidth: 150,
    },
    {
      field: 'receipts',
      headerName: t('eventsPage.receipts'),
      editable: isEditable,
      minWidth: 75,
      valueGetter: (params: GridValueGetterParams) =>
        Object.keys(params.row.receipts).reduce<boolean>((acc, cur) => acc || params.row.receipts[cur as keyof typeof params.row.receipts], false)
          ? 'Yes'
          : 'No',
    },
    {
      field: 'fteCount',
      headerName: t('eventsPage.fteCount'),
      minWidth: 85,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.classifications?.reduce((acc: number, cur: ClassificationType) => acc + cur.amount, 0),
    },
    {
      field: 'fleetCount',
      headerName: t('eventsPage.fleetCount'),
      minWidth: 85,
      valueGetter: (params: GridValueGetterParams) => params.row.fleet?.reduce((acc: number, cur: FleetType) => acc + cur.amount, 0),
    },
    {
      field: 'yardFK',
      headerName: t('eventsPage.yardFK'),
      editable: isEditable,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) =>
        (config.options.yards.find((item: ListType) => item.key === params.row.yardFK) as ListType | undefined)?.value,
    },
    {
      field: 'expectedDate',
      headerName: t('eventsPage.expectedDate'),
      editable: isEditable,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => formatTimestamp(+params.row.expectedStartDate),
    },
    {
      field: 'time',
      headerName: t('eventsPage.time'),
      editable: isEditable,
    },
    {
      field: 'estimatedDuration',
      headerName: t('eventsPage.estimatedDuration'),
      editable: isEditable,
      minWidth: 125,
    },
    {
      field: 'dailyHours',
      headerName: t('eventsPage.dailyHours'),
      editable: isEditable,
      minWidth: 125,
    },
    {
      field: 'status',
      headerName: t('eventsPage.status'),
      editable: isEditable,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<any, number>): React.ReactNode => (
        <Box sx={{ display: 'flex', justifyContent: 'left' }}>
          {!config.can('edit_event', {
            user: authUser,
            incident: params.row,
          }) ? (
            <IncidentStatus status={params.row.status} />
          ) : (
            <>
              <Box onClick={(event) => openStatusMenu(event, params.row.id, params.row.status)}>
                <IncidentStatus isEditable status={params.row.status} />
              </Box>

              <Menu
                anchorEl={statusAnchorEls[params.row.id]}
                open={Boolean(statusAnchorEls[params.row.id])}
                onClose={(event: React.MouseEvent<HTMLElement>) => closeStatusMenu(event, params.row.id)}
              >
                {statusesOptions.map((status, index) => (
                  <MenuItem
                    key={index}
                    onClick={(event) => handleStatusChange(event, params.row, status)}
                    sx={{ width: '184px', gap: 2, display: 'flex' }}
                  >
                    <P1R>
                      <IncidentStatus status={status} />
                    </P1R>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>
      ),
    },
  ];

  return <Table {...props} type="advanced" columns={columns} rows={incidents} onRowClick={(row: any) => setIncident(row.id)} />;
};

export default IncidentsTable;
