import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';

import { selectIncident, selectIncidentDeclineLinemenSuccess } from 'src/redux/incident/selectors';
import {
  loadRoster,
  loadRosterAvailableVehicles,
  rosterSetStatus,
  saveRoster,
  rosterAttachLinemen,
  rosterDetachVehicles,
  rosterAttachVehicles,
  rosterDetachLineman,
  rosterRevert,
} from 'src/redux/roster/actions';
import { loadLinemenByLocation } from 'src/redux/users/actions';
import { loadFilteredVehicles } from 'src/redux/vehicles/actions';
import {
  selectRoster,
  selectRosterSetStatusSuccess,
  selectSavingRosterSuccess,
  selectRosterAttachingLinemen,
  selectRosterAttachingLinemenSuccess,
  selectRosterDetachLineman,
  selectRosterDetachLinemanSuccess,
  selectRosterSuccess,
  selectRosterAttachingLinemenErrors,
  selectrosterDetachVehicles,
  selectRosterAttachingVehiclesSuccess,
  selectrosterDetachVehiclesSuccess,
  selectRosterAttachingVehicles,
  selectRosterRevertSuccess,
  selectRosterLoading,
  selectRosterErrors,
  selectRosterSetStatusError,
  selectSavingRosterErrors,
  selectRosterAssignmentsError,
  selectRosterError,
} from 'src/redux/roster/selectors';
import { selectUser } from 'src/redux/user/selectors';
import { selectSupport, selectFavorites, selectLinemenByYard } from 'src/redux/users/selectors';
import { Button, Icon, H1, P1R, P2R, Notification, P2, Toolbar, H2, P1M, SomethingWentWrongState } from 'src/components';
import { formatIncidentId, formatTimestamp, getHoursAndMinutes, setParams } from 'src/utils';
import { RosterStatus as RosterStatusEnum, RosterApprovalStatus, WorkerType } from 'src/types';
import { themeColors } from 'src/theme';
import config from 'src/config';
import { PATHS } from 'src/navigation';
import Loader from 'src/components/Loader';
import { DashboardWrapper } from '../wrappers';
import RosterStatus from '../../components/RostersTable/RosterStatus';
import OverviewTab from './RosterOverviewTabs/Overview';
import PeopleTab from './RosterOverviewTabs/PeopleTab';
import VehiclesView from './RosterOverviewTabs/VehiclesView';
import Dialog from './Dialog';
import DialogTimesheet from './Dialogs/Timesheet';
import RejectRosterPopup from './RejectRosterPopup';
import ActionForbiddenPopup from '../IncidentContainer/ActionForbiddenPopup';
import LinemanNotAvailableDialog from '../IncidentContainer/LinemanNotAvailablePopup';
import EquipmentTab from './RosterOverviewTabs/EquipmentTab';
import LinemenView from './RosterOverviewTabs/LinemenView';
import SupportView from './RosterOverviewTabs/SupportView';
import FavoritesView from './RosterOverviewTabs/FavoritesView';
import VersionSnackbar from './Dialogs/VersionSnackbar';
import UtilityRosterCSVButton from './UtilityRosterCSV';
import RestoreVersionPopup from './Dialogs/RestoreVersionPopup';
import ActionsAreForbiddenSnackbar from './ActionsAreForbiddenSnackbar';

type Tab = 'overview' | 'equipment' | 'fte';
type View = '' | 'linemen' | 'support' | 'vehicles' | 'favorites';

export default function Roster() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const incident = useSelector(selectIncident);
  const roster = useSelector(selectRoster);
  const rosterSetStatusSuccess = useSelector(selectRosterSetStatusSuccess);
  const rosterSetStatusError = useSelector(selectRosterSetStatusError);
  const { id = '0' } = useParams();
  const rosterId = parseInt(id, 10);
  const { state } = useLocation();
  const user = useSelector(selectUser);
  const linemenByYard = useSelector(selectLinemenByYard);
  const supporters = useSelector(selectSupport);
  const favorites = useSelector(selectFavorites);
  const [activeTab, setActiveTab] = useState<Tab>('overview');
  const [activeView, setActiveView] = useState<View>('');
  const [rejectedRoster, setRejectedRoster] = useState<string | null>(null);
  const [openEditIncidentDialog, setOpenEditIncidentDialog] = useState(false);
  const [openTimesheetDialog, setOpenTimesheetDialog] = useState(false);
  const [errorAttachingLineman, setErrorAttachingLineman] = useState(false);
  const rosterName = `${roster?.name} ID ${roster?.id}`;
  const savingRosterSuccess = useSelector(selectSavingRosterSuccess);
  const rosterAssignmentsError = useSelector(selectRosterAssignmentsError);
  const savingRosterError = useSelector(selectSavingRosterErrors);
  const [linemenStandBySelected, setLinemenStandBySelected] = useState([]);
  const rosterAttachingLinemen = useSelector(selectRosterAttachingLinemen);
  const rosterAttachingLinemenSuccess = useSelector(selectRosterAttachingLinemenSuccess);
  const rosterAttachingLinemenErrors = useSelector(selectRosterAttachingLinemenErrors) as Record<string, string>;
  const incidentDeclineLinemenSuccess = useSelector(selectIncidentDeclineLinemenSuccess);
  const rosterAttachingVehicles = useSelector(selectRosterAttachingVehicles);
  const rosterDetachLinemanSuccess = useSelector(selectRosterDetachLinemanSuccess);
  const rosterDetachingLineman = useSelector(selectRosterDetachLineman);
  const loadRosterSuccess = useSelector(selectRosterSuccess);
  const loadRosterErrors = useSelector(selectRosterErrors) as string[];
  const isLoadRosterFailed = useSelector(selectRosterError);
  const loadRosterLoading = useSelector(selectRosterLoading);
  const rosterDetachingVehicles = useSelector(selectrosterDetachVehicles);
  const rosterAttachingVehiclesSuccess = useSelector(selectRosterAttachingVehiclesSuccess);
  const rosterDetachingVehiclesSuccess = useSelector(selectrosterDetachVehiclesSuccess);
  const rosterRevertSuccess = useSelector(selectRosterRevertSuccess);
  const [addedToRoster, setAddedToRoster] = useState(false);
  const [supportersSelected, setSupportersSelected] = useState([]);
  const [linemenSelected, setLinemenSelected] = useState([]);
  const [vehiclesToAdd, setVehiclesToAdd] = useState([]);
  const [vehiclesToRemove, setVehiclesToRemove] = useState([]);
  const [isVersionMenuOpen, setIsVersionMenuOpen] = useState(false);
  const [rosterVersion, setRosterVersion] = useState(0);
  const [showRestorePopup, setShowRestorePopup] = useState(false);
  const [showActionsForbiddenSnackBar, setShowActionsForbiddenSnackBar] = useState(false);
  // TODO: version without approval still contains deleted ftes and equipment, but without assignments
  const rosterFilteredLinemen = roster.linemen?.filter((lineman) => lineman.assignments?.length);
  const rosterFilteredEquipment = roster.vehicles?.filter((vehicle) => vehicle.assignments?.length);
  const isReadOnlyVersion = !roster.isLastVersion;
  // remove current modified version from display
  const rosterVersions = [...(roster.meta?.filter((item) => item.status) || [])].reverse();
  const drawerOffset = 148;
  const versionToRestore = rosterVersions[0];
  const isVersionRestorable = (version = 0) => version === versionToRestore?.version;
  const isRosterModified = roster.approvalStatus === RosterApprovalStatus.PENDING || roster.approvalStatus === RosterApprovalStatus.REJECTED;
  const isRosterEditable = !(roster.status === RosterStatusEnum.PENDING_APPROVAL || roster.status === RosterStatusEnum.CLOSED);
  const canSplitRoster = config.can('split_roster', { user, roster }) && config.can('edit_roster', { user, roster });
  const isRosterSplittable = roster.status === RosterStatusEnum.ACTIVATED && canSplitRoster && !isRosterModified;

  const handleAttachLinemen = () => {
    if (roster.status === RosterStatusEnum.PENDING_APPROVAL) {
      setErrorAttachingLineman(true);
    } else {
      dispatch(
        rosterAttachLinemen.init({
          id: rosterId,
          linemen: linemenStandBySelected,
          type: WorkerType.FTE,
        }),
      );
    }
  };

  const handleAttachSupport = () => {
    if (roster.status === RosterStatusEnum.PENDING_APPROVAL) {
      setErrorAttachingLineman(true);
    } else {
      dispatch(
        rosterAttachLinemen.init({
          id: rosterId,
          linemen: supportersSelected,
          type: WorkerType.SUPPORT,
        }),
      );
    }
  };

  const handleCloseErrorAttachingLineman = () => {
    setErrorAttachingLineman(false);
    dispatch(loadRoster.init({ id: rosterId }));
  };

  const removeVehicles = () => {
    dispatch(
      rosterDetachVehicles.init({
        id: rosterId,
        vehicles: vehiclesToRemove.map((id: number) => ({ id })),
      }),
    );
  };

  const handleDetachLineman = () => {
    dispatch(rosterDetachLineman.init({ id: rosterId, uuids: linemenSelected }));
  };

  const peopleActions = [
    {
      title: t('eventPage.addLinemen'),
      onClick: () => setActiveView('linemen'),
      active: true,
    },
    {
      title: t('eventPage.addSupport'),
      onClick: () => setActiveView('support'),
      active: true,
    },
    {
      title: 'Add Favorites',
      onClick: () => setActiveView('favorites'),
      active: true,
    },
    {
      title: t('eventPage.timesheet'),
      onClick: () => setOpenTimesheetDialog(true),
      active: !(![RosterStatusEnum.ACTIVATED, RosterStatusEnum.DEMOBILIZED].includes(roster.status) || (roster?.linemen?.length ?? 0) < 1),
    },
    {
      title: <P1R sx={{ color: themeColors.red }}>{t('eventPage.remove')}</P1R>,
      onClick: handleDetachLineman,
      active: !!linemenSelected.length,
    },
  ];

  const fleetActions = [
    {
      title: t('eventPage.addVehicle'),
      onClick: () => setActiveView('vehicles'),
      active: true,
    },
    {
      title: <P1R sx={{ color: themeColors.red }}>{t('eventPage.remove')}</P1R>,
      onClick: removeVehicles,
      active: !!vehiclesToRemove.length,
    },
  ];

  const handleCloseDialog = () => {
    dispatch(loadRoster.init({ id: rosterId }));
    dispatch(loadRosterAvailableVehicles.init({ id: 0 }));
    setOpenEditIncidentDialog(false);
  };

  const resetAttachingLinemen = () => {
    dispatch(rosterAttachLinemen.reset());
    dispatch(loadLinemenByLocation.init({ incidentId: roster.incidentId, yardId: roster.yardFK, favorites: true }));
  };

  const saveVehiclesChanges = () => {
    if (vehiclesToAdd.length) {
      dispatch(
        rosterAttachVehicles.init({
          id: rosterId,
          vehicles: vehiclesToAdd.map((id: number) => ({ id })),
        }),
      );
    }
    if (vehiclesToRemove.length) {
      removeVehicles();
    }
  };

  const cancelVehiclesChanges = () => {
    setVehiclesToAdd([]);
    setVehiclesToRemove([]);
    setActiveTab('equipment');
    setActiveView('');
    dispatch(rosterAttachVehicles.reset());
  };

  const saveLinemenChanges = () => {
    if (linemenStandBySelected.length) {
      handleAttachLinemen();
    }
    if (linemenSelected.length) {
      handleDetachLineman();
    }
  };

  const saveSupportChanges = () => {
    if (supportersSelected.length) {
      handleAttachSupport();
    }
    if (linemenSelected.length) {
      handleDetachLineman();
    }
  };

  const cancelLinemenSupportViewChanges = () => {
    setLinemenSelected([]);
    setLinemenStandBySelected([]);
    setSupportersSelected([]);
    setActiveTab('fte');
    setActiveView('');
    dispatch(rosterAttachLinemen.reset());
  };

  const handleVersionSelect = (version = 0) => {
    setRosterVersion(version);
    dispatch(loadRoster.init({ id: rosterId, version }));
    setIsVersionMenuOpen(false);
  };

  const handleVersionRestore = () => {
    setShowRestorePopup(false);
    dispatch(rosterRevert.init({ rosterId }));
  };

  const getVersionTimeFormat = (timestamp = '') => `${formatTimestamp(timestamp)} ${getHoursAndMinutes(timestamp)}`;

  const onCloseRosterNotification = () => {
    setAddedToRoster(false);
  };

  useEffect(() => {
    // Get default roter version (latest)
    dispatch(loadRoster.init({ id: rosterId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadRosterSuccess) {
      dispatch(loadLinemenByLocation.init({ incidentId: roster.incidentId, yardId: roster.yardFK, favorites: true }));
      dispatch(
        loadFilteredVehicles.init({
          assignedUuid: user.uuid,
          administrativeFlag: false,
          maintenanceFlag: false,
          availability: true,
        }),
      );
    }
  }, [loadRosterSuccess]);

  useEffect(() => {
    if (rosterSetStatusSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
      dispatch(rosterSetStatus.reset());
    }
  }, [dispatch, rosterId, rosterSetStatusSuccess]);

  useEffect(() => {
    if (rosterSetStatusError || savingRosterError || rosterAssignmentsError) {
      setShowActionsForbiddenSnackBar(true);
    }
  }, [rosterSetStatusError, savingRosterError, rosterAssignmentsError]);

  useEffect(() => {
    if (savingRosterSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
      dispatch(saveRoster.reset());
    }
  }, [dispatch, rosterId, savingRosterSuccess]);

  useEffect(() => {
    if (rosterAttachingLinemenSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
      setAddedToRoster(true);
      cancelLinemenSupportViewChanges();
    }
  }, [rosterAttachingLinemenSuccess]);

  useEffect(() => {
    if (incidentDeclineLinemenSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
    }
  }, [incidentDeclineLinemenSuccess]);

  useEffect(() => {
    if (rosterDetachLinemanSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
      cancelLinemenSupportViewChanges();
    }
  }, [rosterDetachLinemanSuccess]);

  useEffect(() => {
    if (rosterAttachingVehiclesSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
      setAddedToRoster(true);
      cancelVehiclesChanges();
    } else if (rosterDetachingVehiclesSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
      cancelVehiclesChanges();
    }
  }, [rosterAttachingVehiclesSuccess, rosterDetachingVehiclesSuccess]);

  useEffect(() => {
    if (rosterRevertSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
    }
  }, [rosterRevertSuccess]);

  useEffect(() => {
    if (loadRosterErrors) {
      navigate(PATHS.ERROR_CONNECTION_PAGE);
    }
  }, [navigate, loadRosterErrors]);

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state?.tab);
    }
  }, [state]);

  if (isLoadRosterFailed)
    return (
      <DashboardWrapper
        breadcrumbs={[
          ['home'],
          [
            `${roster.incident?.name || ''} ${formatIncidentId(roster.incident?.id || 0)}`,
            `${setParams(PATHS.INCIDENT_PAGE, { id: roster.incident?.id?.toString() ?? '', eventTab: 'myRosters', resourcesSubtab: 'linemen' })}`,
          ],
          [rosterName],
        ]}
      >
        <SomethingWentWrongState />
      </DashboardWrapper>
    );

  const NavigationWidget = (
    <Box
      sx={(theme) => ({
        pl: { xs: 6, md: 0 },
        pb: 3,
        gap: 1,
        position: 'sticky',
        zIndex: 999,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
        borderBottom: `1px solid ${themeColors.grayMedium}`,
      })}
    >
      <Box sx={{ gap: 3, display: 'flex', alignItems: 'center' }}>
        <H1 sx={{ display: { xs: 'none', md: 'block' } }}>{rosterName}</H1>

        <RosterStatus status={roster.status} />
      </Box>

      {config.can('approve_and_reject_any_roster') &&
        roster.approvalStatus === RosterApprovalStatus.PENDING &&
        roster.status !== RosterStatusEnum.PENDING_APPROVAL &&
        !isReadOnlyVersion && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="statusReapproval" />
            <P2R sx={{ textTransform: 'capitalize' }}>{t('rosterPage.reApproval')}</P2R>
          </Box>
        )}

      {config.can('approve_and_reject_any_roster') && roster.approvalStatus === RosterApprovalStatus.REJECTED && !isReadOnlyVersion && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="statusRejected" />
          <P2R color={themeColors.error}>{t('rosterPage.rejected')}</P2R>
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {isReadOnlyVersion && (
          <Box sx={{ px: 2, py: 1, borderRadius: '4px', bgcolor: themeColors.grayLight }}>
            <P1R>
              {t('rosterPage.viewing')} {getVersionTimeFormat(roster?.meta?.[rosterVersion - 1]?.date)}
            </P1R>
          </Box>
        )}

        {config.can('split_roster') && (
          <Button
            // TODO: create link type button for this and show more
            disabled={!isRosterSplittable}
            onClick={() => navigate(setParams(PATHS.ROSTER_PAGE_SPLIT, { id: `${roster.id}` }))}
            sx={{
              background: 'transparent',
              textTransform: 'none',
              px: 0,
              '&:hover, &.Mui-disabled': {
                background: 'transparent',
                boxShadow: 'none',
              },
            }}
          >
            <Icon
              name={
                // TODO: add disabled icon state
                !isRosterSplittable ? 'splitGray' : 'split'
              }
              sx={{ fontSize: '14px' }}
            />
            <P1R
              sx={{
                mx: 1,
                fontSize: '14px',
                color: !isRosterSplittable ? themeColors.grayDisabled : themeColors.blue,
              }}
            >
              {t('rosterPage.splitRoster')}
            </P1R>
          </Button>
        )}

        {config.can('edit_roster', { user, roster }) && (
          <Button
            // TODO: create link type button for this and show more
            disabled={roster.status === RosterStatusEnum.DRAFT || roster.status === RosterStatusEnum.PENDING_APPROVAL || !rosterVersions.length}
            onClick={() => setIsVersionMenuOpen((prevState) => !prevState)}
            sx={{
              background: 'transparent',
              textTransform: 'none',
              px: 0,
              '&:hover, &.Mui-disabled': {
                background: 'transparent',
                boxShadow: 'none',
              },
            }}
          >
            <Icon
              name={
                // TODO: add disabled icon state
                roster.status === RosterStatusEnum.DRAFT || roster.status === RosterStatusEnum.PENDING_APPROVAL || !rosterVersions.length
                  ? 'timerGray'
                  : 'timer'
              }
              sx={{ fontSize: '14px' }}
            />
            <P1R
              sx={{
                mx: 1,
                fontSize: '14px',
                color:
                  roster.status === RosterStatusEnum.DRAFT || roster.status === RosterStatusEnum.PENDING_APPROVAL || !rosterVersions.length
                    ? themeColors.grayDisabled
                    : themeColors.blue,
              }}
            >
              {t('eventPage.versionHistory')}
            </P1R>
            {isVersionMenuOpen ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  transform: 'rotate(180deg)',
                }}
              >
                <Icon name="arrowDownBlue" />
              </Box>
            ) : (
              <Icon
                name={
                  roster.status === RosterStatusEnum.DRAFT || roster.status === RosterStatusEnum.PENDING_APPROVAL || !rosterVersions.length
                    ? 'expandGray'
                    : 'arrowDownBlue'
                }
              />
            )}
          </Button>
        )}
      </Box>
    </Box>
  );

  return (
    <DashboardWrapper
      breadcrumbs={[
        ['home'],
        [
          `${roster.incident?.name || ''} ${formatIncidentId(roster.incident?.id || 0)}`,
          `${setParams(PATHS.INCIDENT_PAGE, { id: roster.incident?.id?.toString() ?? '', eventTab: 'myRosters', resourcesSubtab: 'linemen' })}`,
        ],
        [rosterName],
      ]}
      navigationChildren={NavigationWidget}
    >
      <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Container maxWidth={false} disableGutters sx={{ pt: 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Box gap={3} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={5} display="flex">
                  <Button
                    buttonType="group"
                    items={[
                      {
                        title: 'Overview',
                        onClick: () => {
                          setActiveTab('overview');
                          setActiveView('');
                        },
                        active: activeTab === 'overview',
                      },
                      {
                        title: `FTEs ${rosterFilteredLinemen?.length || 0}`,
                        onClick: () => {
                          setActiveTab('fte');
                          setActiveView('');
                        },
                        active: activeTab === 'fte',
                      },
                      {
                        title: `Equipment ${rosterFilteredEquipment?.length || 0}`,
                        onClick: () => {
                          setActiveTab('equipment');
                          setActiveView('');
                        },
                        active: activeTab === 'equipment',
                      },
                    ]}
                  />
                  {!!rosterVersions.length && !isReadOnlyVersion && config.can('edit_roster') && isRosterModified && (
                    <Button
                      onClick={() => setShowRestorePopup(true)}
                      sx={{
                        px: 1,
                        background: 'transparent',
                        textTransform: 'none',
                        '&:hover, &.Mui-disabled': {
                          background: 'transparent',
                          boxShadow: 'none',
                        },
                      }}
                    >
                      <Icon name="arrowRevert" />
                      <P1R sx={{ color: themeColors.yellowMain }}>{t('rosterPage.revertChanges')}</P1R>
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
                  {activeTab === 'fte' && !activeView && rosterFilteredLinemen?.length ? (
                    <Toolbar options={!isReadOnlyVersion && config.can('edit_roster', { user, roster }) ? 'all' : 'mini'} />
                  ) : null}
                  {activeTab === 'equipment' && !activeView && rosterFilteredEquipment?.length ? (
                    <Toolbar options={!isReadOnlyVersion && config.can('edit_roster', { user, roster }) ? 'all' : 'mini'} />
                  ) : null}
                  <Box sx={{ gap: 2, display: 'flex' }}>
                    {activeTab === 'overview' && <UtilityRosterCSVButton roster={roster} />}
                    {activeTab === 'overview' && config.can('approve_and_reject_roster', { user, roster }) && (
                      <Box sx={{ gap: 2, display: 'flex' }}>
                        <Button
                          onClick={() =>
                            dispatch(
                              rosterSetStatus.init({
                                id: roster.id,
                                status: 2,
                                message: '',
                              }),
                            )
                          }
                          sx={{
                            padding: '0 16px',
                            textTransform: 'capitalize',
                            backgroundColor: themeColors.green,
                          }}
                        >
                          {t('rosterPage.approve')}
                        </Button>
                        <Button
                          onClick={() => setRejectedRoster(roster.id as unknown as string)}
                          sx={{
                            padding: '0 16px',
                            textTransform: 'capitalize',
                            backgroundColor: themeColors.red,
                          }}
                        >
                          {t('rosterPage.reject')}
                        </Button>
                      </Box>
                    )}
                    {activeTab === 'overview' && !activeView && !isReadOnlyVersion && (
                      <Box sx={{ gap: '20px', display: 'flex' }}>
                        {config.can('edit_roster') && (
                          <Button
                            disabled={!config.can('edit_roster', { user, roster })}
                            onClick={() => setOpenEditIncidentDialog(true)}
                            primary={false}
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {t('rosterPage.editRoster')}
                          </Button>
                        )}
                        {config.can('send_roster_for_approval', { user, roster, skipStatus: true }) && (
                          <Button
                            disabled={!config.can('send_roster_for_approval', { user, roster }) || !isRosterEditable}
                            onClick={() =>
                              dispatch(
                                rosterSetStatus.init({
                                  id: roster.id,
                                  status: 1,
                                  message: '',
                                }),
                              )
                            }
                            sx={{ padding: '0 16px', textTransform: 'capitalize' }}
                          >
                            {roster.status === RosterStatusEnum.PENDING_APPROVAL ? 'Sent' : 'Send'} {t('rosterPage.forApproval')}
                          </Button>
                        )}
                      </Box>
                    )}
                  </Box>
                  {config.can('edit_roster') && activeTab === 'fte' && !activeView && (
                    <Box sx={{ gap: '20px', display: 'flex', pl: '10px' }}>
                      <Button
                        buttonType="dropdown"
                        disabled={!isRosterEditable || isReadOnlyVersion || !config.can('edit_roster', { user, roster })}
                        loading={rosterAttachingLinemen}
                        popperStyles={{ width: '215px', textAlign: 'left' }}
                        items={peopleActions}
                      >
                        {t('eventPage.menu')}
                      </Button>
                    </Box>
                  )}
                  {config.can('edit_roster') && activeTab === 'equipment' && !activeView && (
                    <Box sx={{ gap: '20px', display: 'flex', pl: '10px' }}>
                      <Button
                        buttonType="dropdown"
                        disabled={!isRosterEditable || isReadOnlyVersion || !config.can('edit_roster', { user, roster })}
                        loading={rosterAttachingVehicles}
                        popperStyles={{ width: '215px', textAlign: 'left' }}
                        items={fleetActions}
                      >
                        {t('eventPage.menu')}
                      </Button>
                    </Box>
                  )}
                  {config.can('edit_roster') && activeView === 'vehicles' && (
                    <Box sx={{ gap: '10px', display: 'flex' }}>
                      <Button
                        primary={false}
                        disabled={!(vehiclesToAdd.length || vehiclesToRemove.length)}
                        loading={rosterAttachingVehicles || rosterDetachingVehicles}
                        onClick={saveVehiclesChanges}
                        sx={{ padding: '0 16px', textTransform: 'capitalize' }}
                      >
                        <Icon
                          name="save"
                          fontSize="small"
                          // TODO: add disabled icon state
                          sx={{ mr: 1, color: !(vehiclesToAdd.length || vehiclesToRemove.length) ? themeColors.grayDisabled : themeColors.green }}
                        />
                        {t('rosterPage.save')}
                      </Button>
                      <Button primary={false} onClick={cancelVehiclesChanges} sx={{ padding: '0 16px', textTransform: 'capitalize' }}>
                        <Icon name="cancel" fontSize="small" sx={{ mr: 1, color: themeColors.red }} />
                        {t('rosterPage.cancel')}
                      </Button>
                    </Box>
                  )}
                  {config.can('edit_roster') && activeView === 'linemen' && (
                    <Box sx={{ gap: '10px', display: 'flex' }}>
                      <Button
                        primary={false}
                        disabled={!(linemenSelected.length || linemenStandBySelected.length)}
                        loading={rosterAttachingLinemen || rosterDetachingLineman}
                        onClick={saveLinemenChanges}
                        sx={{ padding: '0 16px', textTransform: 'capitalize' }}
                      >
                        <Icon
                          name="save"
                          fontSize="small"
                          // TODO: add disabled icon state
                          sx={{
                            mr: 1,
                            color: !(linemenSelected.length || linemenStandBySelected.length) ? themeColors.grayDisabled : themeColors.green,
                          }}
                        />
                        {t('rosterPage.save')}
                      </Button>
                      <Button primary={false} onClick={cancelLinemenSupportViewChanges} sx={{ padding: '0 16px', textTransform: 'capitalize' }}>
                        <Icon name="cancel" fontSize="small" sx={{ mr: 1, color: themeColors.red }} />
                        {t('rosterPage.cancel')}
                      </Button>
                    </Box>
                  )}
                  {config.can('edit_roster') && activeView === 'support' && (
                    <Box sx={{ gap: '10px', display: 'flex' }}>
                      <Button
                        primary={false}
                        disabled={!(linemenSelected.length || supportersSelected.length)}
                        loading={rosterAttachingLinemen || rosterDetachingLineman}
                        onClick={saveSupportChanges}
                        sx={{ padding: '0 16px', textTransform: 'capitalize' }}
                      >
                        <Icon
                          name="save"
                          fontSize="small"
                          // TODO: add disabled icon state
                          sx={{
                            mr: 1,
                            color: !(linemenSelected.length || supportersSelected.length) ? themeColors.grayDisabled : themeColors.green,
                          }}
                        />
                        {t('rosterPage.save')}
                      </Button>
                      <Button primary={false} onClick={cancelLinemenSupportViewChanges} sx={{ padding: '0 16px', textTransform: 'capitalize' }}>
                        <Icon name="cancel" fontSize="small" sx={{ mr: 1, color: themeColors.red }} />
                        {t('rosterPage.cancel')}
                      </Button>
                    </Box>
                  )}
                  {config.can('edit_roster') && activeView === 'favorites' && (
                    <Box sx={{ gap: '10px', display: 'flex' }}>
                      <Button
                        primary={false}
                        disabled={!(linemenSelected.length || linemenStandBySelected.length)}
                        loading={rosterAttachingLinemen || rosterDetachingLineman}
                        onClick={saveLinemenChanges}
                        sx={{ padding: '0 16px', textTransform: 'capitalize' }}
                      >
                        <Icon
                          name="save"
                          fontSize="small"
                          // TODO: add disabled icon state
                          sx={{
                            mr: 1,
                            color: !(linemenSelected.length || linemenStandBySelected.length) ? themeColors.grayDisabled : themeColors.green,
                          }}
                        />
                        {t('rosterPage.save')}
                      </Button>
                      <Button primary={false} onClick={cancelLinemenSupportViewChanges} sx={{ padding: '0 16px', textTransform: 'capitalize' }}>
                        <Icon name="cancel" fontSize="small" sx={{ mr: 1, color: themeColors.red }} />
                        {t('rosterPage.cancel')}
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
              {activeTab === 'overview' &&
                !activeView &&
                (loadRosterLoading ? <Loader /> : <OverviewTab readOnly={isReadOnlyVersion} roster={roster} incident={incident} user={user} />)}
              {activeView === 'linemen' &&
                (loadRosterLoading ? (
                  <Loader />
                ) : (
                  <LinemenView
                    rosterStatus={roster.status}
                    linemen={linemenByYard}
                    fte={rosterFilteredLinemen || []}
                    setSelectedLinemen={(selected: any) => {
                      setLinemenStandBySelected(selected);
                    }}
                    selectedLinemen={linemenStandBySelected}
                    selectedFte={linemenSelected}
                    setSelectedFte={(selected: any) => {
                      setLinemenSelected(selected);
                    }}
                  />
                ))}
              {activeView === 'support' &&
                (loadRosterLoading ? (
                  <Loader />
                ) : (
                  <SupportView
                    rosterStatus={roster.status}
                    support={supporters}
                    fte={rosterFilteredLinemen || []}
                    setSelectedSupport={(selected: any) => {
                      setSupportersSelected(selected);
                    }}
                    selectedSupport={supportersSelected}
                    selectedFte={linemenSelected}
                    setSelectedFte={(selected: any) => {
                      setLinemenSelected(selected);
                    }}
                  />
                ))}
              {activeTab === 'fte' &&
                !activeView &&
                (loadRosterLoading ? (
                  <Loader />
                ) : (
                  <PeopleTab
                    readOnly={isReadOnlyVersion || !isRosterEditable || !config.can('edit_roster')}
                    ibewPay={incident.ibewPay}
                    roster={roster}
                    rosterStatus={roster.status}
                    linemen={rosterFilteredLinemen}
                    fleet={rosterFilteredEquipment}
                    utilityId={roster.incident?.utilityId}
                    onRowSelectionModelChange={(selected: any) => {
                      setLinemenSelected(selected);
                    }}
                    rowSelectionModel={linemenSelected}
                  />
                ))}
              {activeTab === 'equipment' &&
                !activeView &&
                (loadRosterLoading ? (
                  <Loader />
                ) : (
                  <EquipmentTab
                    readOnly={isReadOnlyVersion || !isRosterEditable}
                    rosterVehicles={rosterFilteredEquipment}
                    roster={roster}
                    utilityId={roster.incident?.utilityId}
                    vehiclesToRemove={vehiclesToRemove}
                    setVehiclesToRemove={setVehiclesToRemove}
                  />
                ))}
              {activeView === 'vehicles' &&
                (loadRosterLoading ? (
                  <Loader />
                ) : (
                  <VehiclesView
                    rosterVehicles={rosterFilteredEquipment}
                    vehiclesToAdd={vehiclesToAdd}
                    setVehiclesToAdd={setVehiclesToAdd}
                    vehiclesToRemove={vehiclesToRemove}
                    setVehiclesToRemove={setVehiclesToRemove}
                  />
                ))}
              {activeView === 'favorites' &&
                (loadRosterLoading ? (
                  <Loader />
                ) : (
                  <FavoritesView
                    rosterStatus={roster.status}
                    favorites={favorites}
                    fte={rosterFilteredLinemen || []}
                    setSelectedFavorites={(selected: any) => {
                      setLinemenStandBySelected(selected);
                    }}
                    selectedFavorites={linemenStandBySelected}
                    selectedFte={linemenSelected}
                    setSelectedFte={(selected: any) => {
                      setLinemenSelected(selected);
                    }}
                  />
                ))}
            </Box>
          </Box>

          <Drawer
            variant="persistent"
            anchor="right"
            open={isVersionMenuOpen}
            PaperProps={{
              sx: {
                height: `calc(100% - ${drawerOffset})`,
                top: drawerOffset,
                width: 323,
              },
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, py: 2 }}>
              <H2>{t('eventPage.versionHistory')}</H2>
              <IconButton onClick={() => setIsVersionMenuOpen(false)}>
                <Icon name="close" />
              </IconButton>
            </Box>
            <List disablePadding>
              {isRosterModified && (
                <ListItem key={0} disablePadding>
                  <ListItemButton onClick={() => handleVersionSelect()} sx={{ flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                    <Box sx={{ backgroundColor: themeColors.lightYellow, p: 2, width: '100%', borderRadius: '4px', cursor: 'pointer' }}>
                      <P1M>{t('eventPage.currentModifiedVersion')}</P1M>
                    </Box>
                  </ListItemButton>
                </ListItem>
              )}
              {rosterVersions.map((version) => (
                <ListItem
                  key={version.date}
                  disablePadding
                  secondaryAction={
                    isVersionRestorable(version.version) ? (
                      config.can('edit_roster') && isRosterModified ? (
                        <IconButton onClick={() => setShowRestorePopup(true)} sx={{ borderRadius: '4px' }}>
                          <P1R sx={{ color: themeColors.blue, cursor: 'pointer' }}>{t('rosterPage.restore')}</P1R>
                        </IconButton>
                      ) : (
                        <P1R sx={{ color: themeColors.grayDark }}>{t('rosterPage.current')}</P1R>
                      )
                    ) : null
                  }
                  sx={{
                    '& .MuiListItemSecondaryAction-root': {
                      top: '24px',
                      right: '24px',
                    },
                  }}
                >
                  <ListItemButton
                    onClick={() => handleVersionSelect(version.version)}
                    sx={{
                      pl: 2,
                      pr: 1,
                      '&.MuiListItemButton-root': {
                        pr: 1,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        backgroundColor: isVersionRestorable(version.version) && !isRosterModified ? themeColors.lightYellow : 'unset',
                        py: 1,
                        px: 1,
                        width: '100%',
                        borderRadius: '4px',
                      }}
                    >
                      <P1R sx={{ fontWeight: isVersionRestorable(version.version) && !isRosterModified ? '600' : 'unset' }}>
                        {getVersionTimeFormat(version.date)}
                      </P1R>
                      <P2R sx={{ color: themeColors.grayDark, mt: 1 }}>
                        {roster.ownedBy?.firstName} {roster.ownedBy?.lastName}
                      </P2R>
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Container>
      </Box>
      {openEditIncidentDialog && (
        <Dialog template="edit-roster" onClose={() => handleCloseDialog()} incidentId={roster.incidentId as number} roster={roster} />
      )}
      {openTimesheetDialog && <DialogTimesheet onClose={() => setOpenTimesheetDialog(false)} roster={roster} />}
      {rejectedRoster && <RejectRosterPopup onClose={() => setRejectedRoster(null)} rosterName={rosterName} rosterId={roster?.id} />}
      <Notification open={addedToRoster} onClose={onCloseRosterNotification}>
        <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>Added to roster successfully.</P2>
      </Notification>
      {errorAttachingLineman && (
        <ActionForbiddenPopup errorMessage="While Roster is in Pending Approval status" onClose={handleCloseErrorAttachingLineman} />
      )}
      {rosterAttachingLinemenErrors && (
        <LinemanNotAvailableDialog
          errorMessage={rosterAttachingLinemenErrors.message}
          onConfirm={resetAttachingLinemen}
          onClose={resetAttachingLinemen}
        />
      )}
      {isReadOnlyVersion && (
        <VersionSnackbar
          open
          isRestorable={isVersionRestorable(rosterVersion)}
          versionDate={getVersionTimeFormat(roster?.meta?.[rosterVersion - 1]?.date)}
          onClose={() => handleVersionSelect()}
          onRestore={() => setShowRestorePopup(true)}
        />
      )}
      {showRestorePopup && (
        <RestoreVersionPopup
          rosterVersion={getVersionTimeFormat(versionToRestore.date)}
          onClose={() => setShowRestorePopup(false)}
          onConfirm={handleVersionRestore}
        />
      )}
      <ActionsAreForbiddenSnackbar open={showActionsForbiddenSnackBar} onClose={() => setShowActionsForbiddenSnackBar(false)} />
    </DashboardWrapper>
  );
}
