import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setIncident } from '../reducers';
import { loadIncident } from '../actions';
import { SagaAction } from '../../types';

function* loadIncidentSaga({ payload: { id = 0 } }: SagaAction<Partial<{ id: number }>>): SagaIterator {
  try {
    yield put(loadIncident.pending());

    const { payload } = yield call(API.loadIncident, { id: id.toString() });

    // TODO: temporary fix for utilityContractor prop
    const updatedPayload = {
      ...payload,
      utilityContractor: payload.utilityContractor || payload.utilityCompany,
    };

    // Technical Debt: Updated current incident with details
    yield put(setIncident(updatedPayload));
    yield put(loadIncident.success());
  } catch (e) {
    yield put(loadIncident.failure());
  }
}

export default loadIncidentSaga;
