import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogRocket from 'logrocket';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Link } from '@mui/material';
import { PATHS } from 'src/navigation';
import { createUser } from 'src/redux/user/actions';
import { selectCreatingUser, selectCreatingUserErrors, selectCreatingUserSuccess } from 'src/redux/user/selectors';
import { H1, Button, TextLink, FormikInputString, FormikPassword, FormikInputSelect, P3, FormikInputPhone } from 'src/components';
import config from 'src/config';
import { themeColors } from 'src/theme';
import { ListType, Role } from 'src/types';
import { RoleType } from 'src/shared/enums/roleType.enum';
import Blank from '../wrappers/Blank';

const TERMS_AND_CONDITIONS_LINK = 'https://storm360.com/user-agreement';
// const PRIVACY_POLICY_LINK = 'https://storm360.com/privacy-policy';

type FormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const CreateAccountContainer: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const creatingUser = useSelector(selectCreatingUser);
  const creatingUserErrors = useSelector(selectCreatingUserErrors) as Record<string, string[]>;
  const creatingUserSuccess = useSelector(selectCreatingUserSuccess);

  const roles = config.options.roles
    .filter((role: Role) => role.types.includes(RoleType.FIELD_WORKER))
    .map((role: Role) => ({ key: role.slug, value: role.name }))
    .concat([{ key: 'NEW_USER', value: 'Other' }]) as ListType[];

  useEffect(() => {
    if (creatingUserSuccess) {
      navigate(PATHS.CREATE_ACCOUNT_VERIFY_EMAIL_PAGE, { replace: true });
    }
  }, [dispatch, navigate, creatingUserSuccess]);

  // Technical Debt: Should be resolve on config level and run app
  const [, setConfigIsLoaded] = useState(false);
  useEffect(() => {
    config.refresh().then(() => setConfigIsLoaded(true));
  }, []);

  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('validations.required')),
      lastName: Yup.string().required(t('validations.required')),
      phone: Yup.string().phoneFormat().required(t('validations.required')), //
      role: Yup.string().required(t('validations.required')),
      email: Yup.string().required(t('validations.required')).email(t('validations.email')),
      password: Yup.string().required(t('validations.required')).min(8, 'Password must be at least 8 characters long'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
        .required(t('validations.required')),
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: async ({ confirmPassword, ...data }) => {
      const submitData = {
        ...data,
        termsAccepted: true,
      };

      if (config.LOGROCKET_ENABLED) {
        LogRocket.identify(`${data.email}_register`, {
          email: data.email,
          version: config.getVersion(),
        });
      }

      dispatch(createUser.init(submitData));
    },
  });

  const openTermsAndConditions = () => {
    window.open(TERMS_AND_CONDITIONS_LINK, '_blank', 'noreferrer');
  };

  return (
    <Blank>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '280px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', my: '24px' }}>
            <H1>{t('createAccountPage.title')}</H1>

            <TextLink style={{ display: 'flex', alignItems: 'center' }}>
              {t('createAccountPage.haveAccount')}
              <Link
                component="button"
                variant="body2"
                style={{
                  color: themeColors.blue,
                  textDecoration: 'none',
                  marginLeft: '4px',
                  fontSize: '14px',
                }}
                onClick={() => navigate(PATHS.LOGIN_PAGE)}
              >
                {t('common.signIn')}
              </Link>
            </TextLink>
          </Box>

          <Box sx={{ mb: '24px' }}>
            <FormikInputString formik={formik} field="firstName" label="First Name" errorHelper={creatingUserErrors} />
            <FormikInputString formik={formik} field="lastName" label="Last Name" errorHelper={creatingUserErrors} />
            <FormikInputPhone formik={formik} field="phone" label={t('profilePage.phone')} errorHelper={creatingUserErrors} />

            <FormikInputSelect formik={formik} field="role" label={t('createAccountPage.role')} options={roles} disableClearable />

            <FormikInputString
              formik={formik}
              field="email"
              label={t('createAccountPage.email')}
              disabled={creatingUser}
              errorHelper={creatingUserErrors}
              onChange={(e) => {
                dispatch(createUser.reset());
                const value = e.target.value || '';
                formik.setFieldValue('email', value.toLowerCase());
              }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <FormikPassword
                formik={formik}
                field="password"
                label={t('createAccountPage.createPassword')}
                disabled={creatingUser}
                errorHelper={creatingUserErrors}
                autoComplete="new-password"
              />

              <FormikPassword
                formik={formik}
                field="confirmPassword"
                label={t('createAccountPage.repeatPassword')}
                disabled={creatingUser}
                errorHelper={creatingUserErrors}
                autoComplete="new-password"
              />
            </Box>
            <P3 sx={{ color: 'red' }}>{creatingUserErrors?.message}</P3>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <TextLink>
              {t('createAccountPage.agreeStatement')}
              <Link
                component="button"
                variant="body2"
                style={{
                  color: themeColors.blue,
                  textDecoration: 'none',
                  marginLeft: '4px',
                  fontSize: '14px',
                }}
                onClick={openTermsAndConditions}
              >
                {t('createAccountPage.termsAndConditions')}.
              </Link>
            </TextLink>

            <Button
              fullWidth
              loading={creatingUser}
              disabled={creatingUser}
              onClick={() => formik.handleSubmit()}
              sx={{ textTransform: 'none', mb: 4 }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </Box>
    </Blank>
  );
};

export default CreateAccountContainer;
