import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FormikProps } from 'formik';
import { themeColors } from 'src/theme';
import { P1R } from '../Typography';

type FormikRadioGroupProps = {
  formik: FormikProps<any>;
  field: string;
  options: { value: number; label: string }[];
  onChange?: (event: any) => void;
};

const RadioButtonStyle = {
  color: themeColors.grayDisabled,
  '&.Mui-checked': { color: themeColors.blue },
  '& .MuiSvgIcon-root': { width: 24, height: 24 },
};

const FormikRadioGroup: React.FC<FormikRadioGroupProps> = ({ formik, field, options, onChange, ...props }): JSX.Element => {
  return (
    <RadioGroup
      {...props}
      name={field}
      value={formik.values[field]}
      onChange={onChange || formik.handleChange}
      sx={{ display: 'flex', flexDirection: 'row', gap: { xs: 0, sm: 4 } }}
    >
      {options.map((option) => (
        <FormControlLabel key={option.value} value={option.value} control={<Radio sx={RadioButtonStyle} />} label={<P1R>{option.label}</P1R>} />
      ))}
    </RadioGroup>
  );
};

export default FormikRadioGroup;
