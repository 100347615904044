import { TabProps as MuiTabProps } from '@mui/material';
import { P3 } from 'src/components';
import { ErrorBox, StyledTab, TabLabelWrapper } from './styled';

type CustomTabProps = {
  label: string;
  errorCount?: number;
  isTouched?: boolean;
} & MuiTabProps;

export default function Tab({ label, errorCount = 0, isTouched = false, ...props }: CustomTabProps) {
  return (
    <StyledTab
      {...props}
      hasError={!!(errorCount > 0 && isTouched)}
      label={
        <TabLabelWrapper>
          {label}
          {errorCount > 0 && isTouched && (
            <ErrorBox>
              <P3>
                {errorCount} {errorCount === 1 ? 'issue' : 'issues'}
              </P3>
            </ErrorBox>
          )}
        </TabLabelWrapper>
      }
    />
  );
}
