import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Grid, Button as MuiButton, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { createRoster, rosterTransfer, saveRoster } from 'src/redux/roster/actions';
import {
  selectCreatingRoster,
  selectCreatingRosterErrors,
  selectCreatingRosterSuccess,
  selectSavingRosterSuccess,
  selectRosterTransferSuccess,
} from 'src/redux/roster/selectors';
import {
  H1,
  H3,
  Button,
  Icon,
  FormikCalendar,
  FormikInputSelect,
  FormikInputString,
  FormikInputPhone,
  P1R,
  Input,
  UnsavedChangesPopup,
} from 'src/components';
import { tomorrowDate, setParams } from 'src/utils';
import config from 'src/config';
import { themeColors } from 'src/theme';
import { ListType, RosterType } from 'src/types';
import { PATHS } from 'src/navigation';
import dayjs from 'dayjs';
import RosterStatus from '../../components/RostersTable/RosterStatus';

type Props = {
  loading?: boolean;
  primary?: boolean;
  label?: string;
  template?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  incidentId: number;
  roster?: RosterType;
  incidentIdToTransfer?: number;
  onClose: () => void;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const AdapterDialog: React.FC<Props> = ({ label, disabled, children, incidentId, template, onClose, roster, ...props }): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { eventTab = 'myRosters', resourcesSubtab = 'linemen' } = useParams();
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);
  const creatingRoster = useSelector(selectCreatingRoster);
  const creatingRosterErrors = useSelector(selectCreatingRosterErrors) as Record<string, string[]>;
  const creatingRosterSuccess = useSelector(selectCreatingRosterSuccess);
  const savingRosterSuccess = useSelector(selectSavingRosterSuccess);
  const transferRosterSuccess = useSelector(selectRosterTransferSuccess);
  const maxCharsLimit = 500;

  // TODO: This should be tied to the BE
  let statusesList: ListType[] = [];
  if (roster?.status === 0) {
    // Don't remove the 0 because status field will be empty on Edit Roster
    statusesList = [
      { key: 0, value: 'Draft' },
      { key: 5, value: 'Closed' },
    ];
  } else if (roster?.status === 2) {
    // Don't remove the 2 because status field will be empty on Edit Roster
    statusesList = [
      { key: 2, value: 'Mobilized' },
      { key: 3, value: 'Activated' },
    ];
  } else if (roster?.status === 3) {
    // Don't remove the 3 because status field will be empty on Edit Roster
    statusesList = [
      { key: 3, value: 'Activated' },
      { key: 4, value: 'Demobilized' },
    ];
  } else if (roster?.status === 4) {
    // Don't remove the 3 because status field will be empty on Edit Roster
    statusesList = [
      { key: 3, value: 'Activated' },
      { key: 4, value: 'Demobilized' },
      { key: 5, value: 'Closed' },
    ];
  }

  let validationSchema;

  if (template === 'edit-roster') {
    validationSchema = Yup.object({
      email: Yup.string()
        // Technical Debt: validating email and/or phone number
        .email(t('validations.email')),
      yardFK: Yup.string().required(t('validations.required')),
      startTime: Yup.string().required(t('validations.required')),
      contactEmail: Yup.string().email(t('validations.email')),
      contactPhone: Yup.string().phoneFormat(),
    });
  } else {
    validationSchema = Yup.object({
      email: Yup.string()
        // Technical Debt: validating email and/or phone number
        .email(t('validations.email')),
      yardFK: Yup.string().required(t('validations.required')),
      startDate: Yup.number()
        .required(t('validations.required'))
        .test('is-not-past', 'Date should be in the future', (date) => !dayjs(date * 1000).isBefore(dayjs(), 'day')),
      startTime: Yup.string().required(t('validations.required')),
      contactEmail: Yup.string().email(t('validations.email')),
      contactPhone: Yup.string().phoneFormat(),
    });
  }

  const handleClose = () => {
    if (formik.dirty) {
      setUnsavedChangesPopupOpen(true);
    } else {
      onClose();
    }
  };

  const handleConfirmLeave = () => {
    setUnsavedChangesPopupOpen(false);
    onClose();
  };

  useEffect(() => {
    if (creatingRosterSuccess || savingRosterSuccess) {
      dispatch(createRoster.reset());
      dispatch(saveRoster.reset());
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, creatingRosterSuccess, savingRosterSuccess]);

  useEffect(() => {
    if (transferRosterSuccess && props.incidentIdToTransfer) {
      navigate(
        setParams(PATHS.INCIDENT_PAGE, {
          id: props.incidentIdToTransfer.toString(),
          eventTab,
          resourcesSubtab,
        }),
      );
      onClose();
    }
  }, [dispatch, transferRosterSuccess]);

  // Technical Debt: move formik to the container
  const formik = useFormik({
    initialValues: {
      id: roster?.id,
      name: roster?.name || '',
      yardFK: roster?.yardFK,
      startDate: +(roster?.startDate || tomorrowDate()),
      startTime: roster?.startTime || '',
      notes: roster?.notes || '',
      status: roster?.status || 0,
      incidentId,
      contactFullName: roster?.contactFullName || '',
      contactPhone: roster?.contactPhone || '',
      contactEmail: roster?.contactEmail || '',
    },
    validationSchema,
    onSubmit: async (data) => {
      const rosterData = {
        name: data.name,
        yardFK: data.yardFK,
        startDate: data.startDate,
        startTime: data.startTime,
        notes: data.notes,
        contactFullName: data.contactFullName,
        contactPhone: data.contactPhone,
        contactEmail: data.contactEmail,
      };
      if (template === 'edit-roster' && props.incidentIdToTransfer) {
        dispatch(
          rosterTransfer.init({
            rosterId: data.id,
            incidentId: props.incidentIdToTransfer,
            rosterData: {
              ...rosterData,
              name: `[Transferred] ${data.name}`,
            },
          }),
        );
      } else if (template === 'edit-roster') {
        dispatch(
          saveRoster.init({
            ...rosterData,
            status: data.status,
            id: data.id,
          }),
        );
      } else {
        dispatch(
          createRoster.init({
            ...rosterData,
            incidentId,
          }),
        );
      }
    },
  });

  return (
    <Box component="form" noValidate onSubmit={formik.handleSubmit}>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          // @ts-ignore
          onClose={handleClose}
        >
          <H1 align="center" sx={{ mt: 3 }}>
            {template === 'edit-roster' ? 'Edit Roster' : 'Create Roster'}
          </H1>
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 2, p: '20px' }}>
          <H3 sx={{ display: 'inline-block', mb: 1 }}>{t('rosterPage.generalInfo')}</H3>
          <FormikInputString
            formik={formik}
            field="name"
            label={t('rosterPage.displayName')}
            autoFocus
            isOptional
            disabled={creatingRoster}
            errorHelper={creatingRosterErrors}
          />
          <FormikInputSelect
            formik={formik}
            field="yardFK"
            label={t('rosterPage.yardFK')}
            options={config.options.yards}
            disableClearable
            errorHelper={creatingRosterErrors}
          />
          <FormikCalendar formik={formik} field="startDate" label="Start Day" disabled={creatingRoster} errorHelper={creatingRosterErrors} />
          <FormikInputSelect
            formik={formik}
            field="startTime"
            label={t('rosterPage.startTime')}
            options={config.times.map((value: string) => ({
              key: value,
              value,
            }))}
            disableClearable
            errorHelper={creatingRosterErrors}
          />
          <Box>
            <Input
              multiline
              type="text"
              name="notes"
              label={t('rosterPage.notes')}
              placeholder="Type here"
              maxLength={maxCharsLimit}
              rows={6}
              value={formik.values.notes}
              onChange={formik.handleChange}
              sx={{ '& .MuiInputBase-root': { height: '100%' } }}
            />
            <Box sx={{ mt: -2, display: 'flex', justifyContent: 'right' }}>
              <P1R sx={{ color: themeColors.grayPlaceholders }}>{formik.values.notes.length}/500</P1R>
            </Box>
          </Box>
          {template === 'edit-roster' && !props.incidentIdToTransfer ? (
            <FormikInputSelect
              formik={formik}
              field="status"
              label={t('rosterPage.status')}
              options={statusesList}
              disabled={creatingRoster}
              disableClearable
              renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: ListType) => (
                <MenuItem {...props}>
                  <RosterStatus status={option.key as number} />
                </MenuItem>
              )}
            />
          ) : null}
          <H3 sx={{ display: 'inline-block', mt: 1, mb: 1 }}>{t('rosterPage.alternateContact')}</H3>
          <FormikInputString
            formik={formik}
            field="contactFullName"
            label={t('rosterPage.fullName')}
            isOptional
            disabled={creatingRoster}
            errorHelper={creatingRosterErrors}
          />
          <FormikInputPhone
            formik={formik}
            field="contactPhone"
            label={t('rosterPage.phone')}
            isOptional
            disabled={creatingRoster}
            errorHelper={creatingRosterErrors}
          />
          <FormikInputString
            formik={formik}
            field="contactEmail"
            label={t('rosterPage.email')}
            isOptional
            disabled={creatingRoster}
            errorHelper={creatingRosterErrors}
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              {/* @ts-ignore */}
              <MuiButton
                fullWidth
                variant="outlined"
                onClick={handleClose}
                sx={{
                  height: '48px',
                  textTransform: 'none',
                  borderColor: themeColors.grayMedium,
                }}
              >
                {t('common.cancel')}
              </MuiButton>
            </Grid>
            <Grid item sm={6}>
              <Button fullWidth loading={creatingRoster} onClick={() => formik.handleSubmit()} sx={{ textTransform: 'capitalize' }}>
                {props.incidentIdToTransfer ? 'save and transfer' : t('common.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>

      {unsavedChangesPopupOpen && (
        <UnsavedChangesPopup onCancel={() => setUnsavedChangesPopupOpen(false)} onProceed={handleConfirmLeave} type="unsavedPopup" />
      )}
    </Box>
  );
};

export default AdapterDialog;
