import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { Icon, P1R, P2 } from 'src/components';
import IncidentStatus from 'src/components/IncidentsTable/IncidentStatus';
import { themeColors } from 'src/theme';
import { IncidentType } from 'src/types';
import { formatIncidentId, formatTimestamp } from 'src/utils';
import config from 'src/config';
import { BaseItem, DesktopItem, MobileBox, MobileContainer, HiddenOnMobileConditionalItem, MobileItem, BaseContainer } from './styles';

type IncidentHeaderProps = {
  incident?: Partial<IncidentType>;
  isBroadcastView?: boolean;
};

function IncidentHeader({ incident, isBroadcastView = false }: IncidentHeaderProps) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const defaultOrderClasifications = ['Journeyman Lineman', 'Operator', 'Groundman', 'Safety'];

  const sortClasifications = (a: any, b: any) => {
    // TODO: duplicate. move sorting to separate file
    if (defaultOrderClasifications.indexOf(a.classification?.name) === -1) return 1;
    if (defaultOrderClasifications.indexOf(b.classification?.name) === -1) return -1;
    if (defaultOrderClasifications.indexOf(a.classification?.name) < defaultOrderClasifications.indexOf(b.classification?.name)) return -1;
    if (defaultOrderClasifications.indexOf(a.classification?.name) < defaultOrderClasifications.indexOf(b.classification?.name)) return 1;
    return 0;
  };

  const sortedClassifications = (incident?.classifications || []).filter((item) => item.amount !== 0).sort(sortClasifications);

  const EmptyState = <P1R sx={{ color: themeColors.grayDisabled }}>No Data</P1R>;

  return (
    <Box
      sx={{
        padding: '24px 8px',
        borderRadius: '5px',
        backgroundColor: themeColors.grayLight,
      }}
    >
      <MobileContainer container rowGap={2}>
        {/* This regular Grid item is used to specially align the top column with the bottom one */}
        <Grid container xs={12} lg={6.6}>
          <BaseItem item lg>
            <Box sx={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
              <Box sx={{ position: 'absolute', left: 'clamp(-60px, -6.5vw, -60px)', top: 0, width: '50px' }}>
                <Icon name="incidentLight" />
              </Box>

              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase', whiteSpace: 'nowrap', overflow: 'hidden', mr: 1 }}>
                {t('rosterOverviewHeader.systemControlNumber')}
              </P2>

              <MobileBox showOnMobile={isBroadcastView}>{true && <IncidentStatus status={incident?.status ?? 0} />}</MobileBox>
            </Box>

            <P1R sx={{ color: themeColors.grayDark }}>{formatIncidentId(incident?.id)}</P1R>
          </BaseItem>

          <DesktopItem item lg>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.generalWorkLocation')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>{incident?.workLocation || EmptyState}</P1R>
          </DesktopItem>
        </Grid>

        <DesktopItem item lg>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.reportToYard')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>
            {
              // @ts-ignore
              // eslint-disable-next-line no-unsafe-optional-chaining
              config.options.yards[incident?.yardFK - 1]?.value
            }
          </P1R>
        </DesktopItem>

        <BaseItem item xs={12} lg sx={{ justifyContent: 'flex-end' }}>
          <Box onClick={() => setIsExpanded((prevState) => !prevState)} sx={{ display: 'flex', cursor: 'pointer', gap: '4px' }}>
            <P1R sx={{ fontSize: '14px', color: themeColors.blue }}>{isExpanded ? t('eventPage.showLess') : t('eventPage.showMore')}</P1R>
            <Box sx={{ display: 'flex', justifyContent: 'end', transform: isExpanded ? 'rotate(180deg)' : 'none', marginBottom: '3px' }}>
              <Icon name="arrowDownBlue" />
            </Box>
          </Box>
        </BaseItem>
      </MobileContainer>

      {isExpanded && (
        <>
          <Box sx={{ margin: '24px 0', borderBottom: `1px solid ${themeColors.grayMedium}`, width: '100%' }} />

          <BaseContainer container rowGap={3}>
            <HiddenOnMobileConditionalItem item xs={6} lg={2.2} showOnMobileAndDesktop={!isBroadcastView}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.status')}</P2>
              <Box sx={{ alignSelf: 'flex-start' }}>{incident?.status !== undefined ? <IncidentStatus status={incident?.status} /> : EmptyState}</Box>
            </HiddenOnMobileConditionalItem>

            <MobileItem item xs={6} lg={2.2}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.generalWorkLocation')}</P2>
              <P1R sx={{ color: themeColors.grayDark }}>{incident?.workLocation || EmptyState}</P1R>
            </MobileItem>

            <MobileItem item xs={6} lg={2.2}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.reportToYard')}</P2>
              <P1R sx={{ color: themeColors.grayDark }}>
                {
                  // @ts-ignore
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  config.options.yards[incident?.yardFK - 1]?.value
                }
              </P1R>
            </MobileItem>

            <BaseItem item xs={6} lg={2.2}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.ibewConditions')}</P2>
              <P1R sx={{ color: themeColors.grayDark }}>{incident?.ibewPay || EmptyState}</P1R>
            </BaseItem>

            <BaseItem item xs={6} lg={2.2}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.benefitsPay')}</P2>
              <P1R sx={{ color: themeColors.grayDark }}>{incident?.benefitsPay || EmptyState}</P1R>
            </BaseItem>

            <BaseItem item xs={6} lg={2.2}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.travelReimbursement')}</P2>
              <P1R sx={{ color: themeColors.grayDark }}>{incident?.travelReimbursement || EmptyState}</P1R>
            </BaseItem>

            <BaseItem item xs={6} lg={2.2}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.reportTimeAmdDate')}</P2>
              <P1R sx={{ color: themeColors.grayDark }}>
                {(incident && incident.expectedStartDate && `${incident?.time} ${formatTimestamp(+incident.expectedStartDate)}`) || EmptyState}
              </P1R>
            </BaseItem>

            <BaseItem item xs={12} lg={4.4}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventsPage.notes')}</P2>
              <P1R sx={{ color: themeColors.grayDark, whiteSpace: 'normal', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                {incident?.notes || EmptyState}
              </P1R>
            </BaseItem>

            <BaseItem item xs={12} lg={4.4}>
              <Box sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
                {sortedClassifications.map((classification, index) => (
                  <Box key={index} sx={{ gap: 1.5, display: 'flex', alignItems: 'center' }}>
                    <P1R sx={{ color: themeColors.grayDark, textAlign: 'center' }}>{classification.amount}</P1R>
                    <P2 sx={{ fontWeight: '600' }}>
                      {t(`utility_fte_classifications.${incident?.utility?.name}.${classification.classificationSlug}`)}
                    </P2>
                  </Box>
                ))}
              </Box>
            </BaseItem>

            <BaseItem item xs={12} lg>
              <Box sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
                {incident?.fleet
                  ?.filter((item) => item.amount !== 0)
                  .map((vehicle, index) => (
                    <Box key={index} sx={{ gap: 1.5, display: 'flex', alignItems: 'center' }}>
                      <P1R sx={{ color: themeColors.grayDark, textAlign: 'center' }}>{vehicle.amount}</P1R>
                      <P2 sx={{ fontWeight: '600' }}>{t(`utility_vehicle_classifications.${incident.utility?.name}.${vehicle.vehicleType}`)}</P2>
                    </Box>
                  ))}
              </Box>
            </BaseItem>
          </BaseContainer>
        </>
      )}
    </Box>
  );
}

export default IncidentHeader;
