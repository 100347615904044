import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dialog, Button, Icon, H1, P1R } from 'src/components';
import { PATHS } from 'src/navigation';
import { themeColors } from 'src/theme';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectClearModalOpen } from 'src/redux/clearme/selectors';
import { setClearModal } from 'src/redux/clearme/actions';

const ContinueWithClearPopup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOpen = useSelector(selectClearModalOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={modalOpen} aria-labelledby="customized-dialog-title" fullScreen={fullScreen}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            padding: '34px 0 34px 0',
            width: '314px',
            gap: '34px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <H1 sx={{ fontSize: '44px' }}>🥳</H1>

          <H1 sx={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{t('clearPopup.title')}</H1>

          <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column' }}>
            <P1R sx={{ width: '100%' }}>
              <Trans i18nKey="clearPopup.verifyIdentity" />
            </P1R>
            <P1R sx={{ width: '100%' }}>
              <Trans i18nKey="clearPopup.haveDocuments" />
            </P1R>
            <P1R sx={{ width: '100%' }}>
              <Trans i18nKey="clearPopup.note" />
            </P1R>
          </Box>

          <Box
            sx={{
              padding: '16px',
              gap: '16px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '4px',
              backgroundColor: themeColors.lightYellow,
            }}
          >
            <Box>
              <Icon name="attention" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <P1R sx={{ fontWeight: '500', whiteSpace: 'pre-line' }}>{t('clearPopup.importantMessage')}</P1R>
            </Box>
          </Box>

          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Button
              primary={false}
              onClick={() => navigate(PATHS.CLEARME_AUTH, { state: { initClear: true } })}
              sx={{ width: '100%', flexGrow: 1, borderColor: themeColors.black, textTransform: 'none', ':hover img': { filter: 'invert(100%)' } }}
            >
              <Icon name="clearmeIcon" sx={{ mr: 2 }} />
              {t('clearPopup.continue')}
            </Button>

            <Button
              primary={false}
              onClick={() => dispatch(setClearModal.init({ isOpen: false }))}
              sx={{ width: '100%', mt: 1, textTransform: 'capitalize' }}
            >
              Skip For Now
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ContinueWithClearPopup;
