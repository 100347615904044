import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LogRocket from 'logrocket';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Container, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { themeColors } from 'src/theme';
import { H1, P1R, P3, FormikInputString, FormikPassword } from 'src/components';
import { login, loadUser } from 'src/redux/user/actions';
import { selectUser, selectLogining, selectLoginingSuccess, selectLoginingError } from 'src/redux/user/selectors';
import { PATHS } from 'src/navigation';
import config from 'src/config';
import { setClearModal } from 'src/redux/clearme/actions';

import { BlankWrapper } from '../wrappers';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const logining = useSelector(selectLogining);
  const loginingSuccess = useSelector(selectLoginingSuccess);
  const loginingError = useSelector(selectLoginingError);

  useEffect(() => {
    // Technical Debt: Upgrade loading container
    if (!user.uuid && config.TOKEN) {
      dispatch(loadUser.init());
    }
  }, []);

  useEffect(() => {
    if (loginingSuccess || user) {
      if (!user.uuid) {
        // Skip
      } else if (!user.emailConfirmed) {
        navigate(PATHS.CREATE_ACCOUNT_VERIFY_EMAIL_PAGE);
      } else {
        dispatch(setClearModal.init({ isOpen: true }));
        navigate(PATHS.MY_PROFILE_PAGE);
      }
    }
  }, [navigate, dispatch, loginingSuccess, user]);

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email(t('validations.email')).required(t('validations.required')),
      password: Yup.string()
        .min(7, t('validations.minPassword', { size: 7 }))
        .required(t('validations.required')),
    }),
    onSubmit: async ({ email, password }) => {
      if (config.LOGROCKET_ENABLED) {
        LogRocket.identify(`${email}_login`, {
          email,
          version: config.getVersion(),
        });
      }
      dispatch(login.init({ email, password }));
    },
  });

  return (
    <BlankWrapper>
      <Container>
        <Grid container sx={{ padding: { xs: 0, md: 7 }, margin: '0 auto' }} maxWidth="sm">
          <Grid item xs={12} sx={{ pt: { xs: 0, md: 7 } }}>
            <H1 style={{ marginBottom: '24px' }}>{t('loginPage.title')}</H1>
            <P1R>{t('loginPage.subTitle')}</P1R>
            <Box component="form" marginTop={3} noValidate onSubmit={formik.handleSubmit}>
              <FormikInputString
                formik={formik}
                field="email"
                label={t('loginPage.email')}
                disabled={logining}
                autoFocus
                onChange={(e) => {
                  const value = e.target.value || '';
                  formik.setFieldValue('email', value.toLowerCase());
                }}
              />
              <FormikPassword formik={formik} field="password" label={t('loginPage.password')} disabled={logining} />
              <Box sx={{ textAlign: 'right', mt: 1, mb: 2 }}>
                <Link
                  component={RouterLink}
                  to="/forgot-password"
                  variant="body2"
                  sx={{
                    textDecoration: 'none',
                    color: themeColors.blue,
                    textTransform: 'capitalize',
                  }}
                >
                  <P3>{t('loginPage.forgotPassword')}</P3>
                </Link>
              </Box>
              {loginingError && <P3 sx={{ color: themeColors.error, mt: 3, mb: 2 }}>{t('loginPage.error')}</P3>}
              <LoadingButton
                type="submit"
                fullWidth
                loading={logining}
                disabled={!formik.values.email || !formik.values.password}
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 4,
                  height: 48,
                  textTransform: 'capitalize',
                }}
              >
                {t('loginPage.buttonTitle')}
              </LoadingButton>
              <Box sx={{ mb: 4 }}>
                <P1R>
                  {t('loginPage.dontHaveAccount')}
                  <Link
                    component={RouterLink}
                    to="/create-account"
                    variant="body2"
                    sx={{
                      ml: 0.5,
                      fontSize: '14px',
                      textDecoration: 'none',
                      color: themeColors.blue,
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('loginPage.linkCreateAccount')}
                  </Link>
                </P1R>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </BlankWrapper>
  );
}

export default Login;
