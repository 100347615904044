import { Box, styled } from '@mui/material';

export const RightGradient = styled(Box)(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  width: '60px',
  background: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
  zIndex: 1,
  pointerEvents: 'none',
}));

export const LeftGradient = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  width: '60px',
  background: 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
  zIndex: 1,
  pointerEvents: 'none',
}));
