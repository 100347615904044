import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { IncidentType } from 'src/types';
import { updateIncidentStatus } from '../actions';
import { SagaAction } from '../../types';

function* updateIncidentStatusSaga({ payload: { id, status } }: SagaAction<Partial<IncidentType>>): SagaIterator {
  try {
    yield put(updateIncidentStatus.pending());

    yield call(API.updateIncidentStatus, { id, status });

    yield put(updateIncidentStatus.success());
  } catch (e) {
    yield put(updateIncidentStatus.failure(e));
  }
}

export default updateIncidentStatusSaga;
