import { Box, styled, Tab } from '@mui/material';
import { themeColors } from 'src/theme';

export const StyledTab = styled(Tab)<{ hasError?: boolean }>(({ hasError }) => ({
  textTransform: 'none',
  fontSize: '16px',
  color: hasError ? themeColors.red : themeColors.grayDark,
  '&.Mui-selected': { color: 'black' },
}));

export const TabLabelWrapper = styled(Box)({
  position: 'relative',
});

export const ErrorBox = styled(Box)({
  top: '-20px',
  position: 'absolute',
  color: themeColors.red,
});
