import i18n from 'i18next';

export function translationExists(key: string) {
  /**
   * Checks if a translation key exists for a given language.
   *
   * @param {string} key The translation key to check.
   * @returns {boolean} True if the translation exists.
   */
  if (!key) {
    return false;
  }
  return i18n.exists(key);
}
