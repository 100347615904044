import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';
import { Badge, Box, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { updateUserPassword, verifyUnionInfo } from 'src/redux/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectErrorUpdatingUserPassword,
  selectSuccessUpdatingUserPassword,
  selectSavingProfile,
  selectSaveProfileSuccess,
} from 'src/redux/user/selectors';
import { UserType, ListType, Role } from 'src/types';
import { RoleType } from 'src/shared/enums/roleType.enum';
import { themeColors } from 'src/theme';
import { zipCodeMask, ssnMask, getUnionClassifications, enumToTranslatedOptions, translationExists } from 'src/utils';
import config from 'src/config';
import { PATHS } from 'src/navigation';
import {
  H3,
  FormikInputString,
  FormikInputSelect,
  Accordion,
  Button,
  FormikInputSelectMulti,
  Attachment,
  FormikCalendar,
  Icon,
  P2M,
  InputSelectMulti,
  Avatar,
  FormikInputPhone,
  P1R,
  FormikRadioGroup,
} from 'src/components';
import Snackbar from 'src/components/Snackbar';
import { ClearStatus } from 'src/shared/enums/clearStatus.enum';
import { UnionInfoStatus } from 'src/shared/enums/unionInfoStatus.enum';
import { RubberSleeveSize } from 'src/shared/enums/rubberSleeveSize.enum';
import { EmploymentTypeEnum } from 'src/shared/enums/employmentType.enum';
import { FormValues } from '..';
import ChangePasswordPopup from '../ChangePasswordPopup';
import CannotChangePassword from '../CannotChangePassword';
import RejectUnionInfoDialog from '../RejectUnionInfoDialog';
import UpdateInfoPopup from '../UpdateInfoPopup';
import UpdateAvatarModal from '../UploadAvatarModal';
import {
  VerifiedLabel,
  NotCompleteLabel,
  NotCompletedInfo,
  InReviewLabel,
  RejectedLabel,
  UpdateButtons,
  VerifyButtons,
  OnboardingDetails,
} from './components';

type Props = {
  user: UserType;
  formik: FormikProps<FormValues>;
  can: {
    [key: string]: boolean;
  };
  isOwner: boolean;
  handleUpdate: (sectionName: string, fields: string[]) => void;
};

const addressFields = ['country', 'streetAddress', 'streetAddress2', 'city', 'state', 'region', 'zipcode'];
const idFields = ['id_front', 'id_back'];
const ppeFields = ['rubberSleeve', 'rubberGlove', 'workVest', 'workGlove'];
const unionFields = {
  [EmploymentTypeEnum.UNION]: [
    'localUnion',
    'unionTicket',
    'employmentType',
    'unionClassification',
    'driverLicenseExpiration',
    'cdlEndorsements',
    'cdlRestrictions',
    'union',
    'driver_license_front',
    'driver_license_back',
  ],
  [EmploymentTypeEnum.NON_UNION]: [
    'employmentType',
    'unionClassification',
    'driverLicenseExpiration',
    'cdlEndorsements',
    'cdlRestrictions',
    'employmentType',
    'driver_license_front',
    'driver_license_back',
  ],
  submitFormFields: [
    'localUnion',
    'unionTicket',
    'employmentType',
    'unionClassification',
    'driverLicenseExpiration',
    'cdlEndorsements',
    'cdlRestrictions',
  ],
};
const emergencyContactFields = ['contactFirstName', 'contactLastName', 'contactPhone', 'contactEmail', 'contactRelationship'];
const attachmentTypes = {
  union: true,
  driver_license_front: true,
  driver_license_back: true,
  id_front: true,
  id_back: true,
};

const RowStyle = {
  gap: { xs: 0, md: '14px' },
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
};

const getUnionClassificationOptions = (employmentType: EmploymentTypeEnum, localUnion: string | null, t: (key: string) => string) => {
  if (employmentType === EmploymentTypeEnum.NON_UNION) {
    const nonUnionClassifications = config?.options?.GENERIC_CLASSIFICATIONS || [];

    return nonUnionClassifications.map((classification: string) => ({
      key: classification,
      value: t(`union_classifications.GENERIC.${classification}`),
    }));
  }

  return getUnionClassifications(localUnion, '').map((classification: string) => {
    const keyToCheck = `union_classifications.${localUnion}.${classification}`;
    const translationKey = translationExists(keyToCheck) ? keyToCheck : `union_classifications.GENERIC.${classification}`;

    return { key: classification, value: t(translationKey) };
  });
};

type Sections = { [key: string]: boolean };

function GeneralInfo({ user, formik, can, isOwner, handleUpdate }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeUpload, setActiveUpload] = useState<null | string>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openUpateInfoPopup, setOpenUpateInfoPopup] = useState(false);
  const [openAvatarPopup, setOpenAvatarPopup] = useState(false);
  const [rejectUnionInfo, setRejectUnionInfo] = useState(false);
  const [isExpanded, setIsExpanded] = useState<Sections>({
    account: false,
    preferredName: false,
    roles: false,
    address: false,
    id: false,
    ppe: false,
    union: can.manageRoles && !can.editOwn,
    emergency: false,
    email: false,
  });
  const dispatch = useDispatch();
  const errorUpdatingUserPassword = useSelector(selectErrorUpdatingUserPassword);
  const successUpdatingUserPassword = useSelector(selectSuccessUpdatingUserPassword);
  const savingProfile = useSelector(selectSavingProfile);
  const saveProfileSuccess = useSelector(selectSaveProfileSuccess);
  const displayName = `${user.firstName ?? 'New'} ${user.lastName ?? 'User'}`;
  const profilePicture = user.attachments?.profile_picture?.location || '';

  const roles = config.options.roles
    .filter((role: Role) => !role.types.includes(RoleType.HIDDEN))
    .map((role: Role) => ({ key: role.slug, value: role.name }))
    .concat(user.currentRole === 'NEW_USER' ? [{ key: 'NEW_USER', value: 'New User' }] : []) as ListType[];

  const { country } = formik.values;
  const states = country ? (country === 'Canada' ? config.options.STATES.CA : config.options.STATES.US) : [];

  const handleActiveUpload = (uploadName: string) => {
    setActiveUpload(uploadName);
  };

  const resetField = (name: string) => {
    formik.setFieldValue(name, formik.initialValues[name as keyof FormValues]);
  };

  const resetFields = (fieldNames: string[]) => fieldNames.forEach((name) => resetField(name));

  const isFieldDirty = (name: string) => {
    const { value, initialValue } = formik.getFieldMeta(name);
    return Array.isArray(value) && Array.isArray(initialValue) ? value.join() !== initialValue.join() : value !== initialValue;
  };

  const isSectionDirty = (fieldNames: string[]): boolean => fieldNames.some((field) => isFieldDirty(field));

  const isSectionUncompleted = (fieldNames: string[]) =>
    fieldNames.some((name) => {
      if (name in attachmentTypes) {
        return !(user.attachments && name in user.attachments);
      }
      const fieldValue = formik.getFieldMeta(name).value;
      return !fieldValue || (Array.isArray(fieldValue) && !fieldValue.length);
    });

  const handleApproveUnionSection = () =>
    dispatch(
      verifyUnionInfo.init({
        uuid: user.uuid,
        verifyInfo: { unionInfoStatus: UnionInfoStatus.VERIFIED, unionRejectedComment: null },
      }),
    );

  const handleRejectUnionInfo = (message: string) =>
    dispatch(
      verifyUnionInfo.init({
        uuid: user.uuid,
        verifyInfo: { unionInfoStatus: UnionInfoStatus.REJECTED, unionRejectedComment: message },
      }),
    );

  const handleExpanded = (sectionName: string) =>
    setIsExpanded({
      ...isExpanded,
      [sectionName]: !isExpanded[sectionName],
    });

  useEffect(() => {
    if (saveProfileSuccess) {
      setOpenSuccessSnackbar(true);
    }
  }, [saveProfileSuccess]);

  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            isOwner && (
              <Button
                onClick={() => setOpenAvatarPopup(true)}
                size="small"
                sx={{ borderRadius: 100, minWidth: 'unset', width: '32px', height: '32px', p: 0 }}
              >
                {profilePicture ? <Icon name="edit" size={18} /> : <Icon name="add" size={18} />}
              </Button>
            )
          }
        >
          <Avatar
            name={displayName}
            src={user.attachments?.profile_picture?.location || ''}
            sx={{ borderRadius: 100, width: '100px', height: '100px' }}
          />
        </Badge>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Accordion
          expanded={isExpanded.account}
          onChange={() => handleExpanded('account')}
          title={
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <H3>{t('profilePage.account')}</H3>
              {user.clearStatus === 1 && !isExpanded.account ? <VerifiedLabel /> : null}
            </Box>
          }
          sx={{ width: { xs: '100%', md: '622px' } }}
          actions={
            isOwner ? (
              user.clearStatus !== ClearStatus.APPROVED ? (
                <Button
                  primary={false}
                  onClick={() => navigate(PATHS.CLEARME_AUTH, { state: { initClear: true } })}
                  sx={{ width: '280px', textTransform: 'none', ':hover img': { filter: 'invert(100%)' } }}
                >
                  <Icon name="clearmeIcon" sx={{ mr: 2 }} />
                  {t('emailVerifiedPage.buttonText')}
                </Button>
              ) : (
                <Button style={{ textTransform: 'none' }} onClick={() => setOpenUpateInfoPopup(true)}>
                  {t('profilePage.updateMyInfo')}
                </Button>
              )
            ) : null
          }
        >
          {user.clearStatus === 1 ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Icon name="accepted" sx={{ mr: 1 }} />
              <P2M sx={{ whiteSpace: 'pre-line' }}>
                {t('profilePage.accountVerified')}
                {isOwner ? t('profilePage.updateInClear') : ''}
                {isOwner ? (
                  <Link
                    variant="body2"
                    sx={{ ml: 0.5, fontSize: '12px', textDecoration: 'none', color: themeColors.blue, cursor: 'pointer' }}
                    onClick={() => setOpenUpateInfoPopup(true)}
                  >
                    {t('profilePage.clearApp')}
                  </Link>
                ) : null}
              </P2M>
            </Box>
          ) : null}
          <Box sx={RowStyle}>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputString formik={formik} field="firstName" label={t('profilePage.legalFirstName')} disabled />
            </Box>
            {can.viewFullProfile && (
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString formik={formik} field="middleName" label={t('profilePage.middleName')} disabled />
              </Box>
            )}
          </Box>
          <Box sx={RowStyle}>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputString formik={formik} field="lastName" label={t('profilePage.legalLastName')} disabled />
            </Box>
            {can.viewFieldProfile && (
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputPhone formik={formik} field="phone" label={t('profilePage.phone')} disabled />
              </Box>
            )}
          </Box>

          {can.viewFullProfile && (
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString
                  formik={formik}
                  field="ssn"
                  label={t('profilePage.ssn')}
                  disabled
                  onChange={(e) => {
                    let value = e.target.value || '';
                    value = ssnMask(value);
                    formik.setFieldValue('ssn', value);
                  }}
                />
              </Box>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString formik={formik} field="dateOfBirth" label={t('profilePage.dateOfBirth')} disabled />
              </Box>
            </Box>
          )}
        </Accordion>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Accordion
          expanded={isExpanded.preferredName}
          onChange={() => handleExpanded('preferredName')}
          title={
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <H3>{t('profilePage.preferredName')}</H3>
            </Box>
          }
          actions={
            can.editOwn ? (
              <UpdateButtons
                disabled={!isFieldDirty('preferredName')}
                onCancel={() => resetFields(['preferredName'])}
                onUpdate={() => handleUpdate('preferredName', ['preferredName'])}
              />
            ) : null
          }
          sx={{ width: { xs: '100%', md: '622px' } }}
        >
          <Box sx={RowStyle}>
            <Box sx={{ width: { xs: '100%' }, display: 'flex', flexDirection: 'column' }}>
              <FormikInputString
                formik={formik}
                field="preferredName"
                label={t('profilePage.preferredName')}
                disabled={!can.editOwn}
                onChange={(e) => formik.setFieldValue('preferredName', e.target.value)}
              />
            </Box>
            <Box sx={{ width: { xs: '100%' } }} />
          </Box>
        </Accordion>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Accordion
          expanded={isExpanded.roles}
          onChange={() => handleExpanded('roles')}
          title={
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <H3>{t('profilePage.roles')}</H3>
              {isSectionUncompleted(['roles']) && !isExpanded.roles ? <NotCompleteLabel /> : null}
            </Box>
          }
          actions={
            can.manageRoles ? (
              <UpdateButtons
                disabled={!isFieldDirty('roles')}
                onCancel={() => resetFields(['roles'])}
                onUpdate={() => handleUpdate('roles', ['roles'])}
              />
            ) : null
          }
          sx={{ width: { xs: '100%', md: '622px' } }}
        >
          {isSectionUncompleted(['roles']) ? <NotCompletedInfo /> : null}
          <Box sx={RowStyle}>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputSelectMulti
                formik={formik}
                field="roles"
                label={t('profilePage.s360Roles')}
                options={roles}
                disableClearable
                disabled={!can.manageRoles}
                readOnly={!can.manageRoles}
                isChipDisabled={() => !can.manageRoles}
              />
            </Box>
          </Box>
        </Accordion>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Accordion
          expanded={isExpanded.address}
          onChange={() => handleExpanded('address')}
          title={
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <H3>{t('profilePage.address')}</H3>
              {isSectionUncompleted(addressFields.filter((field) => field !== 'streetAddress2')) && !isExpanded.address ? <NotCompleteLabel /> : null}
            </Box>
          }
          actions={
            can.manageAddress ? (
              <UpdateButtons
                disabled={!isSectionDirty(addressFields)}
                onCancel={() => resetFields(addressFields)}
                onUpdate={() => handleUpdate('address', addressFields)}
                loading={savingProfile}
              />
            ) : null
          }
          sx={{ width: { xs: '100%', md: '622px' } }}
        >
          {isSectionUncompleted(addressFields.filter((field) => field !== 'streetAddress2')) ? <NotCompletedInfo /> : null}
          <Box sx={RowStyle}>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputSelect
                formik={formik}
                field="country"
                label={t('profilePage.country')}
                options={(config.options.COUNTRY || []).map((country: string) => ({ key: country, value: country }))}
                disabled={!can.manageAddress}
              />
            </Box>
            {can.viewFullProfile && (
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString formik={formik} field="streetAddress" label={t('profilePage.streetAddress')} disabled={!can.manageAddress} />
              </Box>
            )}
          </Box>
          <Box sx={RowStyle}>
            {can.viewFullProfile && (
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString formik={formik} field="streetAddress2" label={t('profilePage.streetAddress2')} disabled={!can.manageAddress} />
              </Box>
            )}
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputString formik={formik} field="city" label={t('profilePage.city')} disabled={!can.manageAddress} />
            </Box>
          </Box>
          <Box sx={RowStyle}>
            <Box sx={{ width: { xs: '100%' } }}>
              <form autoComplete="off">
                <FormikInputSelect
                  formik={formik}
                  field="state"
                  label={t('profilePage.state')}
                  disabled={!can.manageAddress || !country}
                  options={states.map((state: string) => ({
                    key: state,
                    value: t(`${country === 'Canada' ? 'provinces' : 'states'}.${state}`),
                  }))}
                />
              </form>
            </Box>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputString formik={formik} field="region" label={t('profilePage.stateRegion')} disabled={!can.manageAddress} />
            </Box>
          </Box>
          {can.viewFullProfile && (
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString
                  formik={formik}
                  field="zipcode"
                  label={t('profilePage.zipcode')}
                  disabled={!can.manageAddress}
                  onChange={(e) => {
                    let value = e.target.value || '';
                    value = zipCodeMask(value);
                    formik.setFieldValue('zipcode', value);
                  }}
                />
              </Box>
              <Box sx={{ width: { xs: '100%' } }} />
            </Box>
          )}
        </Accordion>
      </Grid>
      {(can.editOwn || can.viewFullProfile) && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Accordion
            expanded={isExpanded.id}
            onChange={() => handleExpanded('id')}
            title={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <H3>{t('profilePage.id')}</H3>
                {isSectionUncompleted(idFields) && !isExpanded.id ? <NotCompleteLabel /> : null}
              </Box>
            }
            sx={{ width: { xs: '100%', md: '622px' } }}
          >
            {isSectionUncompleted(idFields) ? <NotCompletedInfo /> : null}
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <Attachment
                  title={t('profilePage.idFront')}
                  typeName="id_front"
                  userId={user.uuid}
                  key={user.attachments?.id_front?.id}
                  selectedAttachment={user.attachments?.id_front}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  disabledActs={{
                    upload: !can.attachFilesToAnyProfile,
                    remove: !can.removeFilesFromAnyProfile,
                  }}
                />
              </Box>
            </Box>
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <Attachment
                  title={t('profilePage.idBack')}
                  typeName="id_back"
                  userId={user.uuid}
                  key={user.attachments?.id_back?.id}
                  selectedAttachment={user.attachments?.id_back}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  disabledActs={{
                    upload: !can.attachFilesToAnyProfile,
                    remove: !can.removeFilesFromAnyProfile,
                  }}
                />
              </Box>
            </Box>
          </Accordion>
        </Grid>
      )}
      {can.viewFieldProfile && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Accordion
            expanded={isExpanded.emergency}
            onChange={() => handleExpanded('emergency')}
            title={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <H3>{t('profilePage.emergencyContact')}</H3>
                {isSectionUncompleted(emergencyContactFields) && !isExpanded.emergency ? <NotCompleteLabel /> : null}
              </Box>
            }
            actions={
              can.manageEmergency ? (
                <UpdateButtons
                  disabled={!isSectionDirty(emergencyContactFields)}
                  onCancel={() => resetFields(emergencyContactFields)}
                  onUpdate={() => handleUpdate('emergencyContact', emergencyContactFields)}
                />
              ) : null
            }
            sx={{ width: { xs: '100%', md: '622px' } }}
          >
            {isSectionUncompleted(emergencyContactFields) ? <NotCompletedInfo /> : null}
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString formik={formik} field="contactFirstName" label={t('profilePage.firstName')} disabled={!can.manageEmergency} />
              </Box>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString formik={formik} field="contactLastName" label={t('profilePage.lastName')} disabled={!can.manageEmergency} />
              </Box>
            </Box>
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputPhone formik={formik} field="contactPhone" label={t('profilePage.phone')} disabled={!can.manageEmergency} />
              </Box>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString
                  formik={formik}
                  field="contactEmail"
                  label={t('profilePage.email')}
                  disabled={!can.manageEmergency}
                  onChange={(e) => {
                    const value = e.target.value || '';
                    formik.setFieldValue('contactEmail', value.toLowerCase());
                  }}
                />
              </Box>
            </Box>
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputSelect
                  formik={formik}
                  field="contactRelationship"
                  label={t('profilePage.relationship')}
                  options={(config.options.emergencyContactRelationships || []).map((rel: ListType) => ({ key: rel.value, value: rel.value }))}
                  disabled={!can.manageEmergency}
                />
              </Box>
              <Box sx={{ width: { xs: '100%' } }} />
            </Box>
          </Accordion>
        </Grid>
      )}
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Accordion
          expanded={isExpanded.ppe}
          onChange={() => handleExpanded('ppe')}
          title={
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <H3>{t('profilePage.ppe')}</H3>
              {isSectionUncompleted(ppeFields) && !isExpanded.ppe ? <NotCompleteLabel /> : null}
            </Box>
          }
          actions={
            can.managePPE ? (
              <UpdateButtons
                disabled={!isSectionDirty(ppeFields)}
                onCancel={() => resetFields(ppeFields)}
                onUpdate={() => handleUpdate('ppe', ppeFields)}
              />
            ) : null
          }
          sx={{ width: { xs: '100%', md: '622px' } }}
        >
          {isSectionUncompleted(ppeFields) ? <NotCompletedInfo /> : null}
          <Box sx={RowStyle}>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputSelect
                formik={formik}
                field="rubberSleeve"
                label={t('profilePage.rubberSleeve')}
                options={enumToTranslatedOptions(RubberSleeveSize, t, 'RubberSleeveSize')}
                disabled={!can.managePPE}
              />
            </Box>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputSelect
                formik={formik}
                field="rubberGlove"
                label={t('profilePage.rubberGlove')}
                options={config.options.rubberGloveSize}
                disabled={!can.managePPE}
              />
            </Box>
          </Box>
          <Box sx={RowStyle}>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputSelect formik={formik} field="workVest" label="Work Vest" options={config.options.workVestSize} disabled={!can.managePPE} />
            </Box>
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputSelect
                formik={formik}
                field="workGlove"
                label={t('profilePage.workGlove')}
                options={config.options.workGloveSize}
                disabled={!can.managePPE}
              />
            </Box>
          </Box>
        </Accordion>
      </Grid>
      {user.profile && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Accordion
            expanded={isExpanded.union}
            onChange={() => handleExpanded('union')}
            title={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <H3>{t('profilePage.unionAndClassification')}</H3>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column-reverse' }}>
                  {user.unionInfoStatus === UnionInfoStatus.VERIFIED ? (
                    <VerifiedLabel />
                  ) : user.unionInfoStatus === UnionInfoStatus.REJECTED ? (
                    <RejectedLabel />
                  ) : (
                    <InReviewLabel text={can.editOwn ? 'In Review' : 'Pending Review'} sx={{ width: '105px' }} />
                  )}
                  {isSectionUncompleted(unionFields[formik.values.employmentType]) && !isExpanded.union ? <NotCompleteLabel /> : null}
                </Box>
              </Box>
            }
            actions={
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '14px' }}>
                {can.editOwn ? (
                  <UpdateButtons
                    disabled={!isSectionDirty(unionFields.submitFormFields)}
                    onCancel={() => resetFields(unionFields.submitFormFields)}
                    onUpdate={() => handleUpdate('unionClassification', unionFields.submitFormFields)}
                  />
                ) : null}
                {/* // TODO: think about adding separate permission */}
                {can.manageRoles && !can.editOwn ? (
                  <VerifyButtons
                    onReject={() => setRejectUnionInfo(true)}
                    onVerify={handleApproveUnionSection}
                    isVerified={user.unionInfoStatus === UnionInfoStatus.VERIFIED}
                    isRejected={user.unionInfoStatus === UnionInfoStatus.REJECTED}
                  />
                ) : null}
              </Box>
            }
            sx={{ width: { xs: '100%', md: '622px' } }}
          >
            {isSectionUncompleted(unionFields[formik.values.employmentType]) ? <NotCompletedInfo /> : null}
            {user.unionRejectedComment ? (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Icon name="rejected" sx={{ mr: 1 }} />
                <P2M>
                  {t('profilePage.comment')}: {user.unionRejectedComment}
                </P2M>
              </Box>
            ) : null}

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <P1R sx={{ mr: { xs: 2, sm: 4 } }}>{t('profilePage.employmentTypeTitle')}</P1R>
              <FormikRadioGroup
                formik={formik}
                field="employmentType"
                options={[
                  { value: EmploymentTypeEnum.UNION, label: t('profilePage.employmentTypeUnion') },
                  { value: EmploymentTypeEnum.NON_UNION, label: t('profilePage.employmentTypeNonUnion') },
                ]}
                onChange={(event) => {
                  formik.setFieldValue('employmentType', Number(event.target.value));
                  formik.setFieldValue('unionClassification', null);
                  formik.setFieldValue('localUnion', null);
                  formik.setFieldValue('unionTicket', '');
                }}
              />
            </Box>
            {formik.values.employmentType === EmploymentTypeEnum.UNION && (
              <>
                <Box sx={RowStyle}>
                  <Box sx={{ width: { xs: '100%' } }}>
                    <FormikInputSelect
                      formik={formik}
                      field="localUnion"
                      label={t('profilePage.localUnion')}
                      disabled={!can.editOwn}
                      options={
                        config.options.UNIONS?.map((classification: string) => ({
                          key: classification,
                          value: t(`unions.${classification}`),
                        })) || []
                      }
                    />
                  </Box>
                  {can.viewFullProfile && can.editOwn && (
                    <Box sx={{ width: { xs: '100%' } }}>
                      <FormikInputString formik={formik} field="unionTicket" label="Union Ticket #" disabled={!can.editOwn} />
                    </Box>
                  )}
                </Box>
                {can.viewFullProfile && (
                  <Box sx={RowStyle}>
                    <Box sx={{ width: { xs: '100%' } }}>
                      <Attachment
                        title={t('profilePage.unionTicket')}
                        typeName="union"
                        userId={user.uuid}
                        key={user.attachments?.union?.id}
                        selectedAttachment={user.attachments?.union}
                        activeField={activeUpload}
                        handleActiveUpload={handleActiveUpload}
                        disabledActs={{
                          upload: !can.attachFilesToAnyProfile,
                          remove: !can.removeFilesFromAnyProfile,
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputSelect
                  formik={formik}
                  field="unionClassification"
                  label={t('profilePage.classification')}
                  disabled={!can.editOwn}
                  options={getUnionClassificationOptions(formik.values.employmentType, formik.values.localUnion, t)}
                />
              </Box>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikCalendar formik={formik} field="driverLicenseExpiration" label={t('profilePage.cdlExpirationDate')} disabled={!can.editOwn} />
              </Box>
            </Box>
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' } }}>
                <InputSelectMulti
                  name="cdlEndorsements"
                  label={t('profilePage.cdlEndorsements')}
                  disabled={!can.editOwn}
                  isChipDisabled={() => !can.editOwn}
                  placeholder="Select"
                  getValue={() => formik.values.cdlEndorsements}
                  options={
                    config.options.CDL_ENDORSEMENTS?.map((endorsement: string) => ({
                      key: endorsement,
                      value: t(`cdlEndorsements.${endorsement}`),
                    })) || []
                  }
                  onChange={(event: SyntheticEvent, value: ListType[]) => {
                    formik.setFieldValue(
                      'cdlEndorsements',
                      value.map((item) => item?.key),
                    );

                    if (value[value.length - 1]?.key === 'N_A') {
                      formik.setFieldValue('cdlEndorsements', ['N_A']);
                      formik.setFieldValue('cdlRestrictions', ['N_A']);
                    } else if (value[value.length - 1]?.key !== 'N_A') {
                      formik.setFieldValue(
                        'cdlEndorsements',
                        value.filter((item) => item?.key !== 'N_A').map((item) => item?.key),
                      );

                      formik.setFieldValue(
                        'cdlRestrictions',
                        value.filter((item) => item?.key !== 'N_A').map((item) => item?.key),
                      );
                    }
                  }}
                  error={formik.touched.cdlEndorsements && Boolean(formik.errors.cdlEndorsements)}
                  helperText={formik.touched.cdlEndorsements && formik.errors.cdlEndorsements}
                  sx={{ '& .MuiInputBase-root': { background: 'white' } }}
                />
              </Box>
              <Box sx={{ width: { xs: '100%' } }}>
                <InputSelectMulti
                  name="cdlRestrictions"
                  label={t('profilePage.cdlRestrictions')}
                  placeholder="Select"
                  disabled={!can.editOwn || formik.values.cdlEndorsements.includes('N_A')}
                  isChipDisabled={() => !can.editOwn || formik.values.cdlEndorsements.includes('N_A')}
                  getValue={() => formik.values.cdlRestrictions}
                  options={
                    config.options.CDL_RESTRICTIONS?.map((restriction: string) => ({
                      key: restriction,
                      value: t(`cdlRestrictions.${restriction}`),
                    })) || []
                  }
                  onChange={(event: SyntheticEvent, value: ListType[]) => {
                    formik.setFieldValue(
                      'cdlRestrictions',
                      value.map((item) => item?.key),
                    );

                    if (value[value.length - 1]?.key === 'N_A') {
                      formik.setFieldValue('cdlRestrictions', ['N_A']);
                    } else if (value[value.length - 1]?.key !== 'N_A') {
                      formik.setFieldValue(
                        'cdlRestrictions',
                        value.filter((item) => item?.key !== 'N_A').map((item) => item?.key),
                      );
                    }
                  }}
                  error={formik.touched.cdlRestrictions && Boolean(formik.errors.cdlRestrictions)}
                  helperText={formik.touched.cdlRestrictions && formik.errors.cdlRestrictions}
                  sx={{ '& .MuiInputBase-root': { background: 'white' } }}
                />
              </Box>
            </Box>
            {can.viewFieldProfile && (
              <>
                <Box>
                  <Attachment
                    title={t('profilePage.driverLicenseFront')}
                    typeName="driver_license_front"
                    userId={user.uuid}
                    key={user.attachments?.driver_license_front?.id}
                    selectedAttachment={user.attachments?.driver_license_front}
                    activeField={activeUpload}
                    handleActiveUpload={handleActiveUpload}
                    disabledActs={{
                      upload: !can.attachFilesToAnyProfile,
                      remove: !can.removeFilesFromAnyProfile,
                    }}
                  />
                </Box>
                <Box>
                  <Attachment
                    title={t('profilePage.driverLicenseBack')}
                    typeName="driver_license_back"
                    userId={user.uuid}
                    key={user.attachments?.driver_license_back?.id}
                    selectedAttachment={user.attachments?.driver_license_back}
                    activeField={activeUpload}
                    handleActiveUpload={handleActiveUpload}
                    disabledActs={{
                      upload: !can.attachFilesToAnyProfile,
                      remove: !can.removeFilesFromAnyProfile,
                    }}
                  />
                </Box>
              </>
            )}
          </Accordion>
        </Grid>
      )}
      {can.viewFieldProfile && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Accordion
            expanded={isExpanded.email}
            onChange={() => handleExpanded('email')}
            title={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <H3>{t('profilePage.emailAndPassword')}</H3>
                {isSectionUncompleted(['email']) && !isExpanded.email ? <NotCompleteLabel /> : null}
              </Box>
            }
            actions={
              can.editOwn ? (
                <UpdateButtons
                  disabled={!isFieldDirty('email')}
                  onCancel={() => resetFields(['email'])}
                  onUpdate={() => handleUpdate('email', ['email'])}
                />
              ) : null
            }
            sx={{ width: { xs: '100%', md: '622px' } }}
          >
            {isSectionUncompleted(['email']) ? <NotCompletedInfo /> : null}
            <Box sx={RowStyle}>
              <Box sx={{ width: { xs: '100%' }, display: 'flex', flexDirection: 'column' }}>
                <FormikInputString
                  formik={formik}
                  field="email"
                  label={t('profilePage.email')}
                  disabled={!can.editOwn}
                  onChange={(e) => {
                    const value = e.target.value || '';
                    formik.setFieldValue('email', value.toLowerCase());
                  }}
                />
                {can.editOwn ? (
                  <Link
                    component="button"
                    onClick={() => setOpenPopup(true)}
                    style={{
                      fontSize: '14px',
                      textDecoration: 'none',
                      color: themeColors.blue,
                      alignSelf: 'end',
                      marginTop: '-14px',
                      marginBottom: '14px',
                    }}
                  >
                    {t('profilePage.changePassword')}
                  </Link>
                ) : null}
              </Box>
              <Box sx={{ width: { xs: '100%' } }} />
            </Box>
          </Accordion>
        </Grid>
      )}
      {can.viewOnboarding ? (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <OnboardingDetails emailConfirmed={user.emailConfirmed} issues={user.issues} clearStatus={user.clearStatus || 0} />
        </Grid>
      ) : null}
      {openSuccessSnackbar && <Snackbar message={t('profilePage.successMessage')} onClose={() => setOpenSuccessSnackbar(false)} />}
      {openPopup && <ChangePasswordPopup onClose={() => setOpenPopup(false)} />}
      {errorUpdatingUserPassword && <CannotChangePassword onClose={() => dispatch(updateUserPassword.reset())} />}
      {successUpdatingUserPassword && <Snackbar message={t('profilePage.newPasswordCreated')} onClose={() => dispatch(updateUserPassword.reset())} />}
      {rejectUnionInfo && <RejectUnionInfoDialog onClose={() => setRejectUnionInfo(false)} onReject={handleRejectUnionInfo} />}
      {openUpateInfoPopup && <UpdateInfoPopup onClose={() => setOpenUpateInfoPopup(false)} />}
      {openAvatarPopup && <UpdateAvatarModal user={user} onClose={() => setOpenAvatarPopup(false)} />}
    </Grid>
  );
}

export default GeneralInfo;
