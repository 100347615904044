import { Grid, styled } from '@mui/material';
import { P1R } from 'src/components';
import { themeColors } from 'src/theme';

export const BaseItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const AssignmentsTypeLabel = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const AssignmentsAmountLabel = styled(P1R)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '5px 15px',
  height: '24px',
  borderRadius: '45px',
  backgroundColor: themeColors.grayMedium,
}));

export const ContactBaseItem = styled(BaseItem)(({ theme }) => ({
  gap: 1.5,
  // border: '1px solid red',
}));
