import { Box } from '@mui/material';
import { H3 } from 'src/components';
import { themeColors } from 'src/theme';

export const InfoBlock = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        mb: 3,
        gap: 3,
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        borderRadius: '4px',
        border: `1px solid ${themeColors.grayMedium}`,
      }}
    >
      <H3 sx={{ textTransform: 'capitalize' }}>{title}</H3>
      {children}
    </Box>
  );
};

export default InfoBlock;
