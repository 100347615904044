import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { themeColors } from 'src/theme';
import { P3, UserProfile } from 'src/components';
import { loadUser, loadUserUuid } from 'src/redux/user/actions';
import { selectUser, selectUserUuid } from 'src/redux/user/selectors';
import { RoleType } from 'src/shared/enums/roleType.enum';

import config from 'src/config';

import { DashboardWrapper } from '../wrappers';

export default function UserProfileContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { uuid = '' } = useParams();
  const user = useSelector(selectUserUuid);
  const currUser = useSelector(selectUser);
  const isOwner = user.uuid === currUser.uuid;

  useEffect(() => {
    dispatch(loadUser.init());
    if (!isOwner) {
      dispatch(loadUserUuid.init({ uuid }));
    }
  }, [isOwner]);

  // SA can modify all, current user cannot modify themselves or the SA,
  const editable = config.can(RoleType.SYSTEM_ADMIN)
    ? true
    : config.can(RoleType.SYSTEM_ADMIN, { permissions: user.allPermissions })
      ? false
      : currUser.uuid !== user.uuid;

  const approvalLabel = (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'left' } }}>
      <Box
        sx={{
          width: '120px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: themeColors.lightYellow,
          borderRadius: '4px',
        }}
      >
        <P3>{t('common.waitingForApproval')}</P3>
      </Box>
    </Box>
  );

  // TODO: We need to have the 'Test' entry in slot 0 because there is a bug in the
  //       breadcrumb logic. Once that is fixed, we can remove the 'Test' field.
  return (
    <DashboardWrapper
      breadcrumbs={[['Breadcrumb Bug Workaround'], ['MU', '/my/managed_users'], [`${user.firstName ?? 'New'} ${user.lastName ?? 'User'}'s Profile`]]}
      navigationWidget={user?.step && user.step < 3 ? approvalLabel : null}
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // height: '100vh',
          overflow: 'auto',
        }}
      >
        <UserProfile currUser={currUser} user={user} editable={editable} />
      </Box>
    </DashboardWrapper>
  );
}
