import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Link } from '@mui/material';
import { FormikProps } from 'formik';

import { UserType } from 'src/types';
import { UserFileType } from 'src/types/user';
import { themeColors } from 'src/theme';
import { H1, H3, P3 } from 'src/components';
import { saveProfile } from 'src/redux/user/actions';
import { selectSuccessUploadingUserFile } from 'src/redux/user/selectors';

import { useTranslation } from 'react-i18next';
import NoAttachmentsImg from '../../../assets/NoAttachments.svg';
import pdfLogo from '../../../assets/pdfLogo.svg';

import { FormValues } from '..';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  currUser: UserType;
  user: UserType;
  // eslint-disable-next-line react/no-unused-prop-types
  formik: FormikProps<FormValues>;
  // eslint-disable-next-line react/no-unused-prop-types
  editable?: boolean;
};

function AttachedFiles({ user }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const successUploadingUserFile = useSelector(selectSuccessUploadingUserFile);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { profile_picture: profilePicture, ...userAttachments } = user.attachments || {};

  useEffect(() => {
    if (successUploadingUserFile) {
      dispatch(saveProfile.reset());
    }
  }, [successUploadingUserFile]);

  return (
    <Grid container spacing={2} sx={{ padding: { xs: '0 24px', md: 0 }, mt: 4 }}>
      {Object.keys(userAttachments || '').length !== 0 ? (
        <>
          <Grid item xs={12}>
            {(user.attachments?.id_front || user.attachments?.id_back) && (
              <Upload
                title={t('profilePage.id')}
                files={[user.attachments.id_front, user.attachments.id_back].filter((file) => file !== undefined)}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {user.attachments?.union && <Upload title={t('profilePage.union')} files={[user.attachments.union]} />}
          </Grid>
          <Grid item xs={12}>
            {(user.attachments?.driver_license_front || user.attachments?.driver_license_back) && (
              <Upload
                title={t('profilePage.driverLicense')}
                files={[user.attachments.driver_license_front, user.attachments.driver_license_back].filter((file) => file !== undefined)}
              />
            )}
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            mt: 14,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ ml: 1 }}>
            <img src={NoAttachmentsImg} alt="Empty list" />
          </Box>
          <H1 sx={{ mb: 3 }}>{t('profilePage.noAttachments')}</H1>
        </Box>
      )}
    </Grid>
  );
}

function Upload({ title, files }: { title: string; files: (UserFileType | undefined)[] }) {
  return (
    <Box
      sx={{
        padding: '25px',
        width: { xs: '100%', md: '574px' },
        backgroundColor: themeColors.grayLight,
        borderRadius: '4px',
      }}
    >
      <H3>{title}</H3>
      <Box sx={{ gap: 2, marginTop: 2, display: 'flex' }}>
        {files?.map((file) => (
          <Link key={file?.id} href={file?.location} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {file?.mimetype === 'application/pdf' ? (
                <img
                  src={pdfLogo}
                  alt="pdf"
                  style={{
                    width: '117px',
                    height: '105px',
                    borderRadius: '4px',
                  }}
                />
              ) : (
                <img
                  src={file?.location}
                  alt={file?.filename}
                  style={{
                    width: '117px',
                    height: '105px',
                    borderRadius: '4px',
                  }}
                />
              )}
              <P3>{file?.filename && file?.filename.length > 15 ? `...${file?.filename.slice(-15)}` : file?.filename}</P3>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default AttachedFiles;
