import { takeLatest } from 'redux-saga/effects';
import getIdIncidentSaga from './getIdIncidentSaga';
import loadIncidentSaga from './loadIncidentSaga';
import saveIncidentSaga from './saveIncidentSaga';
import deleteIncidentSaga from './deleteIncidentSaga';
import setInviteIncidentSaga from './setInviteIncidentSaga';
import { TYPES } from '../constants';
import incidentDeclineLinemenSaga from './incidentDeclineLinemenSaga';
import updateIncidentStatusSaga from './updateIncidentStatusSaga';

export default function* incidentSagas(): Generator {
  yield takeLatest(TYPES.LOAD_INCIDENT.INIT, loadIncidentSaga);
  yield takeLatest(TYPES.GET_ID_INCIDENT.INIT, getIdIncidentSaga);
  yield takeLatest(TYPES.SAVE_INCIDENT.INIT, saveIncidentSaga);
  yield takeLatest(TYPES.DELETE_INCIDENT.INIT, deleteIncidentSaga);
  yield takeLatest(TYPES.SET_INVITE_INCIDENT.INIT, setInviteIncidentSaga);
  yield takeLatest(TYPES.INCIDENT_DECLINE_LINEMEN.INIT, incidentDeclineLinemenSaga);
  yield takeLatest(TYPES.UPDATE_INCIDENT_STATUS.INIT, updateIncidentStatusSaga);
}
