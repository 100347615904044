import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Container, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Button, H1, H3, Icon, P1R, P2, P2M, P2R } from 'src/components';
import { themeColors } from 'src/theme';
import { IncidentType, PermissionEnum } from 'src/types';
import { RosterStatus } from 'src/types/roster';
import { formatIncidentId, formatTimestamp, setParams } from 'src/utils';
import { setInviteIncident } from 'src/redux/incident/actions';
import { selectUser } from 'src/redux/user/selectors';
import { loadFilteredIncidents } from 'src/redux/incidents/actions';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'src/navigation';

type Props = {
  incident: IncidentType & { participationStatus?: number };
  name?: string;
  filteredTypeIncidents: string;
  onClose: () => void;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  isFullscreen: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, isFullscreen, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: isFullscreen ? themeColors.grayLight : 'unset',
      }}
      {...other}
    >
      {children}
      {!isFullscreen ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: '4px',
            top: '4px',
          }}
        >
          <Icon name="arrowBack" color="grayDark" />
        </IconButton>
      )}
    </DialogTitle>
  );
}

const jobParticipationStatus: Record<string, string> = {
  0: '',
  1: 'ACCEPTED',
  '-1': 'REJECTED',
};

function JobDetailsDialog({ onClose, incident, name, filteredTypeIncidents }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inviteStatus, setInviteStatus] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const {
    id,
    name: incidentName,
    ibewPay,
    benefitsPay,
    travelReimbursement,
    rosterDetails,
    yard,
    expectedStartDate,
    time,
    workLocation,
    notes,
  } = incident;
  const participationStatus = incident?.participations?.participationStatus ?? '';
  // const requiredReceipts = Object.keys(receipts)
  //   .filter((key) => receipts[key as keyof typeof receipts])
  //   .join(', ');
  const rosterStatus = rosterDetails?.status || 0;
  const isDeclined = incident?.participations?.declined;

  const acceptInvite = () => {
    dispatch(setInviteIncident.init({ id, status: 'ACCEPTED', yardId: yard.id, rosterId: incident?.rosterDetails?.rosterId }));
    setInviteStatus('ACCEPTED');
    dispatch(
      loadFilteredIncidents.init({
        uuid: user.uuid,
        filter: filteredTypeIncidents,
      }),
    );
  };

  const rejectInvite = () => {
    dispatch(setInviteIncident.init({ id, status: 'REJECTED', yardId: yard.id, rosterId: incident?.rosterDetails?.rosterId }));
    setInviteStatus('REJECTED');
    dispatch(
      loadFilteredIncidents.init({
        uuid: user.uuid,
        filter: filteredTypeIncidents,
      }),
    );
  };

  useEffect(() => {
    setInviteStatus(jobParticipationStatus[participationStatus.toString()]);
  }, [participationStatus]);

  return (
    <Box>
      <BootstrapDialog open onClose={onClose} fullScreen={fullScreen} aria-labelledby="customized-dialog-title">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} isFullscreen={fullScreen}>
          {fullScreen && (
            <H3 sx={{ pl: 6 }}>
              {incidentName} / {id}
            </H3>
          )}
        </BootstrapDialogTitle>
        <DialogContent>
          <Container maxWidth="xs">
            {!fullScreen && (
              <Box
                sx={{
                  mb: 5,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <H1 align="center" sx={{ mt: 5 }}>
                  {incidentName} / {formatIncidentId(id)}
                </H1>
                <P1R>{name}</P1R>
              </Box>
            )}

            <Grid
              container
              rowSpacing={1}
              sx={{
                borderRadius: '0 20px 0 0 ',
                border: `1px solid ${themeColors.grayMedium}`,
                mb: 5,
                mt: 3,
                pt: 2,
              }}
            >
              <Grid item xs={12} sx={{ mb: 1 }}>
                <H3 sx={{ px: 2 }}>{t('jobDetailsDialog.eventDetails')}</H3>
              </Grid>
              <Grid item xs={6}>
                <P2 sx={{ fontWeight: 600, px: 2 }}>{t('jobDetailsDialog.ibewPackage')}</P2>
              </Grid>
              <Grid item xs={6}>
                <P1R>{benefitsPay}</P1R>
              </Grid>
              <Grid item xs={6}>
                <P2 sx={{ fontWeight: 600, px: 2 }}>{t('jobDetailsDialog.ibewRate')}</P2>
              </Grid>
              <Grid item xs={6}>
                <P1R>{ibewPay}</P1R>
              </Grid>
              <Grid item xs={6}>
                <P2 sx={{ fontWeight: 600, px: 2 }}>{t('jobDetailsDialog.reportTimeAndDate')}</P2>
              </Grid>
              <Grid item xs={6}>
                <P1R>
                  {time} {formatTimestamp(expectedStartDate, true)}
                </P1R>
              </Grid>
              <Grid item xs={6}>
                <P2 sx={{ fontWeight: 600, px: 2 }}>{t('jobDetailsDialog.reportToYard')}</P2>
              </Grid>
              <Grid item xs={6}>
                <P1R>
                  {
                    // @ts-ignore
                    // eslint-disable-next-line
                    config.options.yards?.[yard?.id - 1]?.value
                  }
                </P1R>
              </Grid>
              <Grid item xs={6}>
                <P2 sx={{ fontWeight: 600, px: 2 }}>{t('jobDetailsDialog.travelReimbursement')}</P2>
              </Grid>
              <Grid item xs={6}>
                <P1R sx={{ textTransform: 'capitalize' }}>{travelReimbursement}</P1R>
              </Grid>
              <Grid item xs={6}>
                <P2 sx={{ fontWeight: 600, px: 2 }}>{t('jobDetailsDialog.generalWorkLocation')}</P2>
              </Grid>
              <Grid item xs={6}>
                <P1R sx={{ textTransform: 'capitalize' }}>{workLocation}</P1R>
              </Grid>

              {notes && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: '10px',
                    px: '16px',
                    pb: 2,
                    gap: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    borderTop: `1px solid ${themeColors.grayMedium}`,
                    bgcolor: themeColors.grayLight,
                  }}
                >
                  <P2 sx={{ fontWeight: 600 }}>{t('eventsPage.notes')}</P2>
                  <P1R sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{notes}</P1R>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  mt: '26px',
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  borderTop: `1px solid ${themeColors.grayMedium}`,
                }}
              >
                <H3 sx={{ pt: '16px' }}>{t('eventsPage.notes')}</H3>
                <P1R sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{notes}</P1R>
              </Grid>
            </Grid>

            {rosterDetails && rosterDetails.confirmedBy && !isDeclined && (
              <Grid
                container
                rowSpacing={1}
                sx={{
                  borderRadius: '0 20px 0 0 ',
                  border: `1px solid ${themeColors.grayMedium}`,
                  mb: 5,
                  pt: 2,
                }}
              >
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <H3 sx={{ px: 2 }}>{t('jobDetailsDialog.rosterDetails')}</H3>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <P2 sx={{ fontWeight: 600, pl: 2, pr: 1 }}>{t('jobDetailsDialog.confiremedBy')}</P2>
                  <Icon name="accepted" />
                </Grid>
                <Grid item xs={6}>
                  <P1R>{rosterDetails?.confirmedBy || '-'}</P1R>
                </Grid>
                <Grid item xs={6}>
                  <P2 sx={{ fontWeight: 600, px: 2 }}>{t('jobDetailsDialog.reportToYard')}</P2>
                </Grid>
                <Grid item xs={6}>
                  <P1R>
                    {rosterStatus === RosterStatus.DRAFT
                      ? '-'
                      : // @ts-ignore
                        config.options.yards[rosterDetails.yard - 1]?.value || '-'}{' '}
                  </P1R>
                </Grid>
                <Grid item xs={6}>
                  <P2 sx={{ fontWeight: 600, px: 2 }}>{t('jobDetailsDialog.time')}</P2>
                </Grid>
                <Grid item xs={6}>
                  <P1R>{rosterStatus === RosterStatus.DRAFT ? '-' : rosterDetails?.startTime || '-'}</P1R>
                </Grid>

                {rosterDetails?.notes && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mt: '10px',
                      px: '16px',
                      pb: 2,
                      gap: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      borderTop: `1px solid ${themeColors.grayMedium}`,
                      bgcolor: themeColors.grayLight,
                    }}
                  >
                    <P2 sx={{ fontWeight: 600 }}>{t('jobDetailsDialog.notes')}</P2>
                    <P1R>{rosterDetails?.notes}</P1R>
                  </Grid>
                )}
              </Grid>
            )}

            <Grid container rowSpacing={2} sx={{ mb: 2 }}>
              {rosterDetails && rosterDetails.confirmedBy && !isDeclined && (
                <Grid item xs={12}>
                  <Button
                    onClick={() =>
                      navigate(
                        `${setParams(PATHS.ROSTER_PAGE_OVERVIEW, {
                          id: `${incident.rosterDetails?.rosterId}`,
                        })}`,
                        { state: { version: incident.rosterDetails?.version } },
                      )
                    }
                    disabled={rosterStatus === RosterStatus.DRAFT || rosterStatus === RosterStatus.PENDING_APPROVAL}
                    sx={{
                      width: '100%',
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('jobDetailsDialog.goToRoster')}
                  </Button>
                </Grid>
              )}
              {inviteStatus && (
                <Grid item xs={12} sx={{ mb: 4 }}>
                  {inviteStatus === 'ACCEPTED' ? (
                    <Box
                      sx={{
                        color: themeColors.green,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon name="accepted" />
                      <P2M>{t('jobDetailsDialog.acceptOffer')}</P2M>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        color: themeColors.red,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon name="rejected" />
                      <P2M>{t('jobDetailsDialog.rejectOffer')}</P2M>
                    </Box>
                  )}
                </Grid>
              )}

              {!inviteStatus && config.can(PermissionEnum.ACCEPT_OR_REJECT_EVENT_INVITATION) && (
                <>
                  <Grid item xs={12}>
                    <Button
                      onClick={acceptInvite}
                      sx={{
                        width: '100%',
                        backgroundColor: themeColors.green,
                        ':hover': { backgroundColor: themeColors.green },
                        textTransform: 'capitalize',
                      }}
                    >
                      {t('jobDetailsDialog.yes')}
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      primary={false}
                      color="error"
                      onClick={rejectInvite}
                      sx={{
                        width: '100%',
                        textTransform: 'capitalize',
                        borderColor: themeColors.red,
                        ':hover': { backgroundColor: themeColors.red },
                      }}
                    >
                      {t('jobDetailsDialog.no')}
                    </Button>
                  </Grid>
                  <P2R sx={{ mt: 2, textAlign: 'center', lineHeight: '130%', textTransform: 'capitalize' }}>{t('jobDetailsDialog.agreement')}</P2R>
                </>
              )}
            </Grid>
          </Container>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}

export default JobDetailsDialog;
