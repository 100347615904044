import { combineReducers } from 'redux';
import clearme from './clearme';
import clearModalOpen from './clearModal';

const clearmeData = combineReducers({
  clearme,
  clearModalOpen,
});

export * from './clearme';
export * from './clearModal';

export default clearmeData;
