import API from 'src/api';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { UserType } from 'src/types';
import { SagaAction } from '../../types';
import { deleteUserFile } from '../actions';
import { setUser } from '../reducers';
import { selectUser } from '../selectors';

function* deleteUserUploadSaga({ payload }: SagaAction<{ id: number }>): SagaIterator {
  try {
    yield put(deleteUserFile.pending());
    yield call(API.deleteUserUpload, payload);

    // Remove attachment from user
    const user: UserType = yield select(selectUser);
    const { attachments } = user;
    if (attachments) {
      yield put(
        setUser({
          ...user,
          attachments: Object.fromEntries(Object.entries(attachments).filter(([, value]) => value.id !== payload.id)),
        }),
      );
    }

    yield put(deleteUserFile.success());
  } catch (error) {
    yield put(deleteUserFile.failure(error));
  }
}

export default deleteUserUploadSaga;
