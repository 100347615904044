import { Box, BoxProps, Grid, GridProps, styled } from '@mui/material';

export const BaseItem = styled(Grid)(({ theme }) => ({
  padding: '0 8px 0 8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

// Show item only on desktop
export const DesktopItem = styled(BaseItem)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}));

// Show item only on mobile
export const MobileItem = styled(BaseItem)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

interface HiddenOnMobileConditionalItemProps extends GridProps {
  showOnMobileAndDesktop?: boolean;
}

// This Grid item is always seen on desktop but not shown on mobile, with prop set to true you can show it on both
export const HiddenOnMobileConditionalItem = styled(BaseItem, {
  shouldForwardProp: (prop) => prop !== 'showOnBoth',
})<HiddenOnMobileConditionalItemProps>(({ theme, showOnMobileAndDesktop }) => ({
  display: 'none', // Default to hidden on mobile
  [theme.breakpoints.up('lg')]: {
    display: 'flex', // Always show on desktop
  },
  ...(showOnMobileAndDesktop && {
    display: 'flex', // Show on both mobile and desktop if showOnMobileAndDesktop is true
  }),
}));

export const BaseContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '64px',
  },
}));

// Special container for the top section, with padding to accommodate an icon
export const MobileContainer = styled(BaseContainer)(({ theme }) => ({
  paddingLeft: '64px',
}));

interface MobileBoxProps extends BoxProps {
  showOnMobile?: boolean;
}

// Box to show small UI elements only on mobile view
export const MobileBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showOnMobile',
})<MobileBoxProps>(({ theme, showOnMobile }) => ({
  display: showOnMobile ? 'block' : 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));
