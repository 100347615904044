import { takeLatest } from 'redux-saga/effects';
import { TYPES } from '../constants';
import loadClearmeSaga from './loadClearmeSaga';
import clearmeVerifySaga from './clearmeVerifySaga';
import setClearModalSaga from './setClearModalSaga';

export default function* clearmeSagas(): Generator {
  yield takeLatest(TYPES.LOAD_CLEARME.INIT, loadClearmeSaga);
  yield takeLatest(TYPES.CLEARME_VERIFY.INIT, clearmeVerifySaga);
  yield takeLatest(TYPES.SET_CLEAR_MODAL.INIT, setClearModalSaga);
}
