import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs } from '@mui/material';

import { themeColors } from 'src/theme';
import { PermissionEnum } from 'src/types';
import { Button, Loader, Notification, P2, P3, Toolbar } from 'src/components';
import { loadUser } from 'src/redux/user/actions';
import { selectIsUserLoading, selectUser } from 'src/redux/user/selectors';
import config from 'src/config';
import { loadRoles } from 'src/redux/roles/actions';
import { selectCreateRolesSuccess, selectPermissions, selectRoles } from 'src/redux/roles/selectors';

import { DashboardWrapper } from '../wrappers';
import RolesTab from './RbacMgmtTabs/RolesTab';
import PermissionsTab from './RbacMgmtTabs/PermissionsTab';
import RoleDialog from './RoleDialog';

export default function RbacMgmtContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState<number>(0);
  const [showDialog, setShowDialog] = useState(false);
  const [roleCreated, setRoleCreated] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const permissions = useSelector(selectPermissions);
  const isUserLoading = useSelector(selectIsUserLoading);
  const createRolesSuccess = useSelector(selectCreateRolesSuccess);
  const editable = config.can(PermissionEnum.EDIT_ROLE);
  const canAddRole = config.can(PermissionEnum.ADD_ROLE);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    dispatch(loadUser.init());
  }, []);

  useEffect(() => {
    if (createRolesSuccess) {
      setRoleCreated(true);
      dispatch(loadRoles.init());
    }
  }, [createRolesSuccess]);

  const approvalLabel = (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'left' } }}>
      <Box
        sx={{
          width: '120px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: themeColors.lightYellow,
          borderRadius: '4px',
        }}
      >
        <P3>{t('common.waitingForApproval')}</P3>
      </Box>
    </Box>
  );

  const createButton = (
    <Button
      disabled={editMode}
      onClick={() => setShowDialog(true)}
      sx={{
        ml: 1,
        padding: '0 16px',
        textTransform: 'none',
        whiteSpace: 'nowrap',
      }}
    >
      Create Role
    </Button>
  );

  const navigationWidget = (
    <>
      {(tabsValue === 0 && roles.length) || (tabsValue === 1 && permissions.length) ? (
        <Toolbar>{tabsValue === 0 && canAddRole && createButton}</Toolbar>
      ) : null}

      {!((tabsValue === 0 && roles.length) || (tabsValue === 1 && permissions.length)) && tabsValue === 0 && canAddRole && createButton}
    </>
  );

  return (
    <DashboardWrapper
      breadcrumbs={[['RBAC Management']]}
      navigationLabel={user?.step && user.step < 3 ? approvalLabel : null}
      navigationWidget={navigationWidget}
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        {isUserLoading ? (
          <Loader />
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '32px', mt: 5 }}>
              <Tabs value={tabsValue} onChange={handleTabsChange} variant="scrollable" scrollButtons="auto">
                <Tab
                  label={<Box sx={{ position: 'relative' }}> {t('rbacPage.roles')} </Box>}
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    color: themeColors.grayDark,
                    '&.Mui-selected': { color: 'black' },
                  }}
                />
                <Tab
                  label={<Box sx={{ position: 'relative' }}> {t('rbacPage.permissions')} </Box>}
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    color: themeColors.grayDark,
                    '&.Mui-selected': { color: 'black' },
                  }}
                />

                {showDialog && <RoleDialog onClose={() => setShowDialog(false)} />}
              </Tabs>
            </Box>

            {tabsValue === 0 && <RolesTab editable={editable} setEditMode={(mode: boolean) => setEditMode(mode)} />}
            {tabsValue === 1 && <PermissionsTab editable={editable} />}

            <Notification open={roleCreated} onClose={() => setRoleCreated(false)}>
              <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>Role created</P2>
            </Notification>
          </>
        )}
      </Box>
    </DashboardWrapper>
  );
}
