import { ReactNode, useRef } from 'react';
import { GridFilterAltIcon } from '@mui/x-data-grid';
import { Badge, Box, Checkbox, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import useBoolean from 'src/hooks/useBoolean';
import { TableFilter, FilterValue } from '../hooks';

export interface FilterSelectProps {
  title: string;
  options: FilterValue[];
  renderItem?: (item: FilterValue) => ReactNode;
  filters: TableFilter;
  onChange: (key: string, value: FilterValue[]) => void;
  name: string;
}

export const FilterSelect = ({ title, options, renderItem, filters, onChange: onChangeFilter, name }: FilterSelectProps) => {
  const anchorElement = useRef<Element>();

  const { value: open, toggle: toggleOpen } = useBoolean(false);

  const onChange = (option: FilterValue) => () => {
    const checked = filters[name]?.find((filter) => filter.value === option.value);
    if (!checked) {
      onChangeFilter(name, filters[name] ? [...filters[name], option] : [option]);
    } else {
      onChangeFilter(
        name,
        filters[name].filter((filter) => filter.value !== option.value),
      );
    }
  };
  return (
    <Box display="flex" alignItems="center" ref={anchorElement}>
      <Typography className="MuiDataGrid-columnHeaderTitle">{title}</Typography>
      <IconButton onClick={toggleOpen}>
        {filters[name]?.length ? (
          <Badge
            color="error"
            variant="dot"
            overlap="circular"
            sx={{
              position: 'absolute',
              top: '30%',
              right: '30%',
            }}
          />
        ) : null}

        <GridFilterAltIcon color="primary" fontSize="small" />
      </IconButton>
      <Menu anchorEl={anchorElement.current} open={open} onClose={toggleOpen} sx={{ '& .MuiPaper-root': { minWidth: '200px' } }}>
        {options.map((item) => (
          <MenuItem key={item.value} onClick={onChange(item)}>
            <Checkbox checked={!!filters[name]?.find((filter) => filter.value === item.value)} />
            {renderItem ? renderItem(item) : item.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
