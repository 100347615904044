import { createReducer } from '../../utils/toolkit';

const initialState = {
  isOpen: false,
};

const {
  reducer,
  actions: { set: setClearmeModal, merge: mergeClearmeModal, reset: resetClearmeModal },
} = createReducer('clearModalOpen', initialState);

export default reducer;
export { setClearmeModal, mergeClearmeModal, resetClearmeModal };
