import { FormikProps } from 'formik';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { P1R, FormikInputString, FormikInputSelect } from 'src/components';
import config from 'src/config';
import { useTranslation } from 'react-i18next';
import { FormValues } from '..';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
};

function Compensation({ formik, hidden = false }: Props) {
  const { t } = useTranslation();
  const handleChange = (value: 'fuel' | 'travel' | 'lodging' | 'meals') => {
    const updatedReceipts = formik.values.receipts;
    updatedReceipts[value] = !updatedReceipts[value];
    formik.setFieldValue('receipts', updatedReceipts);
  };

  return (
    <Grid columnSpacing={2} container sx={{ mb: 2, display: hidden ? 'none' : 'flex' }}>
      <Grid xs={12} md={6} item>
        <FormikInputSelect
          formik={formik}
          field="ibewPay"
          label={t('rosterOverviewHeader.ibewRate')}
          options={config.options.UNIONS.map((union: string) => ({ key: union, value: t(`unions.${union}`) })) || []}
          disabled={Boolean(formik.values.status)}
          disableClearable
        />
      </Grid>

      <Grid xs={12} md={6} item>
        <FormikInputSelect
          formik={formik}
          field="benefitsPay"
          label={t('rosterOverviewHeader.ibewPackage')}
          options={config.options.UNIONS.map((union: string) => ({ key: union, value: t(`unions.${union}`) })) || []}
          disabled={Boolean(formik.values.status)}
          disableClearable
        />
      </Grid>

      <Grid xs={12} md={6} item>
        <FormikInputString
          formik={formik}
          field="travelReimbursement"
          label={t('rosterOverviewHeader.travelReimbursement')}
          disabled={Boolean(formik.values.status)}
        />
      </Grid>

      <Grid xs={12} md={6} item>
        <FormikInputSelect
          formik={formik}
          field="lodgingProvided"
          label={t('rosterOverviewHeader.lodgingProvided')}
          options={[
            { key: 'true', value: 'Yes' },
            { key: 'false', value: 'No' },
          ]}
          disabled={Boolean(formik.values.status)}
          disableClearable
        />
      </Grid>

      <Grid xs={12} md={6} item>
        <FormikInputString formik={formik} field="meals" label={t('rosterOverviewHeader.meals')} disabled={Boolean(formik.values.status)} />
      </Grid>

      <Grid xs={12} item>
        <P1R>{t('eventsPage.receiptsRequired')}:</P1R>

        <Grid container>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox disabled={Boolean(formik.values.status)} checked={formik.values.receipts.fuel} onChange={() => handleChange('fuel')} />
              }
              label={<P1R>{t('eventsPage.fuel')}</P1R>}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox disabled={Boolean(formik.values.status)} checked={formik.values.receipts.travel} onChange={() => handleChange('travel')} />
              }
              label={<P1R>{t('eventsPage.travel')}</P1R>}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={Boolean(formik.values.status)}
                  checked={formik.values.receipts.lodging}
                  onChange={() => handleChange('lodging')}
                />
              }
              label={<P1R>{t('eventsPage.lodging')}</P1R>}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox disabled={Boolean(formik.values.status)} checked={formik.values.receipts.meals} onChange={() => handleChange('meals')} />
              }
              label={<P1R>{t('eventsPage.meals')}</P1R>}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Compensation;
