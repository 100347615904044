import { useEffect, useState } from 'react';

interface TableFiltersProps<T> {
  items: T[];
  statePersistKey?: string;
}

export type FilterValue = {
  title: string;
  value: string | number;
};

export interface TableFilter {
  [key: string]: FilterValue[];
}

const filterItems = <T extends Record<string, any>>(filters: TableFilter, items: T[]): T[] => {
  return Object.entries(filters).reduce((filteredItems, [key, filter]) => {
    return filteredItems.filter((item) => {
      if (Array.isArray(filter) && filter.length) {
        if (key === 'roles') {
          return item[key].find((role: any) => filter.find((value) => value.value === role.slug || value.value === role));
        }

        return filter.find((value) => value.value === item[key]);
      }

      return true;
    });
  }, items);
};

const checkIsFilters = (filters: TableFilter): boolean => {
  return !!Object.values(filters).find((el) => el.length);
};

export const useTableFilters = <T extends Record<string, any>>({ items, statePersistKey = '' }: TableFiltersProps<T>) => {
  const [filters, setFilters] = useState<TableFilter>({});
  const newItems = filterItems<T>(filters, items);

  const onChangeFilters = (key: string, value: FilterValue[]) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resetFilters = () => {
    setFilters({});
  };

  useEffect(() => {
    if (statePersistKey) {
      const storedState = localStorage.getItem(statePersistKey);
      if (storedState) {
        setFilters((prev) => ({
          ...prev,
          ...JSON.parse(storedState),
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (filters && statePersistKey) {
      localStorage.setItem(statePersistKey, JSON.stringify(filters));
    }
  }, [filters]);

  return {
    data: newItems,
    onChangeFilters,
    filters,
    isFilters: checkIsFilters(filters),
    resetFilters,
  };
};
