import { useEffect, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Tab, Tabs } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { themeColors } from 'src/theme';
import { Button, Icon, P1R, P3 } from 'src/components';
import { normalizerTimestamp, validation, storage } from 'src/utils';
import { UserType } from 'src/types';
import { PermissionEnum } from 'src/shared/enums/permission.enum';
import { loadUser, loadUserUuid, saveProfile, updateUserRoles } from 'src/redux/user/actions';
import {
  selectSaveProfileError,
  selectSaveProfileSuccess,
  selectUpdatingUserRolesSuccess,
  selectVerifingUnionInfoSuccess,
} from 'src/redux/user/selectors';
import config from 'src/config';

import { ClearStatus } from 'src/shared/enums/clearStatus.enum';
import { selectClearModalOpen } from 'src/redux/clearme/selectors';
import { setClearModal } from 'src/redux/clearme/actions';
import { EmploymentTypeEnum } from 'src/shared/enums/employmentType.enum';
import { PATHS } from 'src/navigation';
import AttachedFiles from './ProfileTabs/AttachedFiles';
import GeneralInfo from './ProfileTabs/GeneralInfo';
import UnsavedChangesPopup from './UnsavedChangesPopup';

export type FormValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  prefferedName: string;
  phone: string;
  street: string;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  region: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  contactEmail: string;
  contactRelationship: string;
  email: string;
  roles: string[];
  utilityContractorId: number | null;
  ssn: string;
  preferredName: string;
  rubberGlove: string;
  rubberSleeve: string;
  workGlove: string;
  workVest: string;
  localUnion: string;
  unionTicket: string;
  employmentType: EmploymentTypeEnum;
  unionClassification: string | number;
  driverLicenseState: string;
  driverLicense: string;
  driverLicenseClass: string;
  driverLicenseExpiration?: number | null;
  cdlEndorsements: (string | undefined)[];
  cdlRestrictions: (string | undefined)[];
  dateOfBirth: string;
};

// TODO: editable controls if SA/BM can edit roles, email, and phone fields
//       this should change to control two levels of editability
type UserProfileProps = {
  currUser: UserType;
  user: UserType;
  editable?: boolean;
};

const UserProfile: React.FC<UserProfileProps> = ({ currUser, user, editable, ...props }): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const saveProfileError = useSelector(selectSaveProfileError);
  const saveProfileSuccess = useSelector(selectSaveProfileSuccess);
  const verifyUserInfoSuccess = useSelector(selectVerifingUnionInfoSuccess);
  const updateUserRolesSuccess = useSelector(selectUpdatingUserRolesSuccess);
  const [tabsValue, setTabsValue] = useState<number>(0);
  const [sectionToSubmit, setSectionToSubmit] = useState<string>('');
  const [fieldsToSubmit, setFieldsToSubmit] = useState<string[]>([]);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => formik.dirty && currentLocation.pathname !== nextLocation.pathname);
  const isOwner = user.uuid === currUser.uuid;
  const canEditOwner = isOwner && config.can(PermissionEnum.EDIT_OWN_PROFILE);
  const can = {
    viewFullProfile: isOwner || config.can(PermissionEnum.VIEW_FULL_USER_PROFILE),
    viewFieldProfile: isOwner || config.can(PermissionEnum.VIEW_FIELD_USER_PROFILE) || config.can(PermissionEnum.VIEW_FULL_USER_PROFILE),
    attachFilesToAnyProfile: isOwner || config.can(PermissionEnum.UPLOAD_ANY_ATTACHMENT),
    removeFilesFromAnyProfile: isOwner || config.can(PermissionEnum.REMOVE_ANY_ATTACHMENT),
    editOwn: canEditOwner,
    manageRoles: config.can(PermissionEnum.EDIT_ROLE),
    deleteRoles: config.can(PermissionEnum.DELETE_ROLE),
    viewHiddenRoles: config.can(PermissionEnum.VIEW_HIDDEN_ROLES),
    manageAddress: config.can(PermissionEnum.EDIT_ADDRESS_DETAILS) || canEditOwner,
    manageEmergency: config.can(PermissionEnum.EDIT_EMERGENCY_DETAILS) || canEditOwner,
    managePPE: config.can(PermissionEnum.EDIT_PPE_DETAILS) || canEditOwner,
    viewOnboarding: config.can(PermissionEnum.VIEW_ONBOARDING_DETAILS),
  };
  const isClearModalOpen = useSelector(selectClearModalOpen);
  const displayClearWarning = !isClearModalOpen && (user.clearStatus === ClearStatus.INCOMPLETE || user.clearStatus === ClearStatus.NOT_STARTED);

  useEffect(() => {
    setShowNewUserModal(!(storage('NEW_USER_MODAL_SHOWN') as string[])?.includes(user.uuid));
  }, []);

  const handleCloseNewUserModal = () => {
    storage('NEW_USER_MODAL_SHOWN', user.uuid);
    setShowNewUserModal(false);
  };

  const initialValues: FormValues = {
    firstName: user.firstName || '',
    middleName: user.middleName || '',
    dateOfBirth: user.dateOfBirth || '',
    lastName: user.lastName || '',
    prefferedName: user.prefferedName || '',
    phone: user.phone || '',
    street: user.streetAddress || '',
    streetAddress: user.streetAddress || '',
    streetAddress2: user.streetAddress2 || '',
    city: user.city || '',
    state: user.state || '',
    zipcode: user.zipcode || '',
    country: user.country || '',
    region: user.region || '',
    contactFirstName: user.emergencyFirstName || '',
    contactLastName: user.emergencyLastName || '',
    contactPhone: user.emergencyPhone || '',
    contactEmail: user.emergencyEmail || '',
    contactRelationship: user.emergencyRelationship || '',

    email: user.email || '',
    roles: Object.keys(user.userRoles || {}) || [],

    utilityContractorId: user.utilityContractorId ?? null,
    preferredName: user.preferredName || '',
    unionClassification: user.unionClassification || '',
    ssn: user.ssn || '',
    localUnion: user.localUnion || '',
    unionTicket: user.unionTicket || '',
    employmentType: user.employmentType || EmploymentTypeEnum.UNION,

    rubberSleeve: user.rubberSleeve || '',
    workVest: user.workVest || '',
    workGlove: user.workGlove || '',
    rubberGlove: user.rubberGlove || '',

    driverLicenseState: user.driverLicenseState || '',
    driverLicense: user.driverLicense || '',
    driverLicenseClass: user.driverLicenseClass || '',
    driverLicenseExpiration: (user.driverLicenseExpiration && normalizerTimestamp(user?.driverLicenseExpiration)) || null,

    cdlEndorsements: (user.cdlEndorsements || []).map((item) => item) || [],
    cdlRestrictions: (user.cdlRestrictions || []).map((item) => item) || [],
  };

  const validationSchema = Yup.object({
    roles: sectionToSubmit === 'roles' ? Yup.array().min(1, t('validations.required')) : Yup.mixed().notRequired(),
    zipcode: sectionToSubmit === 'address' ? Yup.string().matches(validation.zipCode, t('validations.zipCode')) : Yup.mixed().notRequired(),
    contactPhone: Yup.string().phoneFormat(t('validations.phone')),
    contactEmail: sectionToSubmit === 'emergencyContact' ? Yup.string().email(t('validations.email')) : Yup.mixed().notRequired(),
  });

  const halfDay: number = 12 * 60 * 60;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      if (sectionToSubmit === 'roles') {
        dispatch(updateUserRoles.init({ uuid: user.uuid, roles: data.roles }));
      } else {
        const submitSectionData: Record<string, any> = {};
        fieldsToSubmit.forEach((field) => {
          if (field === 'driverLicenseExpiration') {
            // Technical Debts: Trouble with "Daylight Saving Time" (DST)
            // For reproducing change on profile: 31/10/2023, save, 29/10/2023, save. Will change on 28/10/2023.
            // Hotfix: Add half day
            submitSectionData[field as keyof FormValues] = data.driverLicenseExpiration
              ? normalizerTimestamp(data.driverLicenseExpiration) + halfDay
              : null;
          } else {
            submitSectionData[field] = data[field as keyof FormValues] ? data[field as keyof FormValues] : null;
          }
        });

        const payload =
          sectionToSubmit === 'email' || sectionToSubmit === 'preferredName'
            ? { [sectionToSubmit]: submitSectionData[sectionToSubmit] }
            : { [sectionToSubmit]: submitSectionData };

        dispatch(saveProfile.init({ ...payload, uuid: user.uuid }));
      }
      setSectionToSubmit('');
      setFieldsToSubmit([]);
    },
  });

  const handleSubmitSection = (sectionName: string, fieldNames: string[]) => {
    setSectionToSubmit(sectionName);
    setFieldsToSubmit(fieldNames);
    formik.handleSubmit();
  };

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    if (saveProfileSuccess || verifyUserInfoSuccess || updateUserRolesSuccess) {
      dispatch(loadUser.init());
      if (!isOwner) {
        dispatch(loadUserUuid.init({ uuid: user.uuid }));
      }
    }
  }, [saveProfileSuccess, verifyUserInfoSuccess, updateUserRolesSuccess, isOwner]);

  useEffect(() => {
    !isOwner && dispatch(setClearModal.init({ isOpen: false }));
  }, [isOwner]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (formik.dirty) {
        const confirmationMsg = 'You have unsaved changes.';
        e.returnValue = confirmationMsg;

        return confirmationMsg;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      dispatch(saveProfile.reset());
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formik.dirty]);

  const newUserModal = (
    <Box sx={{ p: 4, mb: 4, gap: '16px', display: 'flex', background: themeColors.lightYellow }}>
      <Box sx={{ width: '20px', height: '20px' }}>
        <Icon name="warningSign" />
      </Box>
      <Box sx={{ width: '100%', gap: '18px', display: 'flex', flexDirection: 'column' }}>
        <P1R>
          {t('profilePage.thankYouForJoining')}
          {t('profilePage.willReviewAccount')}
        </P1R>
        <P1R>
          {t('profilePage.needHelp')}
          <P1R onClick={() => navigate(PATHS.TUTORIALS)} sx={{ color: themeColors.blue, cursor: 'pointer' }}>
            {' '}
            {t('profilePage.guides')}{' '}
          </P1R>
          {t('profilePage.instructions')}
        </P1R>
      </Box>
      <Box sx={{ mt: -2 }}>
        <IconButton onClick={handleCloseNewUserModal}>
          <Icon name="close" />
        </IconButton>
      </Box>
    </Box>
  );

  const clearNotCompletedWarning = (
    <Box sx={{ p: 3, gap: '16px', display: 'flex', background: themeColors.redLight }}>
      <Box sx={{ width: '20px', height: '20px' }}>
        <Icon name="warningSign" />
      </Box>
      <Box sx={{ py: 0.5, width: '100%', gap: '18px', display: 'flex', flexDirection: 'column' }}>
        <P1R>{isOwner ? 'You are not CLEAR verified yet.' : 'This user is not CLEAR verified yet.'}</P1R>
        {isOwner && (
          <Button onClick={() => dispatch(setClearModal.init({ isOpen: true }))} style={{ textTransform: 'none' }}>
            Complete Verification
          </Button>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 5 }}>
        <Tabs value={tabsValue} onChange={handleTabsChange} variant="scrollable" scrollButtons="auto">
          <Tab
            label={<Box sx={{ position: 'relative' }}>{t('profilePage.generalInfoTab')}</Box>}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              '&.Mui-selected': { color: 'black' },
            }}
          />
          {can.viewFullProfile && (
            <Tab
              label={<Box sx={{ position: 'relative' }}>{t('profilePage.attachedFilesTab')}</Box>}
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                '&.Mui-selected': { color: 'black' },
              }}
            />
          )}
        </Tabs>
      </Box>

      {user.status === 0 && user.clearStatus === ClearStatus.APPROVED && showNewUserModal && isOwner ? newUserModal : null}
      {displayClearWarning ? clearNotCompletedWarning : null}

      {tabsValue === 0 && <GeneralInfo user={user} formik={formik} can={can} isOwner={isOwner} handleUpdate={handleSubmitSection} />}
      {tabsValue === 1 && can.viewFullProfile && <AttachedFiles currUser={currUser} user={user} editable={editable} formik={formik} />}

      <Grid item xs={12}>
        {saveProfileError && <P3 sx={{ color: themeColors.error, mt: 3 }}>{t('common.internalServerError')}</P3>}
      </Grid>

      {blocker.state === 'blocked' && <UnsavedChangesPopup onProceed={blocker.proceed} onCancel={blocker.reset} />}
    </>
  );
};

export default UserProfile;
